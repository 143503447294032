import React, { useEffect, useState } from 'react'
import { useModalDispatcher } from '../../../hooks/useModalDispatcher';
import CustomButton from '../../../components/CustomButton';
import Modal from '../../../components/Modal';
import InputForm from '../../../components/InputForm';
import { useParams, useSearchParams } from 'react-router-dom';
import { ICategory } from '../../../types/interfaces/category.interface';
import { IProduct } from '../../../types/interfaces/product.inteface';
import { apiService, HttpMethod } from '../../../services/apiService';

interface IProps {
    isOpen: boolean;
    setIsOpen: (e: boolean) => void;
    element?: ICategory<IProduct>;
    setElement?: React.Dispatch<React.SetStateAction<ICategory<IProduct> | undefined>>
};

const ManageCategory: React.FC<IProps> = ({ isOpen, setIsOpen, element, setElement }) => {
    const { showErrorModal } = useModalDispatcher();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [categoryName, setCategoryName] = useState<string>("");
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (element) {
            setCategoryName(element.name);
        } else {
            setCategoryName('');
        }
    }, [element])

    const handleManageCategory = async () => {
        try {
            setError('');

            if (!categoryName?.length) {
                setError('Inserisci un nome per la categoria');
                return;
            }

            setIsLoading(true);

            let category;
            if (!!element) {
                category = await apiService(HttpMethod.PUT, 'category/update/' + element._id, { name: categoryName });
            } else {
                category = await apiService(HttpMethod.POST, 'category/create', { name: categoryName, offer: params.offerId });
            }

            if ('errorCode' in category) {
                throw category
            }

            setSearchParams(searchParams);
            setCategoryName('');
            handleClose();

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleDeleteCategory = async () => {
        try {
            setIsLoading(true);

            const response = await apiService(HttpMethod.PUT, 'category/delete/' + element?._id);

            if ('errorCode' in response) {
                throw response
            }

            setSearchParams(searchParams);
            setCategoryName('');
            handleClose();
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        }
    }

    const handleClose = () => {
        if (setElement) { setElement(undefined) }
        setIsOpen(false);
    }

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <p className="text-xl font-semibold text-text text-center mb-4">Aggiungi Categoria</p>
            <div className='flex flex-col gap-8 md:w-96'>
                <InputForm backgroundColor='bg-background' label='Nome' value={categoryName} setValue={(e) => setCategoryName(e)} onSearchSubmit={handleManageCategory} error={!!error.length} errorMessage={error} />

                {!!element && <p className='text-center text-mediumGray text-base font-medium'>Se decidi di eliminare questa categoria, rimuoverai anche tutti i prodotti a lei collegati.</p>} <div className="flex justify-between gap-10">
                    {!element
                        ? <CustomButton onClickHandler={handleClose} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' label='Annulla' />
                        : <CustomButton onClickHandler={handleDeleteCategory} color='bg-error' className='border-2 border-error' label='Elimina' />
                    }
                    <CustomButton disabled={isLoading} onClickHandler={handleManageCategory} color='bg-primary' className='border-2 border-primary' label={!!element ? 'Modifica' : 'Crea'} />
                </div>
            </div>
        </Modal>
    )
}

export default ManageCategory