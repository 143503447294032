import React, { useEffect, useRef } from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import { ITimeTable } from '../../../../types/interfaces/timeTable.interface';
import CustomButton from '../../../../components/CustomButton';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import '../../../../utils/style/hiddenScrollBar.css';
import { useHorizontalScroll } from '../../../../hooks/useHorizontalScroll';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';
import moment from 'moment';

interface Step1Props {
    data: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    setData: React.Dispatch<React.SetStateAction<IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>>;
    offers: IOffer<IAutoBookingOption>[];
    nextStep: () => void;
}

const Step1Create: React.FC<Step1Props> = ({ data, setData, offers, nextStep }) => {
    const containerRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);

    if (containerRefs.current.length !== offers.length) {
        containerRefs.current = Array(offers.length)
            .fill(null)
            .map(() => React.createRef<HTMLDivElement>());
    }

    useEffect(() => {
        containerRefs.current.forEach(containerRef => {
            const container = containerRef.current;
            if (!container) return;

            let isDragging = false;
            let startX = 0;
            let scrollLeft = 0;

            const handleMouseDown = (e: MouseEvent) => {
                isDragging = true;
                startX = e.pageX - container.offsetLeft;
                scrollLeft = container.scrollLeft;
            };

            const handleMouseLeaveOrUp = () => {
                isDragging = false;
            };

            const handleMouseMove = (e: MouseEvent) => {
                if (!isDragging) return;
                e.preventDefault();
                const x = e.pageX - container.offsetLeft;
                const walk = (x - startX) * 2; // Velocità dello scroll
                container.scrollLeft = scrollLeft - walk;
            };

            container.addEventListener('mousedown', handleMouseDown);
            container.addEventListener('mouseleave', handleMouseLeaveOrUp);
            container.addEventListener('mouseup', handleMouseLeaveOrUp);
            container.addEventListener('mousemove', handleMouseMove);

            // Cleanup degli eventi quando il componente viene smontato
            return () => {
                container.removeEventListener('mousedown', handleMouseDown);
                container.removeEventListener('mouseleave', handleMouseLeaveOrUp);
                container.removeEventListener('mouseup', handleMouseLeaveOrUp);
                container.removeEventListener('mousemove', handleMouseMove);
            };
        });
    }, [containerRefs]);

    const handleNext = (event: React.MouseEvent<HTMLDivElement>, offer: IOffer<IAutoBookingOption>, shift?: string) => {
        event.stopPropagation();

        if (!!offer.shifts.length && !shift) {
            return;
        }

        const updatedData = shift
            ? { ...data, offer: offer, shift }
            : { ...data, offer: offer };

        setData(updatedData);
        nextStep();
    };

    return (
        <>
            <div className='flex flex-col gap-2'>
                <p className='font-bold text-text text-2xl'>Esplora i Nostri Servizi</p>
                <p className='text-darkGray font-semibold'>
                    Scegli il servizio che desideri prenotare, seleziona il turno o l'orario che meglio si adatta alla tua agenda e completa la tua esperienza con noi.<br /><br />
                    Esplora le opzioni disponibili e personalizza la tua prenotazione in base alle tue preferenze.
                </p>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4'>
                {offers.filter(a => !!a.autoBookingOptions).map((offer, index) => {
                    return (
                        <div
                            key={index}
                            onClick={(e) => moment(offer?.autoBookingOptions?.dateLimit).isBefore(moment()) ? handleNext(e, offer) : false}
                            className={`
                                aspect-video w-full h-full
                                ${(moment(offer?.autoBookingOptions?.dateLimit).isBefore(moment()) || !offer?.autoBookingOptions?.proposals?.length || offer?.autoBookingOptions?.disabled)
                                    ? 'opacity-50 cursor-default'
                                    : !!offer.shifts.length
                                        ? "" : "cursor-pointer"}
                                bg-white text-text rounded-lg shadow py-4 gap-2 flex flex-col justify-between items-center relative`}
                        >
                            <p className='text-center font-semibold text-2xl'>{offer.name}</p>

                            {moment(offer?.autoBookingOptions?.dateLimit).isBefore(moment())
                                ? <p className='text-center font-medium text-darkGray my-auto'>Nessuna data disponibile.</p>
                                : !offer.autoBookingOptions?.proposals?.length || offer?.autoBookingOptions?.disabled
                                    ? <p className='text-center font-medium text-darkGray my-auto'>Non disponibile.</p>
                                    : <>
                                        {!!offer.shifts.length
                                            ? <div ref={containerRefs.current[index]} className='flex w-full overflow-x-auto hidden-scrollbar py-4 gap-2 px-4'>
                                                {offer.shifts.map((shift: string, index: number) => {
                                                    return (
                                                        <CustomButton key={index} type='button' color='bg-white' textColor='text-text' label={shift} textStyle='font-medium' padding='px-4 py-2' onClickHandler={(e) => handleNext(e, offer, shift)} />
                                                    );
                                                })}
                                            </div>
                                            : <div className='py-2'>
                                                <CustomButton key={index} type='button' color='bg-white' textColor='text-text' label="Seleziona" textStyle='font-medium' padding='px-4 py-2' />
                                            </div>
                                        }

                                        <div className='border-t border-darkGray w-11/12' />

                                        <div className="flex justify-center items-center gap-4 w-full">
                                            {offer.timetable?.map((timetable: ITimeTable, index: number) => {
                                                const isUnavailable = timetable?.isAvailable ? 'opacity-100' : 'opacity-20';
                                                return <p key={index} className={`capitalize font-semibold text-lg text-primary ${isUnavailable}`}>{timetable.day.toString().slice(0, 1)}</p>
                                            })}
                                        </div>
                                    </>
                            }
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Step1Create;
