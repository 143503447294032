export enum USER_ROLES {
    ADMIN,
    USER,
    NOROLE,
};

const rolesTranslated = [
    "Admin",
    "Utente",
    "No ruolo"
];

export function getRolesEnum(roles: number[]): string[] {
    return roles.map((index) => rolesTranslated[index]);
};