import React, { useEffect, useRef, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import { BOOKING_STATUS, bookingStatusColorMapping, bookingStatusDbMapping } from '../../types/enum/bookingStatus';
import Modal from '../../components/Modal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { replaceOffers } from '../../store/offer/offerSlice';
import OfferCapacityInfo from '../../components/OfferCapacityInfo';
import { Paginated } from '../../types/interfaces/paginated.interface';
import { PartialIBooking } from '../../types/interfaces/booking.interface';
import { IOffer } from '../../types/interfaces/offer.interface';
import { IProposal } from '../../types/interfaces/proposal.interface';
import { IBookingRequest } from '../../types/interfaces/bookingRequest.interface';
import BookingTable, { SortDirection } from '../booking/booking/BookingTable';
import { apiService, HttpMethod } from '../../services/apiService';
import QuickBookingForm from '../booking/QuickBookingForm';
import PageTitle from '../../components/PageTitle';
import { ArrowRightForward } from '../../utils/icons/ArrowIcon';

const HomeView: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const { paginationData, globalDate } = useAppSelector(state => state.booking);
    const { offers } = useAppSelector(state => state.offer);

    // const [notifications, setNotifications] = useState<INotification[]>([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [sortData, setSortData] = useState<{ [key: string]: SortDirection }>({});
    const [selectedOffer, setSelectedOffer] = useState<IOffer | undefined>(undefined);
    const [refetch, setRefetch] = useState(false);

    // booking requests stats
    const [bookingRequestStats, setBookingRequestStats] = useState({
        pending: 0,
        updated: 0,
    });

    const containerRef = useRef<HTMLDivElement>(null);
    useHorizontalScroll(containerRef);

    useEffect(() => {
        const interval = setInterval(() => {
            setRefetch(!refetch);
        }, 5 * 60 * 1000); // refetch ogni 5 minuti 

        return () => clearInterval(interval);
    }, [refetch]);

    const { data: bookingsData, loading: isBookLoading } = useFetch<{ pagination: Paginated<PartialIBooking<{ offer: IOffer; proposals: IProposal, bookingRequest: IBookingRequest }>> }>(
        () => apiService(HttpMethod.POST, '/booking/list', { pagination: { pageSize: paginationData.pageSize, currentPage: paginationData.currentPage, filters: { bookingDate: globalDate }, sort: !!Object.entries(sortData).length ? sortData : { createdAt: 'DESC' } } }),
        undefined,
        [paginationData, globalDate, sortData, searchParams, refetch],
        !!paginationData
    );

    // const { loading: isNotifLoading } = useFetch<INotification[]>(
    //     () => apiService(HttpMethod.GET, '/notification/get/list/unread'),
    //     (data) => setNotifications(data)
    // );

    useFetch<IOffer[]>(
        () => apiService(HttpMethod.POST, '/offer/list'),
        (fetchedData) => dispatch(replaceOffers(fetchedData)),
        [],
        !offers.length
    );

    useFetch<{ newBookingRequests: { "PENDING": number, "UPDATED": number } }>(
        () => apiService(HttpMethod.POST, '/booking-request/list/filtered', { pagination: { pageSize: 1, currentPage: 0 } }),
        (response) => setBookingRequestStats({ pending: response.newBookingRequests.PENDING, updated: response.newBookingRequests.UPDATED }),
        [refetch]
    );

    // const handleReadAllNotifications = async () => {
    //     try {
    //         await Promise.all(notifications.map(async notification => {
    //             await ApiService<putHandler>(HttpMethod.PUT, '/notification/read/' + notification._id);
    //         }));
    //         setNotifications([]);
    // 
    //         const response = await apiService(HttpMethod.GET,'/notification/get/list/unread') as INotification[];
    //         setNotifications(response);
    //     } catch (error: any) {
    //         if (error?.errorCode) {
    //             showErrorModal(error.status, error.errorCode);
    //         } else {
    //             showErrorModal(400, '');
    //         }
    //     }
    // };

    // const handleReadNotification = async (id: string) => {
    //     try {
    //         await ApiService<putHandler>(HttpMethod.PUT,'/notification/read/' + id);
    //         setNotifications((prevNotifications) =>
    //             prevNotifications.map((notification) =>
    //                 notification._id === id
    //                     ? { ...notification, hidden: true }
    //                     : notification
    //             )
    //         );
    //         const response = await apiService(HttpMethod.GET,'/notification/get/list/unread') as INotification[];
    //         setNotifications(response);
    //     } catch (error: any) {
    //         if (error?.errorCode) {
    //             showErrorModal(error.status, error.errorCode);
    //         } else {
    //             showErrorModal(400, '');
    //         }
    //     }
    // };

    const handleNavigateToBooking = async (id?: string) => {
        navigate('/dashboard/bookings/details/' + id + '?bookingDate=' + globalDate, { replace: true });
    };

    const handleSortBooking = (sortKey: string) => {
        setSortData((prevSorting) => {
            if (!prevSorting || prevSorting[sortKey] !== undefined) {
                // If sortData is empty or has the same sortKey, toggle the sort direction
                const newSortDirection = (prevSorting && prevSorting[sortKey] === 'ASC') ? 'DESC' : 'ASC';
                return { [sortKey]: newSortDirection };
            } else {
                // Set the new sort key and default sort direction to 'ASC'
                return { [sortKey]: 'ASC' };
            }
        });
    };

    const openCreateBookingModal = (offer?: IOffer | undefined) => {
        setSelectedOffer(offer);
        if (searchParams.has('create')) {
            searchParams.delete('create');
        } else {
            searchParams.set('create', 'OPEN');
        }

        setSearchParams(searchParams);
    };

    return (
        <div className='h-full w-full flex flex-col gap-6 py-8 hidden-scrol overflow-auto'>
            <div className='px-4 md:px-8 capitalize'>
                <PageTitle title={"Panoramica " + moment(globalDate).format('ddd DD MMMM')} goBackBtn={false} />
            </div>

            {/* BOOKING REQUEST */}
            {(bookingRequestStats.pending > 0 || bookingRequestStats.updated > 0) &&
                <div className='flex flex-col px-4 md:px-8 gap-2'>
                    <p className='text-xl font-bold'>Hai delle nuove richieste!</p>

                    <div className='flex flex-col gap-4 rounded shadow-md bg-white select-none'>
                        <div className='w-full rounded-t px-6 pt-3 pb-2 flex justify-between items-center bg-background2'>
                            <p className='capitalize text-3xl font-bold w-full truncate'>Prenotazioni Online</p>
                            <button type='button' onClick={() => navigate('/dashboard/requests?status=pending')} className='bg-text py-2 px-4 rounded-lg shadow'>
                                <ArrowRightForward color='white' size='28' />
                            </button>
                        </div>

                        <div className='flex flex-col gap-2 px-6 pb-4'>
                            <div className='flex justify-between items-baseline w-full'>
                                <p className='text-xl font-medium text-text'>Nuove</p>
                                <p className='text-text'>{bookingRequestStats.pending}</p>
                            </div>
                            <div className='flex justify-between items-baseline w-full'>
                                <p className='text-xl font-medium text-text'>Modifiche</p>
                                <p className='text-text'>{bookingRequestStats.updated}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* OVERVIEW OFFERS STATS */}

            <div className='flex flex-col gap-2'>
                <p className='px-4 md:px-8 text-xl font-bold'>Resoconto capienza</p>
                {
                    offers.length > 0
                        ? <div ref={containerRef} className='flex w-full h-full gap-4 pb-2 px-4 md:px-8 overflow-x-auto hidden-scrollbar select-none'>
                            {offers.map((offer: IOffer, index: number) => {
                                return <OfferCapacityInfo key={index} date={globalDate} offer={offer} addBookingBtn={(offer) => openCreateBookingModal(offer)} refetch={refetch} />
                            })}
                        </div>
                        : <p className='text-center my-10 text-lg font-medium text-darkGray'>Crea prima dei servizi per vederne il resoconto</p>
                }
            </div>

            {/* NOTIFICATIONS */}
            {/*             <div className='flex flex-col px-8 gap-2'>
                <p className='text-xl font-bold'>Notifiche</p>
                <div className='flex flex-col justify-center items-center py-12 gap-4 rounded shadow-md bg-white w-full select-none'>
                    <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.8438 60.875L0.125 43.1562V17.8438L17.8438 0.125H43.1562L60.875 17.8438V43.1562L43.1562 60.875H17.8438ZM20.8813 44.8438L30.5 35.225L40.1188 44.8438L44.8438 40.1188L35.225 30.5L44.8438 20.8813L40.1188 16.1562L30.5 25.775L20.8813 16.1562L16.1562 20.8813L25.775 30.5L16.1562 40.1188L20.8813 44.8438ZM20.7125 54.125H40.2875L54.125 40.2875V20.7125L40.2875 6.875H20.7125L6.875 20.7125V40.2875L20.7125 54.125Z" fill="#1C1B1F" />
                    </svg>
                    <p className='text-center text-xl font-bold'>
                        Le notifiche<br />
                        non sono abilitate
                    </p>
                </div>
            </div> */}

            {/* LAST BOOKINGS TABLE */}
            <div className='flex flex-col px-4 md:px-8 gap-2'>
                <p className='text-xl font-bold'>Ultime prenotazioni</p>
                {!!bookingsData?.pagination?.data.length
                    ? <BookingTable
                        dataLoading={isBookLoading}
                        rows={bookingsData?.pagination?.data!}
                        headers={[
                            { key: 'surname', label: 'Nome' },
                            { key: 'phoneNumber', label: 'Telefono' },
                            { key: 'offer', label: 'Servizio' },
                            { key: 'people', label: 'Persone' }
                        ]}

                        onHeaderClick={handleSortBooking}
                        onRowClick={(id) => handleNavigateToBooking(id)}
                        onStatusChange={() => false}
                        sorting={sortData}
                        totalPages={bookingsData?.pagination?.totalPages!}
                        paginationOptions={[15, 20, 25]}
                        showIcon={false}
                    />
                    : <div className='flex flex-col justify-center items-center py-12 gap-4 rounded shadow-md bg-white w-full select-none'>
                        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.8438 60.875L0.125 43.1562V17.8438L17.8438 0.125H43.1562L60.875 17.8438V43.1562L43.1562 60.875H17.8438ZM20.8813 44.8438L30.5 35.225L40.1188 44.8438L44.8438 40.1188L35.225 30.5L44.8438 20.8813L40.1188 16.1562L30.5 25.775L20.8813 16.1562L16.1562 20.8813L25.775 30.5L16.1562 40.1188L20.8813 44.8438ZM20.7125 54.125H40.2875L54.125 40.2875V20.7125L40.2875 6.875H20.7125L6.875 20.7125V40.2875L20.7125 54.125Z" fill="#1C1B1F" />
                        </svg>
                        <p className='text-center text-xl font-bold'>
                            Nessuna Prenotazione<br />
                            Trovata per questa data
                        </p>
                    </div>
                }
            </div>

            {/* NOTIFICATIONS LIST */}
            {/* <CardContainer title='Notifiche' additionalStyle='h-fit max-sm:w-full' headerElement={<button disabled={notifications.length <= 0} className='font-medium text-primary whitespace-nowrap hover:underline' onClick={handleReadAllNotifications}>Letto tutto</button>}>
                    {(!isNotifLoading)
                        ? <div className='flex flex-col h-full gap-2'>
                            {(notifications.length > 0)
                                ? notifications?.map((notification: INotification, index: number) => {
                                    return <div key={index}
                                        className={`flex flex-col bg-background p-2 gap-2 shadow rounded-md ${notification.hidden ? "-translate-x-full opacity-0" : ""}`}
                                        style={{ transition: 'opacity 0.3s ease, transform 0.3s ease' }}
                                    >
                                        <div className={`flex items-center ${(notification.targetEntity?.entityModel === 'bookings') ? 'justify-between gap-4' : 'justify-end'}`}>
                                            {notification.targetEntity?.entityModel === 'bookings' && <p className='text-xs font-medium text-primary py-2 cursor-pointer whitespace-nowrap' onClick={() => handleNavigateToBooking(notification.targetEntity?.entityId)}>Vedi</p>}
                                            <p className='text-xs text-darkGray whitespace-nowrap'>{moment(notification.date).format('DD MMMM YYYY')}</p>
                                        </div>
                                        <div className='flex gap-2 justify-between items-center'>
                                            <p className='whitespace-pre-line text-xs md:text-base text-text font-medium'>{notification.message}</p>
                                            <div className='cursor-pointer' onClick={() => handleReadNotification(notification._id)}>
                                                <DeleteBucketIcon />
                                            </div>
                                        </div>
                                    </div>
                                })
                                : <p className='flex h-full items-center justify-center font-medium text-sm text-darkGray whitespace-nowrap'>Nessuna notifica da mostrare</p>}
                        </div>
                        : <LoadingIndicator label='Caricamento notifiche' />
                    }
                </CardContainer> */}

            <Modal title='Legenda stato prenotazione' isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}  >
                <div className='flex flex-col gap-2'>
                    {Object.keys(bookingStatusDbMapping).map((status, index) => {
                        return <div key={index} className='flex items-center gap-4'>
                            <div className={`h-6 aspect-square rounded-full ${bookingStatusColorMapping[Number(status) as BOOKING_STATUS]}`}></div>
                            <p className={`text-lg font-medium text-darkGray`}>{bookingStatusDbMapping[Number(status) as BOOKING_STATUS]}</p>
                        </div>
                    })}
                </div>
            </Modal>

            <Modal
                isOpen={searchParams.has('create')}
                onClose={openCreateBookingModal}
                fullScreen
                bgColor='bg-background'
                classNameChildren='p-8 mx-auto'
            >
                {!!selectedOffer && <QuickBookingForm selectedOffer={selectedOffer} />}
            </Modal>

        </div >
    );
}

export default HomeView
