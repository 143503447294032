import React from 'react';
import { ArrowDown } from '../utils/icons/ArrowIcon';
import { AddIcon } from '../utils/icons/AddIcon';
import { BOOKING_STATUS, bookingStatusBorderColorMapping, bookingStatusColorMapping, mapBookingStatus } from '../types/enum/bookingStatus';
import useComponentVisible from '../hooks/useComponentVisible';

interface CustomSelectProps {
    options: { label: string, value: any }[];
    selectedOption: any | undefined;
    setSelectedOption: (arg0: { label: string, value: any }) => void;
    backgroundColor?: string;
    title?: string;
    className?: string;
    containerClassName?: string;
    optionClassName?: string;
    arrow?: boolean;
    addIcon?: boolean;
    isColorChange?: boolean;
}

const SelectForm: React.FC<CustomSelectProps> = ({ options, selectedOption, setSelectedOption, backgroundColor = 'bg-primary', title, className, containerClassName, optionClassName, arrow = true, addIcon = false, isColorChange = false }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const handleOptionClick = (option: { label: string, value: any }) => {
        setSelectedOption(option);
        setIsComponentVisible(false);
    };

    const toggleDropdown = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    return (
        <div ref={ref} className={`flex flex-col gap-2 select-none ${containerClassName}`}
            onClick={(e) => e.stopPropagation()}>
            {title && (
                <label className="font-semibold text-text text-lg">
                    {title}
                </label>
            )}
            <div className={`relative flex justify-between text-sm md:text-sm h-8 font-medium ${className}`}>
                <div onClick={toggleDropdown} className={`flex items-center justify-center ${isColorChange ? bookingStatusColorMapping[selectedOption as BOOKING_STATUS] : backgroundColor} ${arrow ? 'w-10/12' : 'w-full'} text-text_white cursor-pointer px-2 py-1 whitespace-nowrap ${(isComponentVisible && !!options.length) ? 'rounded-t-md shadow-lg' : 'rounded-md'}`} >
                    {typeof selectedOption !== 'undefined'
                        ? mapBookingStatus(selectedOption)
                        : (addIcon) ? <AddIcon /> : 'Seleziona'}
                </div>
                {isComponentVisible && (
                    <div className={`bg-white absolute ${arrow ? 'w-10/12' : 'w-full'} top-full ${isColorChange && 'border ' + bookingStatusBorderColorMapping[selectedOption as BOOKING_STATUS]}  overflow-auto rounded-bl-md rounded-br-md z-50 shadow-lg`}>
                        {options.map((option: { label: string, value: string }, index: number) => (
                            <div key={index} onClick={() => handleOptionClick(option)}
                                className={`flex items-center justify-center h-8 bg-white text-text cursor-pointer px-2 py-1 hover:${backgroundColor} hover:text-text_white whitespace-nowrap ${optionClassName} ${(index === options.length - 1) && 'rounded-b-md'}`}>
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
                {arrow &&
                    <div onClick={toggleDropdown} className="flex w-1/12 justify-center items-center cursor-pointer">
                        <ArrowDown />
                    </div>}
            </div>
        </div>
    );
};

export default SelectForm;
