import React, { useEffect, useState } from 'react'
import { useModalDispatcher } from '../../../hooks/useModalDispatcher';
import CustomButton from '../../../components/CustomButton';
import Modal from '../../../components/Modal';
import InputForm from '../../../components/InputForm';
import { apiHandler } from '../../../services/apiHandler';
import { useParams, useSearchParams } from 'react-router-dom';
import { IProposal } from '../../../types/interfaces/proposal.interface';
import { IStructure } from '../../../types/interfaces/structure.interface';
import { IOffer } from '../../../types/interfaces/offer.interface';
import { IProduct } from '../../../types/interfaces/product.inteface';

interface IProps {
    isOpen: boolean;
    setIsOpen: (e: boolean) => void;
    element?: IProposal<IStructure, IOffer, IProduct>;
    setElement?: React.Dispatch<React.SetStateAction<IProposal<IStructure, IOffer, IProduct> | undefined>>;
    onCreateAction?: (element: any) => void; // If not passed as prop, the component will create the record on the db
    onUpdateAction?: (element: any) => void; // If not passed as prop, the component will update the record on the db
    onDeleteAction?: (elementId: string) => void; // If not passed as prop, the component will delete the record on the db
};

const ManageProposal: React.FC<IProps> = ({ isOpen, setIsOpen, element, setElement, onCreateAction, onUpdateAction, onDeleteAction }) => {
    const { showErrorModal } = useModalDispatcher();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [proposalName, setProposalName] = useState<string>("");
    const [proposalDescription, setProposalDescription] = useState<string>("");
    const [capacity, setCapacity] = useState<number>(0);
    const [proposalPrice, setProposalPrice] = useState<number>(0);

    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setError("");

        if (element) {
            setProposalName(element.name);
            setProposalDescription(element.description ?? '');
            setCapacity(element.capacity ?? 0);
            setProposalPrice(element.price);
        } else {
            setProposalName('');
            setProposalDescription('');
            setCapacity(0);
            setProposalPrice(0);
        }
    }, [element]);

    const handleManageProposal = async () => {
        try {
            setError('');

            if (!proposalName?.length) {
                setError('Inserisci un nome per la proposta');
                return;
            }

            // if (!proposalPrice) {
            //     setError('Inserisci un prezzo per la proposta');
            //     return;
            // }

            if (proposalPrice < 0) {
                setError('Il prezzo deve essere positivo');
                return;
            }

            if (proposalPrice > 99999) {
                setError('Il prezzo deve essere inferiore a 99999');
                return;
            }

            setIsLoading(true);

            if (!!element) {
                if (onUpdateAction) {
                    onUpdateAction({ _id: element._id, name: proposalName, description: proposalDescription, price: proposalPrice, capacity });
                } else {
                    await apiHandler.put('proposal/update/' + element._id, { name: proposalName, description: proposalDescription, price: proposalPrice, capacity });
                }
            } else {
                if (onCreateAction) {
                    onCreateAction({ name: proposalName, description: proposalDescription, price: proposalPrice, offer: params.offerId, capacity });
                } else {
                    await apiHandler.post('proposal/create', { name: proposalName, description: proposalDescription, price: proposalPrice, offer: params.offerId, capacity });
                }
            }

            setSearchParams(searchParams);

            setProposalName('');
            setProposalDescription('');
            setCapacity(0);
            setProposalPrice(0);

            handleClose();

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleDeleteProposal = async () => {
        try {
            setIsLoading(true);

            if (onDeleteAction) {
                onDeleteAction(element?._id!);
            } else {
                await apiHandler.del('proposal/delete/' + element?._id);
            }

            setSearchParams(searchParams);
            setProposalName('');
            handleClose();
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleClose = () => {
        if (setElement) { setElement(undefined) }
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <p className="text-xl font-semibold text-text text-center mb-4">{!element ? "Aggiungi Proposta" : "Modifica Proposta"}</p>
            <div className='flex flex-col gap-8 md:w-96'>

                <InputForm backgroundColor='bg-background' label='Nome' value={proposalName} setValue={(e) => setProposalName(e)} onSearchSubmit={handleManageProposal} />
                <InputForm backgroundColor='bg-background' label='Descrizione' value={proposalDescription} setValue={(e) => setProposalDescription(e)} onSearchSubmit={handleManageProposal} />
                <div className='flex flex-col gap-1'>
                    <InputForm backgroundColor='bg-background' label='Capacità Max' type='number' value={capacity} setValue={(e) => setCapacity(e)} onSearchSubmit={handleManageProposal} />
                    <p className='text-sm text-mediumGray'>Lasciando la capacità a 0 non verra inclusa nelle analisi</p>
                </div>
                <InputForm backgroundColor='bg-background' label='Prezzo' type='number' value={proposalPrice} setValue={(e) => setProposalPrice(e)} onSearchSubmit={handleManageProposal} />

                {!!error.length && <p className='text-center text-error text-base font-medium'>{error}</p>}

                {!!element?.products?.length && <p className='text-center text-mediumGray text-base font-medium'>Se decidi di eliminare questa proposta, rimuoverai anche tutti i prodotti a lei collegati.</p>} <div className="flex justify-between gap-10">

                    {!element
                        ? <CustomButton onClickHandler={handleClose} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' label='Annulla' />
                        : <CustomButton onClickHandler={handleDeleteProposal} color='bg-error' label='Elimina' />
                    }
                    <CustomButton disabled={isLoading} onClickHandler={handleManageProposal} color='bg-primary' label={!!element ? 'Modifica' : 'Crea'} />
                </div>
            </div>
        </Modal>
    )
}

export default ManageProposal