import { useLoaderData, useNavigate } from 'react-router-dom';
import { IList } from '../../types/interfaces/list.interface';
import { IEvent } from '../../types/interfaces/event.interface';
import PageTamplate from '../../components/PageTamplate';
import PageTitle from '../../components/PageTitle'
import Text from '../../components/Text';
import CustomButton from '../../components/CustomButton';
import moment from 'moment';
import { AddIcon } from '../../utils/icons/AddIcon';
import ListCreate from './ListCreate';
import useComponentVisible from '../../hooks/useComponentVisible';
import { IminError } from '../../types/interfaces/iminError.interface';
import GenericError from '../error/GenericError';

const Lists = () => {
    const response = useLoaderData() as { lists: IList<string, IEvent>[] } | IminError;
    const navigate = useNavigate();
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    if ('errorCode' in response) {
        return <GenericError />;
    }

    const { lists } = response as { lists: IList<string, IEvent>[] };

    return (
        <PageTamplate classname='overflow-auto'>
            <PageTitle title='Liste' goBackBtn rightBtn={<button type='button' className='bg-text py-2 px-4 rounded-lg shadow text-white flex items-center' onClick={() => setIsComponentVisible(true)}><AddIcon size='24' /></button>} />

            {!lists.length
                && <p className="text-center text-lg font-semibold text-text mt-8">Non hai nessuna lista programmata. Una volta creata, potrai visualizzarla qui sotto</p>
            }

            {lists.map((list, index) => (
                <div key={index} className='p-4 rounded bg-white shadow flex flex-col md:flex-row gap-2 justify-between'>
                    <div className='flex flex-col gap-1 md:grid md:grid-cols-4 w-full'>
                        {/* Style name for mobile */}
                        <p className='flex md:hidden text-2xl font-bold text-text'>{list.event.name}</p>
                        {/* Style name for tablet & desktop */}
                        <Text label={list.event.name} title='Lista' reverseFontStyle containerClassName='hidden md:flex' />

                        <Text label={moment(list.event.date).format('ddd DD MMM YY')} title={'Data'} reverseFontStyle className='capitalize' />
                        <Text label={!!list.people.length ? list.people.length : "No Partecipanti"} title={'N partecipanti'} reverseFontStyle />
                    </div>

                    <CustomButton
                        color='bg-text'
                        label='Visualizza'
                        onClickHandler={() => navigate('/dashboard/list/' + list._id)}
                    />
                </div>
            ))}

            <ListCreate isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)} />
        </PageTamplate>
    )
}

export default Lists