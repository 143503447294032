import React, { useEffect, useReducer, useRef, useState } from 'react'

import QrScanner from "qr-scanner";
import QrFrame from "../../utils/assets/qr/qr-frame.svg";
import "./qrStyle.css";
import CustomButton from '../../components/CustomButton';
import Text from '../../components/Text';
import { apiService, HttpMethod } from '../../services/apiService';
import { useParams } from 'react-router-dom';
import { CrossIcon } from '../../utils/icons/CrossIcon';

const ListScan: React.FC = () => {
    // retrieve url params
    const { id } = useParams();

    // QR States
    const scanner = useRef<QrScanner>();
    const videoEl = useRef<HTMLVideoElement>(null);
    const qrBoxEl = useRef<HTMLDivElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);
    const [isScanComplete, setIsScanComplete] = useState<boolean | undefined>(undefined);
    const [qrError, setQrError] = useState<string>("");

    // Result
    const [parsedResultScan, setParsedResultScan] = useState<any>();

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            // Instantiate the QR Scanner
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                // This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
                preferredCamera: "environment",
                // This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
                highlightScanRegion: true,
                // This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
                highlightCodeOutline: true,
                // A custom div which will pair with "highlightScanRegion" option above. This gives us full control over our scan region.
                overlay: qrBoxEl?.current || undefined,
            });

            // start scanning
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) {
                        setQrOn(false);
                        setQrError(err)
                    }
                });
        }

        // Clean up on unmount.
        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (!isScanComplete) {
            restartScanning()
        }
    }, [isScanComplete])

    // Success
    const onScanSuccess = async (result: QrScanner.ScanResult) => {
        // stop scanning
        scanner.current?.stop();
        setIsScanComplete(true);

        try {
            const response = await apiService(HttpMethod.POST, '/pr-list/scan', { listId: id, tokenPartecipant: result.data });

            // TODO: show error
            if (response.errorCode) {
                alert(response.message)
            }

            setParsedResultScan(response);
        } catch (error: any) {
            console.log(error)
        }
    };


    // Fail
    const onScanFail = (err: string | Error) => {
        console.log(err);
    };

    const handleAccept = async (id: string) => {
        try {
            const response = await apiService(HttpMethod.PUT, '/partecipant/update/status/' + id, { status: true });
            console.log(response)

            if (response.errorCode) {
                alert(response.message)
            }

        } catch (error: any) {
            console.log(error)
        }

        setParsedResultScan(undefined);
        setIsScanComplete(false);
    }

    const handleCancel = () => {
        setParsedResultScan(undefined);
        setIsScanComplete(false);
    }

    // restart scanning 
    const restartScanning = () => {
        if (typeof isScanComplete === 'undefined') return

        if (isScanComplete === false) {
            // start scanning
            scanner.current?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) {
                        setQrOn(false);
                        setQrError(err)
                    }
                })
        }
    }

    // If "camera" is not allowed in browser permissions, show an alert.
    useEffect(() => {
        if (!qrOn)
            alert(
                qrError
            );
    }, [qrOn]);

    return (
        <div className="qr-reader bg-black">
            {/* QR */}
            <video ref={videoEl}></video>
            <div ref={qrBoxEl} className="qr-box">
                <img
                    src={QrFrame}
                    alt="Qr Frame"
                    width={256}
                    height={256}
                    className="qr-frame"
                />
            </div>

            {/* scan success */}
            {parsedResultScan &&
                <div className='bg-white absolute bottom-0 w-full h-full flex flex-col justify-between z-50'>
                    <div>
                        <div className='flex items-center justify-between mb-4 p-4 shadow-md'>
                            <button onClick={handleCancel}>
                                <CrossIcon fillColor='#000' width='24' height='24' />
                            </button>
                            <p className='text-xl font-bold'>Risultato Scansione</p>
                            <div className='h-6 w-6'></div>
                        </div>

                        <div className='p-4'>
                            <div className='flex flex-col gap-y-4'>
                                <div className='grid grid-cols-2 gap-x-4'>
                                    <Text label={parsedResultScan?.name} title={'Nome'} reverseFontStyle />
                                    <Text label={parsedResultScan?.surname} title={'Cognome'} reverseFontStyle />
                                </div>
                                <Text label={parsedResultScan?.email} title={'Email'} reverseFontStyle />
                                <Text label={parsedResultScan?.age} title={'Eta'} reverseFontStyle />
                                <Text title={'Stato'} label={parsedResultScan?.status ? 'Entrato' : 'Non Entrato'} reverseFontStyle />
                            </div>
                        </div>
                    </div>

                    {parsedResultScan?.status
                        ? <div className='absolute bottom-0 w-full'>
                            <CustomButton label="Annulla" color="bg-text" className='rounded-none' padding='py-6' fullHeight onClickHandler={handleCancel} />
                        </div>
                        : <div className='absolute bottom-0 w-full'>
                            <CustomButton label="Entrato" color="bg-text" className='rounded-none' padding='py-6' fullHeight onClickHandler={() => { handleAccept(parsedResultScan._id) }} />
                        </div>
                    }
                </div>
            }
        </div >
    );
};

export default ListScan