import { useState } from 'react';
import { Form, useNavigation, useParams } from 'react-router-dom';
import { CREATION_MODE } from '../../types/enum/creationMode.enum';
import { GENDER } from '../../types/enum/gender.enum';
import InputForm from '../../components/InputForm';
import SelectForm from '../../components/SelectForm';
import CustomButton from '../../components/CustomButton';
import PageTitle from '../../components/PageTitle';

const AddPartecipant = () => {
    const params = useParams();
    const navigation = useNavigation();

    const [gender, setGender] = useState<{ value: GENDER, label: string } | undefined>(undefined);

    return (
        <div className="flex flex-col gap-4 p-4 md:p-8">
            {/* Form di registrazione alla lista */}
            <PageTitle title='Registra Persona' subTitle={'Inserisci i dettagli'} />

            <Form method='POST' className="flex flex-col gap-10">
                {/* Input Hidden */}
                <input hidden name='creationMode' defaultValue={CREATION_MODE.MANUAL} />
                <input hidden name='gender' defaultValue={gender?.value} />
                <input hidden name='list' defaultValue={params.list} />


                <div className='flex flex-col gap-4'>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <InputForm
                            label='Nome*'
                            name='name'
                            backgroundColor='bg-white'
                        />
                        <InputForm
                            label='Cognome*'
                            name='surname'
                            backgroundColor='bg-white'
                        />
                    </div>

                    <InputForm
                        label='Email*'
                        name='email'
                        type='email'
                        backgroundColor='bg-white'
                    />

                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <SelectForm
                            title='Sesso*'
                            options={[{ label: 'Donna', value: GENDER.FEMALE }, { label: 'Uomo', value: GENDER.MALE }, { label: 'Altro', value: GENDER.OTHER }]}
                            selectedOption={gender?.label}
                            setSelectedOption={(v) => setGender(v)}
                            arrow={false}
                            className='md:h-full h-9'
                        />
                        <InputForm
                            label='Età (opzionale)'
                            name='age'
                            backgroundColor='bg-white'
                        />
                    </div>
                </div>

                <p className='text-sm text-mediumGray font-medium'>
                    Verrà inviata un email al diretto interessato contenente un QR Code da mostrare al momento dell'ingresso.
                </p>

                <div className='flex flex-col gap-1'>
                    <p className='text-xs text-mediumGray font-medium'>* Campi obbligatori</p>
                    <CustomButton
                        disabled={navigation.state !== 'idle'}
                        containerClassName='flex md:items-end'
                        color='bg-text'
                        label="Registra"
                        type='submit'
                    />
                </div>
            </Form>
        </div>
    )
}

export default AddPartecipant