import React, { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import Switch from './Switch';
import Text from './Text';

type InputFormProps = {
    value?: any;
    defaultValue?: any;
    setValue?: (...args: any[]) => any;
    label?: string;
    type?: React.HTMLInputTypeAttribute;
    customStyle?: string;
    isDisabled?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    isSwitchActive?: boolean;
    switchBtn?: boolean;
    multiline?: boolean;
    multilineRows?: number;
    paddingX?: number;
    paddingY?: number;
    backgroundColor?: string;
    placeholder?: string;
    onSearchSubmit?: (...args: any[]) => any;
    onSearch?: (...args: any[]) => any;
    error?: boolean;
    errorMessage?: string;
    containerClassName?: string;
    min?: number;
    maxLenght?: number;
    inputRef?: React.MutableRefObject<HTMLInputElement | null>;
    name?: string;
    hidden?: boolean;
};

const InputForm: React.FC<InputFormProps> = ({ value, defaultValue, setValue, label, type = 'text', customStyle = '', isDisabled = false, fullWidth = false, fullHeight = false, isSwitchActive = false, switchBtn = false, multiline = false, multilineRows = 3, paddingX = 2, paddingY = 2, backgroundColor = 'bg-background', placeholder = '', onSearchSubmit, onSearch, error = false, errorMessage = 'Error', containerClassName = '', min = 0, maxLenght, inputRef, name, hidden = false }) => {
    const [isSwitchOn, setIsSwitchOn] = useState(isSwitchActive);

    const handleSwitchToggle = () => {
        setIsSwitchOn(prevIsSwitchOn => !prevIsSwitchOn);
    };

    useEffect(() => {
        if (value?.length >= 0 && onSearch) {
            const timer = setTimeout(() => {
                onSearch(value);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [value]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue && setValue(event.target.value);
    };

    const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && onSearchSubmit) {
            onSearchSubmit(value);
        }
    };

    function removeLeadingZero(event: FormEvent<HTMLInputElement>): void {
        const input = event.target as HTMLInputElement;
        if (input.value.startsWith('0') && input.value.length > 1 && type === 'number') {
            input.value = input.value.replace(/^0+/, '');
        }
    };

    const renderInput = () => {
        const inputClassNames = `${fullHeight ? 'h-full' : ''} px-${paddingX} py-${paddingY} rounded-md shadow text-sm font-medium focus:outline-none resize-none ${backgroundColor} ${customStyle} ${fullWidth ? 'w-full' : ''}`;

        if (multiline) {
            return (
                <textarea
                    value={value}
                    defaultValue={defaultValue}
                    onChange={handleInputChange}
                    rows={multilineRows}
                    placeholder={placeholder}
                    className={inputClassNames}
                    disabled={isDisabled}
                    name={name}
                />
            );
        } else {
            return (
                <input
                    name={name}
                    onClick={(e) => e.stopPropagation()}
                    onInput={removeLeadingZero}
                    ref={inputRef}
                    maxLength={maxLenght}
                    min={min}
                    type={type}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    className={inputClassNames}
                    disabled={isDisabled}
                    onKeyDown={handleInputKeyDown}
                    title={label}
                />
            );
        }
    };

    const containerClassNames = `${fullWidth ? 'w-full' : ''}  ${multiline ? 'h-auto' : ''} ${fullHeight ? 'h-full' : ''} ${containerClassName} flex flex-col gap-2 ${hidden ? 'hidden' : ''}`;

    return (
        <div className={containerClassNames}>
            {(label || switchBtn) && (
                <div className='flex items-center justify-between'>
                    {label && <Text title={label} label=' ' />}
                    {switchBtn && <Switch isOn={isSwitchOn} toggleSwitch={handleSwitchToggle} />}
                </div>
            )}

            {switchBtn && !isSwitchOn ? null : renderInput()}
            {error && <p className="text-error text-xs md:text-sm font-medium">{errorMessage}</p>}
        </div>
    );
};

export default InputForm;
