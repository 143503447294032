import { Form, useActionData, useLoaderData, useNavigation } from 'react-router-dom';
import PageTamplate from '../../components/PageTamplate';
import PageTitle from '../../components/PageTitle';
import { IList } from '../../types/interfaces/list.interface';
import { IEvent } from '../../types/interfaces/event.interface';
import { IUser } from '../../types/interfaces/auth.interface';
import { AddIcon } from '../../utils/icons/AddIcon';
import useComponentVisible from '../../hooks/useComponentVisible';
import Modal from '../../components/Modal';
import InputForm from '../../components/InputForm';
import CustomButton from '../../components/CustomButton';
import { CREATION_MODE } from '../../types/enum/creationMode.enum';
import { useEffect, useState } from 'react';
import Text from '../../components/Text';
import { IPartecipant } from '../../types/interfaces/partecipant.interface';
import { usePartecipantApi } from '../../services/routingApi/partecipant.api';
import SelectForm from '../../components/SelectForm';
import { GENDER } from '../../types/enum/gender.enum';
import { IPr } from '../../types/interfaces/pr.interface';

const ListDetails = () => {
    const response = useLoaderData() as IList<IPartecipant<string, IPr, IUser>, IEvent>;
    const actionResponse = useActionData() as IPartecipant<string, IPr, IUser> | { errorCode: string, status: number, message?: string };
    const navigation = useNavigation();

    const { updatedStatus } = usePartecipantApi();

    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [selectedPeopleListed, setSelectedPeopleListed] = useState<IPartecipant<string, IPr, IUser> | undefined>();
    const [list, setList] = useState<IList<IPartecipant<string, IPr, IUser>, IEvent>>(response);
    const [gender, setGender] = useState<{ value: GENDER, label: string } | undefined>(undefined);

    useEffect(() => {
        if (!!actionResponse && !('errorCode' in actionResponse)) {
            setIsComponentVisible(false);

            setList(prevList => {
                let partecipants = prevList.people;
                partecipants.push(actionResponse as IPartecipant<string, IPr, IUser>);
                return { ...prevList, people: partecipants };
            });
        }
    }, [actionResponse]);

    const handleSelectRow = (person: IPartecipant<string, IPr, IUser>) => {

        if (selectedPeopleListed?._id === person._id) {
            setSelectedPeopleListed(undefined);
        } else {
            setSelectedPeopleListed(person);
        }
    };

    const handleChangePeopleStatus = async (partecipant: IPartecipant<string, IPr, IUser>) => {
        if (partecipant._id) {
            const response = await updatedStatus(partecipant);

            if (!!response && !('errorCode' in response)) {
                setSelectedPeopleListed(undefined);

                setList(prevList => {
                    const partecipants = prevList.people;
                    const index = partecipants.findIndex((p: IPartecipant<string, IPr, IUser>) => p._id === partecipant._id);
                    partecipants[index].status = !partecipants[index].status;
                    return { ...prevList, people: partecipants };
                });
            }
        }
    };

    return (
        <PageTamplate classname='overflow-y-auto overflow-x-hidden select-none px-0'>
            <div className='px-4'>
                <PageTitle title={'Lista'} subTitle={list.event.name} goBackBtn rightBtn={<button type='button' className='bg-text py-2 px-4 rounded-lg shadow text-white flex items-center' onClick={() => setIsComponentVisible(true)}><AddIcon size='24' /></button>} />
            </div>

            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-3 md:grid-cols-5 px-4'>
                    <Text title='Nome' label={' '} />
                    <Text title='Cognome' label={' '} />
                    <Text title='Età' label={' '} />
                    <Text containerClassName='hidden md:flex' title='Referente' label={' '} />
                    <Text containerClassName='hidden md:flex' title='Iscrizione' label={' '} />

                </div>
                <div>
                    {!!list?.people?.length
                        ? list.people.map((person, index) => {
                            const pr = !!person.pr ? person.pr : !!person.userPr ? person.userPr : null;
                            return <div key={index} className={`${selectedPeopleListed?._id === person._id ? ' pl-2 pr-4' : 'px-4'} flex relative items-center w-full h-fit`}>
                                <div className={`w-full grid grid-cols-3 md:grid-cols-5 items-center ${selectedPeopleListed?._id === person?._id ? 'shadow rounded-l pl-2' : 'pl-0'} py-2 z-10 relative cursor-pointer`} onClick={() => handleSelectRow(person)}>
                                    <Text title=' ' label={person.name} />
                                    <Text title=' ' label={person.surname} />
                                    <Text title=' ' label={person.age ?? ' '} />
                                    <Text title=' ' label={!!pr ? pr?.name + ' ' + pr?.surname : " "} containerClassName='hidden md:flex' />
                                    <Text title=' ' label={person.creationMode === CREATION_MODE.AUTO ? 'Online' : 'PR'} containerClassName='hidden md:flex' />
                                    <div className={`absolute h-4 w-4 aspect-square rounded-full right-2 ${!!person.status ? 'bg-success' : 'bg-mediumGray'}`} />
                                </div>
                                <button onClick={() => handleChangePeopleStatus(person)} className={`${selectedPeopleListed?._id === person._id ? 'text-opacity-100 bg-opacity-100 w-20 rounded-r' : 'text-opacity-0 bg-opacity-0 w-0'} ${!!person.status ? 'bg-error' : 'bg-success'} py-2 h-full text-text_white z-0 focus:outline-none text-sm font-semibold right-4 transition-all duration-500 ease-in-out`}>
                                    {!!person.status ? "Esci" : "Entra"}
                                </button>
                            </div>
                        })
                        : <p className='text-mediumGray text-center font-medium mt-40'>Nessun partecipante aggiunto.</p>
                    }
                </div>
            </div>

            {/* Modal to add a new participant */}
            <Modal isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)} classNameChildren='p-4 h-full gap-1 flex flex-col'>
                <p className='text-text font-bold text-2xl'>Aggiungi Partecipante</p>
                <p className='text-text font-medium text-sm leading-normal'>
                    Aggiungendo un partecipante, riceverà una email con un QR code per entrare all'evento.<br />
                    Verifica che l'email sia corretta! Se sbagli, elimina il partecipante e aggiungilo di nuovo con l'indirizzo giusto.
                </p>


                <Form method='post' className='flex flex-col gap-6'>
                    <div className='grid grid-cols-2 gap-2'>
                        <InputForm isDisabled={navigation.state !== 'idle'} label='Email' name='email' fullWidth paddingY={1.5} containerClassName='gap-y-1 col-span-2' />
                        <InputForm isDisabled={navigation.state !== 'idle'} label='Nome' name='name' fullWidth paddingY={1.5} containerClassName='gap-y-1' />
                        <SelectForm title='Sesso*' arrow={false} containerClassName='gap-y-1' className='shadow-md'
                            options={[{ label: 'Donna', value: GENDER.FEMALE }, { label: 'Uomo', value: GENDER.MALE }, { label: 'Altro', value: GENDER.OTHER }]}
                            selectedOption={gender?.label} setSelectedOption={(v) => setGender(v)}
                        />
                        <InputForm isDisabled={navigation.state !== 'idle'} label='Cognome' name='surname' fullWidth paddingY={1.5} containerClassName='gap-y-1' />
                        <InputForm isDisabled={navigation.state !== 'idle'} label='Eta' name='age' type='number' fullWidth paddingY={1.5} containerClassName='gap-y-1' />
                    </div>

                    <input hidden defaultValue={list._id} name='list' />
                    <input hidden defaultValue={CREATION_MODE.MANUAL} name='creationMode' />
                    <input hidden defaultValue={gender?.value} name='gender' />

                    <div className='flex justify-between items-center gap-2'>
                        <CustomButton type='button' color='bg-white' label='Annulla' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => setIsComponentVisible(false)} disabled={navigation.state !== 'idle'} />
                        <CustomButton type='submit' color='bg-text' label='Aggiungi' className='border-2 border-text' disabled={navigation.state !== 'idle'} />
                    </div>
                </Form>
            </Modal>

        </PageTamplate >

    )
}

export default ListDetails