import React, { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import PageTitle from '../../components/PageTitle'
import { GoBackIcon } from '../../utils/icons/GoBackIcon'
import TimePicker from '../../components/TimePicker'
import SearchBarAutoComplete from '../../components/SearchBarAutoComplete'
import { IEvent } from '../../types/interfaces/event.interface'
import { useListApi } from '../../services/routingApi/list.api'
import { useEventApi } from '../../services/routingApi/event.api'
import useComponentVisible from '../../hooks/useComponentVisible'
import CustomButton from '../../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { IList } from '../../types/interfaces/list.interface'
import DatePicker from '../../components/DatePicker'
import moment from 'moment'
import Text from '../../components/Text'
import { SwitchIcon } from '../../utils/icons/SwitchIcon'
import { colorPalette } from '../../types/enum/colorPalette'

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    event?: IEvent;
};

const ListCreate: React.FC<IProps> = ({ isOpen, onClose, event }) => {
    const navigate = useNavigate();
    const { isComponentVisible: isDatePickerOpen, setIsComponentVisible: setIsDatePickerOpen } = useComponentVisible(false);

    const { createList } = useListApi();
    const { searchEventByName } = useEventApi();

    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState<IList<string, IEvent> | undefined>({} as IList<string, IEvent>);

    useEffect(() => {
        if (event) {
            setList({
                event: event,
                limitDateTime: new Date(`${moment(event.date).format('YYYY-MM-DD')}T13:00:00`)
            } as IList<string, IEvent>);
        }
    }, [event]);

    const handleCreateList = async () => {
        setIsLoading(true);

        if (!list?.event) return

        const response = await createList(list);

        setIsLoading(false);

        if (!response || 'errorCode' in response) {
            return
        }

        if (response?._id) {
            navigate(`/dashboard/list/${response._id}`);
        }
    };

    const handleRowClick = (event: IEvent) => {
        setList({
            ...list,
            event: event,
            limitDateTime: new Date(`${moment(event.date).format('YYYY-MM-DD')}T13:00:00`)
        } as IList<string, IEvent>);
    };

    const handleSearchEvent = async (searchTerm: string) => {
        const response = await searchEventByName(searchTerm);

        if (!!response && !('errorCode' in response)) {
            return response;
        };
    };

    const handleDatePick = (newDate: string) => {
        if (list) {
            const updatedDate = moment(newDate).format('YYYY-MM-DD');
            const updatedDateTime = `${updatedDate}T${moment(list.limitDateTime).format('HH:mm:ss')}`;

            setList({
                ...list,
                limitDateTime: new Date(updatedDateTime),
            });
        }
    };

    const handleTimePick = (newTime: string) => {
        if (list) {
            const updatedTime = newTime;
            const updatedDateTime = `${moment(list.limitDateTime).format('YYYY-MM-DD')}T${updatedTime}:00`;

            setList({
                ...list,
                limitDateTime: new Date(updatedDateTime),
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} classNameChildren='p-4 h-full gap-4 flex flex-col justify-between overflow-auto hidden-scrollbar' fullScreen>

            <div className='flex flex-col gap-6'>
                <div className='flex flex-col gap-6'>
                    <PageTitle
                        title='Nuova Lista'
                        goBackBtn={false}
                        rightBtn={
                            <div onClick={onClose} className='cursor-pointer border-2 bg-white shadow rounded-lg flex items-center justify-center p-2 w-fit aspect-square'>
                                <GoBackIcon />
                            </div>
                        }
                    />

                    <hr className='border-t border-mediumGray -mx-4' />

                    <p className='text-text font-medium text-sm'>
                        Crea una nuova lista per il tuo evento e tieni tutto organizzato!<br /><br />

                        Ricorda:<br />
                        - Ogni lista deve essere collegata a un evento specifico.<br />
                        - Puoi creare liste solo per eventi che si terranno entro i prossimi 4 mesi.
                    </p>
                </div>

                <hr className='border-t border-mediumGray -mx-4' />

                <div className='flex flex-col gap-6'>
                    {/* Event selected */}
                    {
                        !!list?.event && (
                            <div className='flex flex-col gap-2'>
                                {!event && <Text
                                    title='Evento Selezionato'
                                    label={"Puoi sempre cambiare l'evento prima di creare la lista. Clicca sull'icona dello switch per modificarlo."}
                                />}

                                {!list.event.poster &&
                                    <p className='text-warning text-lg font-medium text-center'>
                                        Attenzione! Non possiamo collegare questo evento a una lista perché manca la locandina. 🎨
                                        <br />
                                        Per procedere, ti preghiamo di aggiornare l'evento aggiungendo una locandina. Grazie!
                                    </p>
                                }

                                <div className='flex items-center justify-between bg-lightGray shadow px-4 py-2 -mx-4'>
                                    <Text
                                        title={list?.event.name}
                                        label={moment(list?.event.date).format('dddd DD MMMM')}
                                        className='capitalize'
                                        containerClassName='capitalize'
                                    />
                                    {!event && <button type='button' onClick={() => setList({} as IList<string, IEvent>)}>
                                        <SwitchIcon size='32' color={colorPalette.Info} />
                                    </button>}
                                </div>
                            </div>
                        )
                    }

                    {(!!list?.event && !!list.event.poster) && <hr className='border-t border-mediumGray -mx-4' />}

                    {/* Date & Time picker */}
                    {(!!list?.event && !!list.event.poster) &&
                        <div className='flex flex-col gap-4 w-full items-center md:items-start'>
                            <Text title="Seleziona limite per l'iscrizione" label="Imposta la data e l'ora entro cui i partecipanti possono registrarsi. Dopo questo termine, l'iscrizione non sarà più possibile." />

                            <div className='flex flex-col max-md:items-center gap-4 w-full'>
                                <div className='w-full md:w-fit'>
                                    <DatePicker
                                        bgColor='bg-background'
                                        isOpen={isDatePickerOpen}
                                        setIsOpen={setIsDatePickerOpen}
                                        onDatePick={handleDatePick}
                                        selectedDate={moment(list?.limitDateTime).toISOString()}
                                        maxDate={moment(list?.event.date).toISOString()}
                                        minDate={moment().toISOString()}
                                    />
                                </div>

                                <TimePicker
                                    selectedTime='15:00'
                                    onTimePick={handleTimePick}
                                />
                            </div>
                        </div>
                    }

                    {/* Search events if not selected */}
                    {
                        !list?.event && (
                            <div className='flex flex-col gap-2'>
                                <p className='text-text font-semibold text-xl'>Evento</p>
                                <SearchBarAutoComplete<IEvent<string, string, string, string>> searchApiCall={handleSearchEvent} fieldToPrint={['name', 'date']} onClickAction={handleRowClick} />
                            </div>
                        )
                    }

                    {(!!list?.event && !!list.event.poster) && <hr className='border-t border-mediumGray -mx-4' />}

                    {(!!list?.event && !!list.event.poster) &&
                        <Text title='Limite' label={moment(list?.limitDateTime).format('dddd DD MMMM HH:mm')} className='capitalize pb-4' reverseFontStyle={true} />
                    }
                </div>
            </div>

            {/* Buttons to create */}
            <div className='flex flex-col sm:flex-row justify-between items-center w-full gap-2'>
                <CustomButton label="Annulla" fullWidth={true} containerClassName='sm:w-fit' disabled={isLoading} onClickHandler={() => { navigate('/dashboard/servizi') }} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' />

                <CustomButton label='Salva ed esci' fullWidth={true} containerClassName='sm:w-fit' disabled={isLoading || !list?.event || !list.event.poster} onClickHandler={handleCreateList} color='bg-text' className='border-2 border-text' />
            </div>

        </Modal>
    )
}

export default ListCreate