import React, { useEffect, useState } from 'react'
import Text from './Text';
import moment from 'moment';

interface IProps<T> {
    searchApiCall: (searchTerm: string) => Promise<T[] | undefined>;
    onClickAction?: (suggestion: T) => void;
    suggestionRightBtn?: (suggestion: T) => JSX.Element;

    fieldToPrint: [keyof T] | [keyof T, keyof T];
    label?: string;
    placeholder?: string;
}

const SearchBarAutoComplete = <T,>({
    searchApiCall,
    onClickAction,
    suggestionRightBtn,
    fieldToPrint,
    label = '',
    placeholder = 'Cerca...'
}: IProps<T>): JSX.Element => {

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [suggestions, setSuggestions] = useState<T[]>([])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (searchTerm) {
                handleSearch()
            }
        }, 500)

        return () => clearTimeout(timeout)
    }, [searchTerm])

    const handleSearch = async () => {
        const suggestions = await searchApiCall(searchTerm);

        if (typeof suggestions !== 'undefined')
            setSuggestions(suggestions);
    }

    const handleClickSuggestion = (suggestion: any) => {
        setSearchTerm(suggestion[fieldToPrint[0]]);

        if (!!onClickAction)
            onClickAction(suggestion);
    }

    const renderSuggestion = (suggestion: any) => {

        let formattedFieldToPrint1 = fieldToPrint[1] ? suggestion[fieldToPrint[1]] : ''

        if (moment(formattedFieldToPrint1).isValid()) {
            formattedFieldToPrint1 = moment(formattedFieldToPrint1).format('dddd DD MMMM YYYY')
        }

        return <div className='flex justify-between items-center gap-1'>
            <Text label={formattedFieldToPrint1} title={suggestion[fieldToPrint[0]]} className='capitalize' containerClassName='truncate' />
            {suggestionRightBtn ? suggestionRightBtn(suggestion) : null}
        </div>
    }

    return (
        <div className='w-full h-full'>
            <Text label={''} title={label} />
            <input
                className='w-full p-2 border border-black rounded'
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* render suggestions */}
            <div className='overflow-y-auto overflow-x-hidden h-72 md:h-96'>
                {suggestions.length > 0
                    ? suggestions.map((suggestion, index) => (
                        <div key={index} className='p-2 hover:bg-lightGray cursor-pointer' onClick={() => handleClickSuggestion(suggestion)}>
                            {renderSuggestion(suggestion)}
                        </div>
                    ))
                    : searchTerm.length > 0
                        ? <p className='text-center text-mediumGray font-medium mt-10'>Nessun risultato trovato.</p>
                        : <p className='text-center text-mediumGray font-medium mt-10'>Inserisci un testo per cercare.</p>
                }
            </div>

        </div>
    )
}

export default SearchBarAutoComplete