import { Navigate, Outlet } from 'react-router-dom'
import useCookie from '../../hooks/useCookie';
import { IminDashboard } from '../../utils/icons/IminDashboard';
import { SquaringCircles } from '../../utils/icons/SquaringCircles';

const PrLayout = () => {
    const { cookieData } = useCookie({ pr_session: 'pr_session' });

    if (!cookieData.pr_session) {
        return <Navigate to="/auth/pr/login" />;
    };

    return (
        <div className="flex flex-col h-full w-full justify-between overflow-auto hidden-scrollbar">

            <Outlet />

            <footer className='flex w-full items-center justify-between bg-primary p-4'>
                <IminDashboard color='#FFF' />
                <div className='flex flex-col gap-1 items-center'>
                    <p className='font-medium text-xs text-text_white'>Powered by</p>
                    <a href='https://thesquaringcircles.com/' target='_blank' rel='noreferrer'><SquaringCircles /></a>
                </div>
            </footer>
        </div>
    )
}

export default PrLayout