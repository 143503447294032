import { LoaderFunctionArgs } from "react-router-dom";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";
import { apiService, HttpMethod } from "../apiService";
import { IList } from "../../types/interfaces/list.interface";
import { AxiosError } from "axios";
import { IEvent } from "../../types/interfaces/event.interface";
import { IUser } from "../../types/interfaces/auth.interface";
import { IPartecipant } from "../../types/interfaces/partecipant.interface";
import { IPagination } from "../../types/interfaces/pagination.interface";
import { Paginated } from "../../types/interfaces/paginated.interface";

export function useListApi() {
    const { showErrorModal } = useModalDispatcher();

    const createList = async (list: IList<string, IEvent>) => {
        try {
            const response = await apiService<IList | { errorCode: string, status: number, message?: string }>(HttpMethod.POST, '/pr-list/create', { event: list.event, limitDateTime: list.limitDateTime });

            if (response instanceof AxiosError) {
                throw response;
            }

            if ('errorCode' in response) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return response
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const listPrLists = async () => {
        try {
            const response = await apiService<IList<string, IEvent>[] & { errorCode: string, status: number, message?: string }>(HttpMethod.GET, '/pr-list/list')

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return response;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const getOneList = async (args: LoaderFunctionArgs) => {
        try {
            const id = args.params.id;

            const response = await apiService<IList<IPartecipant<string, IUser>, IEvent> & { errorCode: string, status: number, message?: string }>(HttpMethod.GET, '/pr-list/get/' + id);

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return response;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const getListByToken = async (args: LoaderFunctionArgs) => {
        try {
            const token = args.params.token;

            const response = await apiService<IList<string, IEvent> & { errorCode: string, status: number, message?: string }>(HttpMethod.GET, '/pr-list/get-by-token/' + token);

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return response;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const getListPaginated = async (args: LoaderFunctionArgs) => {
        try {
            const id = args.params.id;
            const params = new URLSearchParams(args.request.url.substring(args.request.url.indexOf('?') + 1));

            let page_size = params.get('size') ? Number(params.get('size')) : 50;

            if (page_size !== 50 && page_size !== 100 && page_size !== 200) {
                page_size = 50
            }

            const body: IPagination<IList> = {
                pageSize: page_size,
                currentPage: params.get('page') ? Number(params.get('page')) : 1,
                filters: {}
            }

            const response = await apiService<Paginated<IList> & { errorCode: string, status: number, message?: string }>(HttpMethod.POST, '/pr-list/get/paginated/' + id, { pagination: body });

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return response.data;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return {
        createList,
        listPrLists,
        getOneList,
        getListByToken,
        getListPaginated
    };
}