import { useLoaderData } from "react-router-dom"
import { ICategory } from "../../../types/interfaces/category.interface";
import { IminError } from "../../../types/interfaces/iminError.interface";
import GenericError from "../../error/GenericError";
import { IProduct } from "../../../types/interfaces/product.inteface";
import { IStructure } from "../../../types/interfaces/structure.interface";
import { IOffer } from "../../../types/interfaces/offer.interface";
import { useState } from "react";
import PageTitle from "../../../components/PageTitle";
import MenuSelected from "./MenuSelected";
import { ArrowRightForward } from "../../../utils/icons/ArrowIcon";

const DigitalMenu = () => {
    const response = useLoaderData() as ICategory<IProduct, IStructure, IOffer>[] | IminError;

    const [selectedOffer, setSelectedOffer] = useState<IOffer | undefined>(undefined);

    if ('errorCode' in response) return <GenericError redirectUrl="/" />

    const menus = response as ICategory<IProduct, IStructure, IOffer>[];

    if (menus.length === 0) return <GenericError redirectUrl="/" />

    const structure = menus[0].structureId as IStructure;


    const groupedMenus = menus?.reduce((acc, menu) => {
        const offerName = menu.offer.name;

        if (!acc[offerName]) {
            acc[offerName] = [];
        }

        acc[offerName].push(menu);

        return acc;
    }, {} as Record<string, ICategory<IProduct, IStructure, IOffer>[]>);

    return (
        <div className='flex flex-col justify-between'>

            <div className='w-full bg-white shadow px-8 py-4 text-center'>
                <p className='line-clamp-1 font-bold text-4xl text-primary'>{structure.name}</p>
                <p className='line-clamp-1 font-base text-sm text-text'>{structure.address.city} ({structure.address.state}), {structure.address.street}</p>
            </div>


            <div className='flex flex-col justify-between gap-8'>

                {!selectedOffer && <div className="p-4 pb-0">
                    <PageTitle title={'Servizi'} subTitle="Seleziona un servizio per vederne il menu" goBackBtn={false} />
                </div>}

                {!!selectedOffer
                    ? <MenuSelected offer={selectedOffer} categories={menus.filter(menu => menu.offer._id === selectedOffer._id)} goBackAction={() => setSelectedOffer(undefined)} />
                    : <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 p-4 pb-8 pt-0">
                        {Object.entries(groupedMenus).map(([offerName, menuArray], index) => {
                            return <button key={index} onClick={() => setSelectedOffer(menuArray[0].offer)} type="button" className='w-full bg-white shadow rounded-md flex flex-col gap-2 py-2'>
                                <div className="flex justify-between items-center w-full px-4">
                                    <h1 className='line-clamp-1 font-bold text-2xl text-text'>{offerName}</h1>
                                    <ArrowRightForward color="131313" />
                                </div>

                                <div className="w-full border-t-2 border-mediumGray mt-4" />

                                <div className="flex justify-center items-center gap-1 w-full px-4">
                                    {menuArray[0].offer.timetable?.map((timetable, index: number) => {
                                        const isUnavailable = timetable?.isAvailable ? 'opacity-100' : 'opacity-20';
                                        return <p key={index} className={`uppercase font-medium text-sm text-text ${isUnavailable}`}>{timetable.day.toString()[0]}</p>
                                    })}

                                </div>
                            </button>
                        })}
                    </div>
                }
            </div>
        </div>
    )
}

export default DigitalMenu