import { Form, useLoaderData, useNavigate, useSubmit } from "react-router-dom"
import InputForm from "../../../components/InputForm";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import CustomButton from "../../../components/CustomButton";
import CopyToClipboard from "../../../components/CopyToClipboard";
import { IAutoBookingOption } from "../../../types/interfaces/autoBookingOptions.interface";
import { AutoBookingType } from "../../../types/enum/autoBookingType.enum";
import Switch from "../../../components/Switch";
import { IProposal } from "../../../types/interfaces/proposal.interface";
import { IOffer } from "../../../types/interfaces/offer.interface";
import { IProduct } from "../../../types/interfaces/product.inteface";
import { IStructure } from "../../../types/interfaces/structure.interface";
import RadioButton from "../../../components/RadioButton";
import PageTitle from "../../../components/PageTitle";
import DatePicker from "../../../components/DatePicker";

export interface FormField {
    name: string;
    label: string;
    isRequired: boolean;
};

const AutoBookingManage = () => {
    const { proposalsFetched, autoBookingOptions, offer } = useLoaderData() as { proposalsFetched: IProposal<IStructure, IOffer, IProduct>[], autoBookingOptions: IAutoBookingOption<IStructure, string, IProposal[]>, offer: IOffer };
    const submit = useSubmit();

    const navigate = useNavigate();
    const formData = useRef() as any;

    const [isFetched, setIsFetched] = useState(false);
    const [bookingMode, setBookingMode] = useState<AutoBookingType>(AutoBookingType.AUTOMATIC);
    const [isBookingOnlineActive, setIsBookingOnlineActive] = useState(true);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().utc(true).toISOString());
    const [proposals, setProposals] = useState<string[]>([]);
    const [maxPeoplePerBooking, setMaxPeoplePerBooking] = useState(0);
    const [nBookingsAvailable, setNBookingsAvailable] = useState(0);
    const [info, setInfo] = useState('');

    const [errors, setErrors] = useState<Record<string, string | null>>({});


    const BOOKING_URL = (isFetched && offer?.structureId) ? `www.imin-dashboard.com/${encodeURIComponent(autoBookingOptions.structure.name) ?? "structure"}/${encodeURIComponent(offer.structureId)}` : undefined;

    useEffect(() => {
        if (autoBookingOptions?._id && !isFetched) {
            fillDataFromAutoBookingOptions()
            setIsFetched(true);
        }
    }, [autoBookingOptions]);

    const fillDataFromAutoBookingOptions = () => {
        setMaxPeoplePerBooking(autoBookingOptions.maxPeoplePerBooking);
        setNBookingsAvailable(autoBookingOptions.nBookingsAvailable);
        setInfo(autoBookingOptions.info);
        setBookingMode(autoBookingOptions.type);
        setIsBookingOnlineActive(!autoBookingOptions.disabled);
        setSelectedDate(moment(autoBookingOptions.dateLimit).utc(true).toISOString());

        if (autoBookingOptions.proposals) {
            let proposalsIds: string[] = []

            autoBookingOptions.proposals.forEach(proposal => {
                if (proposal._id)
                    proposalsIds.push(proposal._id);
            })

            setProposals(proposalsIds);
        }
    }

    const manageProposal = (proposalId: string | undefined) => {
        if (!proposalId) return;

        if (proposals.length === 0) {
            setProposals([proposalId]);
            return;
        }

        setProposals(prevProposals => {
            if (prevProposals.includes(proposalId)) {
                return prevProposals.filter(proposal => proposal !== proposalId);
            } else {
                return [...prevProposals, proposalId];
            }
        })
    };


    const handleSetDate = (date: string) => {
        // if date is < today, then show error
        const today = moment().utc(true).toISOString();
        if (moment(date).isBefore(today)) {
            setSelectedDate(today);
            return;
        }

        setSelectedDate(date);
        setIsCalendarOpen(false);
    };

    const validate = () => {
        const errors: Record<string, string> = {};

        // get form data from form
        const data = new FormData(formData.current);

        //transform data to object
        const objData = Object.fromEntries(data.entries());

        // check if maxPeoplePerBooking is not empty
        if (!objData.maxPeoplePerBooking || Number(objData.maxPeoplePerBooking) <= 0 || Number(objData.maxPeoplePerBooking) >= 999) {
            errors.maxPeoplePerBooking = 'Il numero massimo di posti per prenotazione deve essere compreso tra 1 e 999';
        }

        // check if info is not empty
        if (!objData.info) {
            errors.info = 'Per favore, specifica alcune informazioni aggiuntive';
        }

        // check if nBookingsAvailable is not empty
        if (!objData.nBookingsAvailable || Number(objData.nBookingsAvailable) <= 0 || Number(objData.nBookingsAvailable) >= 999) {
            errors.nBookingsAvailable = 'Il numero di prenotazioni disponibili deve essere compreso tra 1 e 999';
        }

        // check if date is not empty
        if (!objData.dateLimit) {
            errors.date = 'La data limite è obbligatoria';
        }

        return errors;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            const formElement = e.target;
            const data = new FormData(formElement);
            submit(data,
                {
                    method: 'post',
                    action: `/dashboard/servizi/auto-booking/${offer._id}`,
                });
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <div className="flex flex-1 container mx-auto shadow bg-white my-12 rounded">
            <Form method="POST" className="h-full w-full flex flex-col p-8 gap-8 justify-between" ref={formData} onSubmit={handleSubmit}>
                <PageTitle title="Prenotazione Online" />

                <div className={`h-full flex flex-col gap-4 ${!isBookingOnlineActive && 'opacity-40'}`}>

                    <p className="text-lg font-medium">
                        Configura le impostazioni per le prenotazioni online.<br />
                        Attiva o disattiva il servizio, imposta la data ultima prenotabile e il numero massimo di posti per prenotazione. Aggiungi informazioni supplementari e proposte del servizio.
                    </p>

                    <div className='items-center flex justify-between'>
                        <p className="font-semibold text-text text-lg">- Prenotazione online attiva?</p>

                        <Switch isOn={isBookingOnlineActive === true} toggleSwitch={() => setIsBookingOnlineActive(!isBookingOnlineActive)} size="md" />
                    </div>

                    {/* Data prenotazione */}
                    <div className='flex flex-col gap-2'>
                        <p className="font-semibold text-text text-lg">- Data massima prenotabile</p>
                        <DatePicker
                            isOpen={isCalendarOpen}
                            setIsOpen={setIsCalendarOpen}
                            onDatePick={handleSetDate}
                            selectedDate={selectedDate}
                            bgColor="bg-background"
                        />
                    </div>

                    {/* Turni del servizio */}
                    <div className="flex flex-col gap-2 select-none">
                        <div className="flex flex-col">
                            <p className="font-semibold text-text text-lg">- Turni del servizio {offer?.name}</p>
                            <p className="text-xs text-darkGray">Per modificarli entra nel pannello "Servizi"</p>
                        </div>
                        <div className="flex gap-2">
                            {offer.shifts.map((shift, index) => (
                                <p key={index} className="text-lg font-medium shadow text-text bg-background rounded-lg px-4 py-1">{shift}</p>
                            ))}
                        </div>
                    </div>

                    {/* Posti max per ogni turno */}
                    <InputForm name='nBookingsAvailable' value={nBookingsAvailable} setValue={(value) => setNBookingsAvailable(value)} label="- Posti disponibili per turno" backgroundColor="bg-background" type="number" />
                    {errors.nBookingsAvailable && <p className="text-red-500">{errors.nBookingsAvailable}</p>}

                    {/* Posti max per ogni prenotazione */}
                    <InputForm name='maxPeoplePerBooking' value={maxPeoplePerBooking} setValue={(value) => setMaxPeoplePerBooking(value)} label="- Max posti singola prenotazione" backgroundColor="bg-background" type="number" />
                    {errors.maxPeoplePerBooking && <p className="text-red-500">{errors.maxPeoplePerBooking}</p>}

                    {/* Info del servizio */}
                    <InputForm name='info' value={info} setValue={(value) => setInfo(value)} label={`- Informazioni del servizio ${offer?.name}`} backgroundColor="bg-background" multiline multilineRows={6} />
                    {errors.info && <p className="text-red-500">{errors.info}</p>}

                    {/* hidden forms */}
                    <InputForm name='dateLimit' hidden value={selectedDate} />
                    <InputForm name='type' type="text" hidden value={bookingMode} />
                    <InputForm name='offer' type="text" hidden value={offer._id} />
                    <InputForm name='proposals' hidden value={JSON.stringify(proposals)} />
                    <InputForm name='disabled' hidden value={!isBookingOnlineActive} />
                    {/* end of hidden forms */}

                    <div className="w-full border-t border-darkGray" />

                    <div className='flex flex-col gap-2'>
                        <p className="font-semibold text-text text-lg">- Proposte</p>

                        {!!proposalsFetched &&
                            proposalsFetched.map((proposal: IProposal<IStructure, IOffer, IProduct>, index: number) => {
                                return (
                                    <RadioButton
                                        key={index}
                                        labelStyle="hover:text-primary"
                                        label={proposal.name}
                                        checked={!!proposals?.find(option => option === proposal._id)}
                                        onChange={() => manageProposal(proposal._id)}
                                    />
                                )
                            })
                        }
                    </div>
                </div>

                {/* URL prenotazione automatica */}
                {
                    isBookingOnlineActive && BOOKING_URL &&
                    <div className="flex flex-col gap-2">
                        <p className="font-semibold text-text text-lg">Condividi questo link con i tuoi clienti</p>
                        <div className="flex items-center justify-between bg-white rounded-l w-full shadow">
                            <div className="flex flex-col truncate p-4">
                                <p className="font-medium truncate select-text">{BOOKING_URL}</p>
                            </div>
                            <button type='button' className="bg-primary text-white rounded-r hover:bg-orange focus:outline-none focus:bg-orange relative">
                                <CopyToClipboard link={BOOKING_URL} padding={4} />
                            </button>
                        </div>
                    </div>
                }

                {/* footer save options */}
                <div className="flex w-full justify-between ">
                    <CustomButton type='button' color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' label="Annulla" onClickHandler={() => navigate(-1)} />
                    <CustomButton type="submit" color="bg-primary" label="Salva" textColor="text-text_white" />
                </div>
            </Form>
        </div>
    )
}

export default AutoBookingManage