export const PhotoPlaceHolderIcon = () => {
    return (
        <svg width="118" height="106" viewBox="0 0 118 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M59 85.3889C66.375 85.3889 72.6437 82.8125 77.8062 77.6597C82.9688 72.5069 85.55 66.25 85.55 58.8889C85.55 51.5278 82.9688 45.2708 77.8062 40.1181C72.6437 34.9653 66.375 32.3889 59 32.3889C51.625 32.3889 45.3562 34.9653 40.1937 40.1181C35.0312 45.2708 32.45 51.5278 32.45 58.8889C32.45 66.25 35.0312 72.5069 40.1937 77.6597C45.3562 82.8125 51.625 85.3889 59 85.3889ZM59 73.6111C54.87 73.6111 51.3792 72.188 48.5275 69.3417C45.6758 66.4954 44.25 63.0111 44.25 58.8889C44.25 54.7667 45.6758 51.2824 48.5275 48.4361C51.3792 45.5898 54.87 44.1667 59 44.1667C63.13 44.1667 66.6208 45.5898 69.4725 48.4361C72.3242 51.2824 73.75 54.7667 73.75 58.8889C73.75 63.0111 72.3242 66.4954 69.4725 69.3417C66.6208 72.188 63.13 73.6111 59 73.6111ZM0 106V11.7778H30.385L41.3 0H76.7L87.615 11.7778H118V106H0Z" fill="#1A1C20" />
        </svg>
    )
};

export const PhotoPlaceHolderIconXS = () => {
    return (
        <svg width="59" height="53" viewBox="0 0 59 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.5 42.6944C33.1875 42.6944 36.3219 41.4062 38.9031 38.8299C41.4844 36.2535 42.775 33.125 42.775 29.4444C42.775 25.7639 41.4844 22.6354 38.9031 20.059C36.3219 17.4826 33.1875 16.1944 29.5 16.1944C25.8125 16.1944 22.6781 17.4826 20.0969 20.059C17.5156 22.6354 16.225 25.7639 16.225 29.4444C16.225 33.125 17.5156 36.2535 20.0969 38.8299C22.6781 41.4062 25.8125 42.6944 29.5 42.6944ZM29.5 36.8056C27.435 36.8056 25.6896 36.094 24.2637 34.6708C22.8379 33.2477 22.125 31.5056 22.125 29.4444C22.125 27.3833 22.8379 25.6412 24.2637 24.2181C25.6896 22.7949 27.435 22.0833 29.5 22.0833C31.565 22.0833 33.3104 22.7949 34.7362 24.2181C36.1621 25.6412 36.875 27.3833 36.875 29.4444C36.875 31.5056 36.1621 33.2477 34.7362 34.6708C33.3104 36.094 31.565 36.8056 29.5 36.8056ZM0 53V5.88889H15.1925L20.65 0H38.35L43.8075 5.88889H59V53H0Z" fill="#1A1C20" />
        </svg>
    )
};