import { Form, useLoaderData } from "react-router-dom";
import { IPr } from "../../types/interfaces/pr.interface";
import { IStructure } from "../../types/interfaces/structure.interface";
import InputForm from "../../components/InputForm";
import CustomButton from "../../components/CustomButton";
import { IminError } from "../../types/interfaces/iminError.interface";
import GenericError from "../error/GenericError";


const UnsubscribePr = () => {
    const response = useLoaderData() as IPr<IStructure> | IminError;

    if ('errorCode' in response) return <GenericError redirectUrl="/" />

    const pr = response as IPr<IStructure>;

    return (
        <Form method='PUT' className='flex flex-col gap-8 justify-center items-center p-8 w-full'>

            <div className='w-full p-4 text-center flex flex-col gap-2'>
                <p className='font-bold text-4xl text-primary'>Vuoi disinscriverti come PR?</p>
                <p className='font-base text-sm text-text'>Una volta disincritto non riceverai piu email riguardanti eventi o liste, non potrai piu promuovere quest'ultime come PR su nessuna delle strutture dove sei attualmente registrato.</p>
            </div>


            <div className='m-auto px-4 w-full md:w-1/2 flex flex-col gap-8'>
                <InputForm label="Email" value={pr.email} backgroundColor='bg-white' isDisabled />

                {
                    pr.deleted
                        ? <p className='text-center font-medium text-xl text-darkGray underline'>Utente disiscritto</p>
                        : <CustomButton color='bg-error' label='Procedi' className='w-fit m-auto' type='submit' />
                }
            </div>

        </Form>
    )
}

export default UnsubscribePr