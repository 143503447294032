export enum FoodAllergen {
    PEANUTS = "PEANUTS",
    TREE_NUTS = "TREE NUTS",
    MILK = "MILK",
    EGGS = "EGGS",
    FISH = "FISH",
    SHELLFISH = "SHELLFISH",
    MOLLUSKS = "MOLLUSKS",
    GLUTEN = "GLUTEN",
    SOY = "SOY",
    SESAME = "SESAME",
    MUSTARD = "MUSTARD",
    CELERY = "CELERY",
    LUPIN = "LUPIN",
    SULPHITES = "SULPHITES"
};

export function translateAllergenToItalian(allergen: FoodAllergen): string {
    switch (allergen) {
        case FoodAllergen.PEANUTS:
            return "Arachidi";
        case FoodAllergen.TREE_NUTS:
            return "Frutta a guscio";
        case FoodAllergen.MILK:
            return "Latte";
        case FoodAllergen.MOLLUSKS:
            return "Molluschi";
        case FoodAllergen.EGGS:
            return "Uova";
        case FoodAllergen.FISH:
            return "Pesce";
        case FoodAllergen.SHELLFISH:
            return "Crostacei";
        case FoodAllergen.GLUTEN:
            return "Glutine";
        case FoodAllergen.SOY:
            return "Soia";
        case FoodAllergen.SESAME:
            return "Sesamo";
        case FoodAllergen.MUSTARD:
            return "Senape";
        case FoodAllergen.CELERY:
            return "Sedano";
        case FoodAllergen.LUPIN:
            return "Lupini";
        case FoodAllergen.SULPHITES:
            return "Solfiti";
        default:
            return "Allergene non riconosciuto";
    }
};