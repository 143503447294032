import { useEffect, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import PageTamplate from '../../../components/PageTamplate';
import CustomButton from '../../../components/CustomButton';
import SelectForm from '../../../components/SelectForm';
import ManageCategory from './ManageCategory';
import ManageProduct from './ManageProduct';
import useComponentVisible from '../../../hooks/useComponentVisible';
import useScreenSize from '../../../hooks/useScreenSize';
import { ICategory } from '../../../types/interfaces/category.interface';
import { IProduct } from '../../../types/interfaces/product.inteface';
import { colorPalette } from '../../../types/enum/colorPalette';
import { AddIcon } from '../../../utils/icons/AddIcon';
import { InfoIcon } from '../../../utils/icons/InfoIcon';
import { apiHandler } from '../../../services/apiHandler';
import { useModalDispatcher } from '../../../hooks/useModalDispatcher';
import PageTitle from '../../../components/PageTitle';
import CopyToClipboard from '../../../components/CopyToClipboard';

const MenuManage = () => {
    const categories = useLoaderData() as ICategory<IProduct>[];
    const screenSize = useScreenSize();
    const { showErrorModal } = useModalDispatcher();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isComponentVisible: createCategory, setIsComponentVisible: setCreateCategory } = useComponentVisible(false);
    const { isComponentVisible: createProduct, setIsComponentVisible: setCreateProduct } = useComponentVisible(false);


    const [product, setProduct] = useState<IProduct>();
    const [category, setCategory] = useState<ICategory<IProduct>>();

    const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([])
    const [selectedCategory, setSelectedCategory] = useState<ICategory<IProduct>>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!!categories?.length) {
            const index = categories.findIndex(category => category._id === selectedCategory?._id);
            if (index !== -1) {
                setSelectedCategory(categories[index])
            } else {
                setSelectedCategory(categories[0])
            }
        }
    }, [categories])


    const openModal = (type: 'CATEGORY' | "PRODUCT") => {
        if (type === 'CATEGORY') {
            setCategory(undefined);
            setCreateCategory(true);
        } else {
            setCreateProduct(true);
        }
    }

    const handleManageProduct = (product: IProduct, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();

        setProduct(product);
        setCreateProduct(true);
    };

    const handleManageCategory = () => {
        setCategory(selectedCategory);
        setCreateCategory(true);
    };

    const handleSelectAll = () => {
        const allProductsSelected = selectedProducts.length === categories.find(category => category._id === selectedCategory?._id)?.products.length;
        if (allProductsSelected) {
            setSelectedProducts([]);
        } else {
            const allProducts = categories.find(category => category._id === selectedCategory?._id)?.products || [];
            setSelectedProducts(allProducts.filter(product => typeof product !== 'string'));
        }
    };

    const handleProductSelect = (product: IProduct) => {
        const isSelected = selectedProducts.some(selectedProduct => selectedProduct._id === product._id);
        if (isSelected) {
            setSelectedProducts(selectedProducts.filter(selectedProduct => selectedProduct._id !== product._id));
        } else {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleDeleteProducts = async () => {
        try {
            setIsLoading(true);

            await apiHandler.post('product/delete/many', { ids: selectedProducts.map(prod => prod._id) });

            setSearchParams(searchParams);
            setSelectedProducts([]);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    return (
        <PageTamplate classname='overflow-y-auto overflow-x-hidden gap-12 md:pt-10 hidden-scrollbar'>

            <PageTitle
                title='Menu'
                rightBtn={
                    !!categories.length
                        ? <div className='flex gap-2 bg-white rounded-md shadow items-center'>
                            <p className='font-semibold text-lg px-4 select-none'>Link Menu</p>
                            <CopyToClipboard link={'https://www.imin-dashboard.com/digital/menu/' + categories[0].structureId} padding={2} iconClassName='bg-text rounded-r-md' />
                        </div>
                        : undefined
                }
            />

            {!!categories?.length
                ? <>
                    <div className='flex flex-col gap-6'>
                        <div className='flex justify-between items-center'>
                            <p className='text-xl md:text-2xl font-medium text-text line-clamp-2'>Categorie</p>
                            <button type='button' className='bg-text py-2 px-4 rounded-lg shadow' onClick={() => openModal('CATEGORY')}><AddIcon size='28' /></button>
                        </div>

                        {screenSize === 'xs' || screenSize === 'sm'
                            ? <div className='flex gap-4 items-center w-full'>
                                <SelectForm
                                    containerClassName='w-full'
                                    arrow={false}
                                    options={categories
                                        .filter(category => category._id !== selectedCategory?._id)
                                        .map(category => ({ label: category.name, value: category }))
                                    }
                                    selectedOption={categories.find(category => category._id === selectedCategory?._id)?.name}
                                    setSelectedOption={(e) => {
                                        setSelectedCategory(e.value);
                                    }}
                                />

                                {!!selectedCategory && <div onClick={handleManageCategory}>
                                    <InfoIcon color={colorPalette.Primary} size={28} />
                                </div>}
                            </div>
                            : <div className='flex justify-center'>
                                {categories.map((category, index) => {
                                    return <div key={index}>
                                        <button onClick={() => setSelectedCategory(category)} className={`px-4 py-1 whitespace-nowrap font-medium text-xl ${index === 0 && 'rounded-l-md'} ${index === categories.length - 1 && 'rounded-r-md'} shadow ${selectedCategory?._id === category._id ? 'bg-primary text-white' : 'bg-white text-text'}`}>
                                            <p>{category.name}</p>
                                        </button>
                                        {selectedCategory?._id === category._id && <div onClick={handleManageCategory} className='w-full text-text py-1 font-medium text-center cursor-pointer hover:underline'>Modifica</div>}
                                    </div>
                                })}
                            </div>
                        }
                    </div>

                    <div className='flex flex-col gap-6'>
                        <div className='flex justify-between items-center'>
                            <p className='text-xl md:text-2xl font-medium text-text line-clamp-2'>Prodotti</p>
                            {!!selectedCategory ? <button type='button' className='bg-text py-2 px-4 rounded-lg shadow' onClick={() => openModal('PRODUCT')}><AddIcon size='28' /></button> : null}
                        </div>

                        {!!categories.find(category => category._id === selectedCategory?._id)?.products.length
                            ? <div className='bg-white rounded-md shadow-md'>
                                <div className={`flex w-full items-center justify-between gap-4 p-4 ${selectedProducts.length === categories.find(category => category._id === selectedCategory?._id)?.products.length && 'bg-lightGray border-l-4 border-primary'}`}>
                                    <div className='flex items-center flex-1 gap-4'>
                                        <button type='button' onClick={handleSelectAll} className={`h-4 aspect-square shadow border border-text rounded ${(selectedProducts.length === categories.find(category => category._id === selectedCategory?._id)?.products.length) ? 'bg-primary' : 'bg-background'}`} />
                                        <p className=' w-full rounded-t-md font-semibold text-text text-lg'>Info</p>
                                    </div>
                                    {
                                        selectedProducts.length > 0
                                            ? <div className='flex items-center gap-6 self-center'>
                                                <p className='text-sm font-medium whitespace-nowrap'>{selectedProducts.length} selezionat{selectedProducts.length > 1 ? 'i' : 'o'}</p>
                                                <CustomButton disabled={isLoading} color='bg-error' label='Elimina' onClickHandler={handleDeleteProducts} padding='px-4 py-1' />
                                            </div>
                                            : <p className='col-span-1 text-center w-10 text-2xl'>#</p>
                                    }
                                </div>

                                <div className='border-b border-mediumGray w-full' />

                                {categories
                                    .find(category => category._id === selectedCategory?._id)?.products
                                    .sort((a, b) => {
                                        if (typeof a !== 'string' && typeof b !== 'string') {
                                            return a.name.localeCompare(b.name);
                                        } else {
                                            return typeof a === 'string' ? 1 : -1;
                                        }
                                    })
                                    .map((product, index) => {
                                        if (typeof product !== 'string') {
                                            return <div key={index} className={`${selectedProducts.some(selectedProduct => selectedProduct._id === product._id) && 'bg-lightGray border-l-4 border-primary'}`}>
                                                <div onClick={() => handleProductSelect(product)} className="flex items-center w-full last:rounded-b-md p-4 gap-4 font-medium text-darkGray text-opacity-60 text-sm cursor-pointer">
                                                    <div className={`h-4 aspect-square shadow border border-text rounded ${(selectedProducts.some(selectedProduct => selectedProduct._id === product._id)) ? 'bg-primary' : 'bg-background'}`} />

                                                    <img
                                                        className='h-10 aspect-video rounded-md shadow-md object-cover'
                                                        src={product.image || "https://via.placeholder.com/512x512/F5F5F5/?text=No+Image+Available"}
                                                        alt='img-suggestion'
                                                    />

                                                    <div className='flex flex-col gap-1 w-full'>
                                                        <div className='flex items-center justify-between w-full'>
                                                            <p className='text-lg font-semibold line-clamp-2 capitalize text-text'>{product.name} {product.is_frozen && "*"}</p>
                                                            <p className='col-span-1 md:text-base flex gap-2 whitespace-nowrap'>{product.price.toFixed(2)}{screenSize !== 'xs' && screenSize !== 'sm' && <span>€</span>}</p>
                                                        </div>
                                                        <p className='text-sm font-medium line-clamp-2'>{product.description}</p>
                                                    </div>

                                                    <button className='col-span-1 flex justify-center w-10' onClick={(e) => handleManageProduct(product, e)}><InfoIcon color={colorPalette.Primary} size={28} /></button>
                                                </div>
                                                {(index !== categories?.find(category => category._id === selectedCategory?._id)?.products?.length! - 1) && <div className='border-b border-mediumGray w-full' />}
                                            </div>
                                        }
                                        return null
                                    })
                                }
                            </div>
                            : <p className='flex h-full items-center justify-center font-medium text-sm text-darkGray whitespace-nowrap'>Nessun prodotto inserito.</p>
                        }
                    </div>
                </>
                : <div className='flex flex-col h-full w-full justify-center gap-8'>
                    <p className='font-medium text-center text-4xl text-darkGray'>Nessun prodotto presente</p>
                    <p className='font-medium text-center text-base text-darkGray'>Al momento, non hai ancora inserito nessun prodotto nel tuo menu.<br />Per iniziare a organizzare il tuo servizio, è necessario creare la prima categoria di prodotti.</p>
                    <CustomButton color='bg-primary' label='Aggiungi Categoria' onClickHandler={() => openModal('CATEGORY')} className='w-fit self-center' />
                    <ManageCategory isOpen={createCategory} setIsOpen={setCreateCategory} />
                </div>
            }

            <ManageCategory isOpen={createCategory} setIsOpen={setCreateCategory} element={category} setElement={setCategory} />
            <ManageProduct isOpen={createProduct} setIsOpen={setCreateProduct} category={selectedCategory} element={product} setElement={setProduct} image />

        </PageTamplate>
    )
}

export default MenuManage