import { useState } from "react";
import Drawer from "../../../components/Drawer";
import { colorPalette } from "../../../types/enum/colorPalette";
import { ICategory } from "../../../types/interfaces/category.interface";
import { IOffer } from "../../../types/interfaces/offer.interface";
import { IProduct } from "../../../types/interfaces/product.inteface";
import { IStructure } from "../../../types/interfaces/structure.interface";
import { GoBackIcon } from "../../../utils/icons/GoBackIcon";
import { InfoIcon } from "../../../utils/icons/InfoIcon";
import Text from "../../../components/Text";
import { getAllergenIcon } from "../../../utils/foodAllergenicsUtils";
import AllergensInfo from "./AllergensInfo";

interface IProps {
    categories: ICategory<IProduct, IStructure, IOffer>[];
    offer: IOffer;
    goBackAction: () => void;
};

const MenuSelected: React.FC<IProps> = ({ categories, offer, goBackAction }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(undefined);

    const showProductInfo = (product: IProduct) => {
        setSelectedProduct(product);
        setIsDrawerOpen(true);
    };

    const closeProductInfo = () => {
        setIsDrawerOpen(false);
    };

    return (
        <div className="flex flex-col pb-4 md:pb-8">
            <div className="flex items-center gap-2 border-b border-lightGray shadow-sm px-4 py-2.5">
                <button type='button' onClick={goBackAction} className='shadow bg-white rounded-lg p-1 aspect-square'>
                    <GoBackIcon />
                </button>
                <p className='text-2xl md:text-4xl font-semibold text-text line-clamp-2'>Menu {offer.name}</p>
            </div>

            <div className="flex flex-col">
                {categories.map((category, index) => {

                    if (!category.products.length) {
                        return null
                    }

                    return <div key={index} className="w-full flex flex-col">
                        <h1 className="line-clamp-1 font-bold text-lg text-text px-4 bg-background sticky shadow top-0 z-10 py-4 capitalize">
                            {category.name}
                        </h1>

                        <div className="flex flex-col gap-4 px-4 py-4">
                            {category.products
                                .sort((a, b) => {
                                    if (a.name < b.name) return -1;
                                    if (a.name > b.name) return 1;
                                    return 0
                                })
                                .map((product, index) => (
                                    <button key={index} type="button" onClick={() => showProductInfo(product)} className="flex justify-between items-baseline w-full gap-2">
                                        <div className="flex flex-col flex-1 justify-between">
                                            <div className="flex items-center justify-start gap-1 w-full">
                                                <div className="w-fit">
                                                    <InfoIcon color={colorPalette.DarkGray} size={16} />
                                                </div>

                                                <p className="line-clamp-1 text-base font-bold text-text capitalize text-left w-full">{product.name} {product.is_frozen && "*"}</p>

                                                <div className="flex items-center gap-0.5">
                                                    {!!product?.allergens?.length && product.allergens.map((allergen, index) => (
                                                        <div key={index}>{getAllergenIcon(allergen, undefined, '18')}</div>
                                                    ))}
                                                </div>
                                            </div>

                                            <p className="line-clamp-1 text-xs text-darkGray text-left whitespace-pre-line pl-5">
                                                {product.description}
                                            </p>
                                        </div>
                                        <p className="w-[76px] flex self- justify-end whitespace-nowrap text-sm font-bold text-text text-right">
                                            {product.price !== 0 ? product.price.toFixed(2) : 0} €
                                        </p>
                                    </button>
                                ))}
                        </div>

                        <hr className="border-t border-lightGray" />
                    </div>
                })}

            </div>

            <div className="flex flex-col gap-4 px-4 py-4">
                {
                    !!categories.some(category => category.products.some(product => product.is_frozen)) &&
                    <p className="text-sm font-medium text-text text-left">
                        I piatti contrassegnati con un asterisco (*) possono contenere ingredienti congelati quando i prodotti freschi non sono disponibili, per garantire la qualità delle nostre preparazioni.
                    </p>
                }



                <AllergensInfo />
            </div>

            <Drawer
                isOpen={isDrawerOpen}
                onClose={closeProductInfo}
                maxHeight="100%"
                initialHeight="60%"
                backgroundColor="white"
                opacityIntesity={3}
            >
                <div className="flex flex-col gap-2 h-full overflow-auto pb-10 hidden-scrollbar">

                    <h2 className="text-xl font-semibold capitalize text-center px-4">{selectedProduct?.name}</h2>

                    {selectedProduct?.image && <img src={selectedProduct?.image} alt={selectedProduct?.name} className="w-full aspect-video shadow" />}

                    <div className="flex justify-between items-center px-4 py-2">
                        <div className="flex items-center gap-0.5">
                            {!!selectedProduct?.allergens?.length && selectedProduct.allergens.map((allergen, index) => (
                                <div key={index}>{getAllergenIcon(allergen, undefined, '24')}</div>
                            ))}
                        </div>

                        <p className="whitespace-nowrap text-sm font-semibold text-text text-right">
                            {selectedProduct?.price !== 0 ? selectedProduct?.price.toFixed(2) : 0} €
                        </p>
                    </div>


                    <Text title="Descrizione" label={selectedProduct?.description} containerClassName="px-4" />
                </div>
            </Drawer>
        </div>
    )
}

export default MenuSelected