export const MustardIcon = ({ color = '#BD9A3C', size = '32' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_793_2)">
                <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill={color} />
                <path d="M13.589 20.0002L12.919 20.6702C12.8546 20.7379 12.777 20.7919 12.6911 20.8288C12.6051 20.8656 12.5126 20.8847 12.419 20.8847C12.3255 20.8847 12.2329 20.8656 12.147 20.8288C12.061 20.7919 11.9835 20.7379 11.919 20.6702L9.60903 18.3302C9.54126 18.2657 9.48729 18.1881 9.45042 18.1022C9.41355 18.0162 9.39453 17.9237 9.39453 17.8302C9.39453 17.7366 9.41355 17.6441 9.45042 17.5581C9.48729 17.4722 9.54126 17.3946 9.60903 17.3302L10.279 16.6602" stroke="white" stroke-miterlimit="10" />
                <path d="M14.0005 20.4104L9.88054 16.3004C9.75203 16.1747 9.64993 16.0245 9.58022 15.8588C9.51052 15.6931 9.47461 15.5151 9.47461 15.3354C9.47461 15.1556 9.51052 14.9776 9.58022 14.8119C9.64993 14.6462 9.75203 14.4961 9.88054 14.3704L17.7405 5.15037C17.9988 4.89511 18.3474 4.75195 18.7105 4.75195C19.0737 4.75195 19.4222 4.89511 19.6805 5.15037L25.1405 10.6104C25.3958 10.8687 25.539 11.2172 25.539 11.5804C25.539 11.9435 25.3958 12.2921 25.1405 12.5504L15.9205 20.4104C15.795 20.5374 15.6455 20.6382 15.4807 20.707C15.3159 20.7758 15.1391 20.8112 14.9605 20.8112C14.782 20.8112 14.6052 20.7758 14.4404 20.707C14.2756 20.6382 14.1261 20.5374 14.0005 20.4104Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.0001 18.6699L6.64009 22.7499C6.54084 22.8729 6.49057 23.0281 6.49895 23.1859C6.50733 23.3437 6.57378 23.4928 6.6855 23.6045C6.79722 23.7162 6.94631 23.7827 7.10409 23.7911C7.26186 23.7994 7.41716 23.7492 7.54009 23.6499L11.6301 20.3399L10.0001 18.6699Z" stroke="white" strokeMiterlimit="10" />
                <path d="M14.2902 27.2498H10.2902C10.1702 27.2628 10.0487 27.2504 9.93382 27.2134C9.81889 27.1763 9.71305 27.1155 9.62319 27.0349C9.53333 26.9542 9.46146 26.8555 9.41226 26.7453C9.36306 26.635 9.33763 26.5156 9.33763 26.3948C9.33763 26.2741 9.36306 26.1547 9.41226 26.0444C9.46146 25.9342 9.53333 25.8355 9.62319 25.7548C9.71305 25.6742 9.81889 25.6133 9.93382 25.5763C10.0487 25.5393 10.1702 25.5268 10.2902 25.5398H11.2402C11.4657 25.5398 11.6819 25.4503 11.8413 25.2909C12.0007 25.1315 12.0902 24.9153 12.0902 24.6898C12.0902 24.4644 12.0007 24.2482 11.8413 24.0888C11.6819 23.9294 11.4657 23.8398 11.2402 23.8398H7.24023" stroke="white" strokeMiterlimit="10" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_793_2">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};