import { ActionFunctionArgs, redirect } from "react-router-dom";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";
import { apiService, HttpMethod } from "../apiService";
import { AxiosError } from "axios";
import { IPartecipant } from "../../types/interfaces/partecipant.interface";
import { IUser } from "../../types/interfaces/auth.interface";
import { IList } from "../../types/interfaces/list.interface";
import { IStructure } from "../../types/interfaces/structure.interface";
import { IEvent } from "../../types/interfaces/event.interface";
import { IPr } from "../../types/interfaces/pr.interface";

function getNestedProperty<T>(obj: T, path: string[]): any {
    return path.reduce((acc: any, key) => acc && acc[key], obj);
};

export function usePartecipantApi() {
    const { showErrorModal, showModal } = useModalDispatcher();

    const addPeopleList = async ({ request }: ActionFunctionArgs, url = '/partecipant/add-user-to-list', redirectUrl?: string, fieldToRedirect?: string) => {
        try {
            const formData = await request.formData();
            let data = Object.fromEntries(formData) as any;

            const partecipant = await apiService<IPartecipant<IList<string, IEvent, IStructure>, string> | { errorCode: string, status: number, message?: string }>(HttpMethod.POST, url, data);

            if (partecipant instanceof AxiosError) {
                throw partecipant;
            }

            if ('errorCode' in partecipant) {
                showErrorModal(partecipant.status, partecipant.errorCode, partecipant.message);
                return partecipant;
            }

            showModal(200, data.name + ' ' + data.surname + ' aggiunto con successo!');

            if (!!redirectUrl) {
                if (fieldToRedirect) {
                    const fieldPath = fieldToRedirect?.split('.');
                    const value = getNestedProperty(partecipant, fieldPath);
                    return redirect(redirectUrl + value);
                } else {
                    return redirect(redirectUrl);
                }
            } else {
                return partecipant;
            }
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const updatedStatus = async (partecipant: IPartecipant<string, IPr, IUser>) => {
        try {
            const response = await apiService<IPartecipant | { errorCode: string, status: number, message?: string }>(HttpMethod.PUT, '/partecipant/update/status/' + partecipant._id, { status: !partecipant.status });

            if (response instanceof AxiosError) {
                throw response;
            }

            if ('errorCode' in response) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            showModal(200, partecipant.name + ' ' + partecipant.surname + ' aggiornato!');

            return response;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    return {
        addPeopleList,
        updatedStatus,
    };
}