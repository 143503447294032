import { NavLink } from 'react-router-dom';
import { NAV_ACTIONS } from '../types/enum/navbarActions';
import { SidebarDataAdmin } from './sidebarData';
import useCookie from '../hooks/useCookie';
import { IminLogoSvg } from '../utils/icons/IminLogoSvg';
import { useAppDispatch, useAppSelector } from '../store/store';
import { setSideBarState } from '../store/UI/uiSlice';
import { LockIcon } from '../utils/icons/LockIcon';
import { IUserStructure } from '../types/interfaces/userStructure.interface';
import { IUser } from '../types/interfaces/auth.interface';
import { IStructure } from '../types/interfaces/structure.interface';
import { IPlugin } from '../types/interfaces/plugin.interface';
import { PluginIdentifier } from '../types/enum/pluginIdentifier';
import { replaceUserAuth } from '../store/auth/authSlice';

const activeLink = 'p-2 w-full flex justify-start items-center no-underline text-primary font-semibold border-l-4 border-primary';
const normalLink = 'p-2 w-full flex justify-start items-center no-underline rounded-md text-text hover:text-primary font-medium';

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const { sideBarState } = useAppSelector(state => state.ui);
    const userStructure = useAppSelector<{ user: IUser, structures: IStructure[], userStructure: IUserStructure<IStructure<IPlugin, string>, string, IPlugin> }>(state => state.account.userStructure);
    const { globalDate } = useAppSelector(state => state.booking);

    const { removeCookie } = useCookie({
        userData: 'user_cookie',
        structureData: 'structure_cookie',
    });


    const handleNavLinkClick = (item: { title: string, isAction: NAV_ACTIONS, path: string }) => {
        const { isAction } = item;

        dispatch(setSideBarState(false));

        if (isAction === NAV_ACTIONS.CHANGE_STRUCTURE) {
            removeCookie('structureData');
        } else if (isAction === NAV_ACTIONS.LOGOUT) {
            removeCookie('userData');
            removeCookie('structureData');

            dispatch(replaceUserAuth({ userAuth: undefined }));
        }
    };

    return <div className={`w-56 ${sideBarState ? 'left-0' : '-left-56'} p-4 transition-all ease-in-out duration-700 fixed h-full select-none bg-white z-50 flex flex-col items-center shadow-2xl`}>

        <div className='my-4 flex justify-center w-full'>
            <IminLogoSvg />
        </div>

        {SidebarDataAdmin.map((item, index) => {
            return <div className='flex flex-col gap-3 justify-between h-full w-full overflow-auto hidden-scrollbar' key={index}>
                <div className='flex flex-col gap-1'>
                    {item.subNav
                        ? item.subNav
                            .sort((a, b) => {
                                const isPermittedA = userStructure?.userStructure?.permissions.some(el => el.plugin.identifier === a.key) || !a.key;
                                const isPermittedB = userStructure?.userStructure?.permissions.some(el => el.plugin.identifier === b.key) || !b.key;

                                // Metto gli elementi non consentiti in fondo alla lista
                                if (isPermittedA && !isPermittedB) return -1;
                                if (!isPermittedA && isPermittedB) return 1;
                                return 0;
                            })
                            .map((subItem, index) => {
                                let isPermitted = userStructure?.userStructure?.permissions.some(el => el.plugin.identifier === subItem.key);

                                if (subItem.key === PluginIdentifier.ONLINE_BOOKINGS) {
                                    if (
                                        !!userStructure.userStructure.structureId.plugins.some(plugin => plugin.identifier === PluginIdentifier.ONLINE_BOOKINGS) &&
                                        !!userStructure?.userStructure.permissions?.some(permission => permission.plugin.identifier === PluginIdentifier.BOOKINGS)
                                    ) {
                                        isPermitted = true
                                    } else {
                                        isPermitted = false
                                    }
                                }

                                if (isPermitted || !subItem.key) {
                                    return (
                                        <NavLink
                                            key={index}
                                            onClick={() => {
                                                dispatch(setSideBarState(false));
                                            }}
                                            className={({ isActive }) => isActive ? activeLink : normalLink}
                                            to={
                                                subItem.title === 'Prenotazioni'
                                                    ? subItem?.path + '?bookingDate=' + globalDate + '&pageSize=15&currentPage=1&sort=%7B%22createdAt%22%3A%22DESC%22%7D'
                                                    : subItem?.path
                                            }
                                        >
                                            <p className={`ml-4 text-xl`}>
                                                {subItem.title}
                                            </p>
                                        </NavLink>
                                    );
                                }

                                return (
                                    <div key={index} title="Contatta l'assistenza per maggiori informazioni." className="p-2 w-full flex justify-between items-center no-underline rounded-md text-text font-medium">
                                        <p className={`ml-4 text-xl text-mediumGray`}>
                                            {subItem.title}
                                        </p>
                                        <LockIcon />
                                    </div>
                                );
                            })
                        : null
                    }

                </div>
                <div className='flex flex-col gap-2'>
                    {(item.footerNav)
                        ? item.footerNav
                            .filter(subItem => {
                                if (subItem.key) {
                                    const isPermitted = userStructure?.userStructure?.permissions.some(el => el.plugin.identifier === subItem.key);
                                    return isPermitted ? true : false
                                }

                                return true
                            })
                            .map((item, index) => {
                                return (item.isAction)
                                    ? <NavLink
                                        onClick={() => handleNavLinkClick(item)}
                                        key={index}
                                        className={normalLink}
                                        to={item?.path}>
                                        <p className={`ml-4 font-medium text-md`}>{item.title}</p>
                                    </NavLink>

                                    : <NavLink
                                        key={index}
                                        className={({ isActive }) => isActive ? activeLink : normalLink}
                                        to={item?.path}>
                                        <p className={`ml-4 font-medium text-md`}>{item.title}</p>
                                    </NavLink>
                            })
                        : null}
                </div>
            </div>
        })}
    </div >
}



export default Sidebar;