import { useLoaderData } from "react-router-dom";
import { IPartecipant } from "../../../types/interfaces/partecipant.interface";
import { IList } from "../../../types/interfaces/list.interface";
import { IPr } from "../../../types/interfaces/pr.interface";
import { IStructure } from "../../../types/interfaces/structure.interface";
import { IEvent } from "../../../types/interfaces/event.interface";
import moment from "moment";
import Text from "../../../components/Text";
import { listRegistratorTemplate } from "../../../utils/pdfGenerator/qrCodeToPartecipant";
import { IminError } from "../../../types/interfaces/iminError.interface";
import GenericError from "../../error/GenericError";

const PartecipantDetails = () => {
    const response = useLoaderData() as IPartecipant<IList<string, IEvent, IStructure>, IPr> | IminError;

    if ('errorCode' in response) return <GenericError redirectUrl="/" />

    const partecipant = response as IPartecipant<IList<string, IEvent, IStructure>, IPr>;

    return (
        <div className="bg-white md:rounded-md shadow flex-1 flex flex-col gap-4 justify-between w-full md:w-fit m-auto p-4 md:p-8 md:my-4">

            <div className="flex flex-col gap-4">
                <p className='text-3xl md:text-4xl font-bold text-text text-center'>{partecipant.status ? 'Evento Concluso!' : !partecipant.deleted ? 'Sei in Lista!' : 'Non sei piu in Lista'}</p>

                <Text reverseFontStyle title="Nome" label={partecipant.name} />
                <Text reverseFontStyle title="Cognome" label={partecipant.surname} />
                <Text reverseFontStyle title="Luogo" label={partecipant.list.structure.name} />
                <Text reverseFontStyle title="Data" label={moment(partecipant.list.event.date).format('dddd DD MMMM YYYY')} className="capitalize" />
            </div>

            <div className="flex flex-col gap-2 w-full md:w-fit">
                <p className="text-darkGray font-medium text-sm text-center md:text-left">
                    Il biglietto è stato inviato all'indirizzo <strong>{partecipant.email}</strong>.<br />
                    Puoi anche scaricarlo ora e conservarlo per un uso futuro.<br />
                    Ricorda di mostrare il biglietto all'ingresso dell'evento.<br />
                    Grazie e buona partecipazione!
                </p>

                <button
                    onClick={() => listRegistratorTemplate(partecipant.qrCodeDataURL, partecipant.list.structure, partecipant.list.event, partecipant.list.event.poster)}
                    className="bg-text text-white px-4 py-2 rounded-md mt-4"
                >
                    Scarica il Biglietto PDF
                </button>
            </div>
        </div>
    )
}

export default PartecipantDetails