import React, { useState } from 'react';
import Text from './Text';

interface ITimePickerProps {
    selectedTime: string;
    onTimePick: (value: string) => void;
    title?: string;
}

const TimePicker: React.FC<ITimePickerProps> = ({ selectedTime, onTimePick, title }) => {
    const [hours, setHours] = useState<string>(selectedTime.split(':')[0] || '00');
    const [minutes, setMinutes] = useState<string>(selectedTime.split(':')[1] || '00');

    const handleTimeChange = (newHours: string, newMinutes: string) => {
        const formattedTime = `${newHours.padStart(2, '0')}:${newMinutes.padStart(2, '0')}`;
        onTimePick(formattedTime);
    };

    return (
        <div className='flex flex-col'>

            {!!title && <Text title={title} label=' ' />}

            <div className='flex items-center gap-4'>
                <div className='flex flex-col'>
                    <Text title='Ora' label=' ' />
                    <select
                        value={hours}
                        onChange={(e) => {
                            setHours(e.target.value);
                            handleTimeChange(e.target.value, minutes);
                        }}
                        className='bg-white border rounded p-2 text-text font-medium'
                    >
                        {[...Array(24).keys()].map((h) => (
                            <option key={h} value={h.toString()}>
                                {h.toString().padStart(2, '0')}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='flex flex-col'>
                    <Text title='Minuti' label=' ' />
                    <select
                        value={minutes}
                        onChange={(e) => {
                            setMinutes(e.target.value);
                            handleTimeChange(hours, e.target.value);
                        }}
                        className='bg-white border rounded p-2 text-text font-medium'
                    >
                        {[0, 15, 30, 45].map((m) => (
                            <option key={m} value={m.toString()}>
                                {m.toString().padStart(2, '0')}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default TimePicker;
