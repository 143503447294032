import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import PageTamplate from '../../components/PageTamplate';
import { apiHandler } from '../../services/apiHandler';
import { replaceInfo, replaceManageOfferSession, replaceOfferManage } from '../../store/offer/offerSlice';
import { useAppDispatch, useAppSelector } from '../../store/store'
import { IOffer } from '../../types/interfaces/offer.interface';
import InformationStep from './templates/InformationStep';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import { validateLongString, validateNumber, validateShortString, validateTimetable } from './templates/stepValidations';
import { HttpMethod, apiService } from '../../services/apiService';
import PageTitle from '../../components/PageTitle';

const OfferManage: React.FC = () => {
    // utils
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { showModal, showErrorModal } = useModalDispatcher()

    // redux init
    const dispatch = useAppDispatch();
    const offerManage = useAppSelector(state => state.offer.manageOffer);
    const { info, isCreate, offerSession } = useAppSelector(state => state.offer.manageOffer);

    // local state 
    const [isLoading, setIsLoading] = useState(false);
    const [infoErrors, setInfoErrors] = useState({
        name: false,
        description: false,
        capacity: false,
        timetable: {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false
        }
    });

    useEffect(() => {
        if (searchParams.get('offerSession') && searchParams.get('offerSession') !== offerSession) {
            dispatch(replaceManageOfferSession(searchParams.get('offerSession') as string));
            initializeManageOffer();
        }
    }, [searchParams])

    const initializeManageOffer = async () => {
        // set temp offer state to default state 
        let offerManageState = offerManage;

        if (searchParams.get('isCreate') === 'true') {
            // create new offer set redux 
            offerManageState = {
                ...offerManageState,
                isCreate: true,
            }
        }

        if (searchParams.get('isCreate') === 'false') {
            // fetch offer by id and set redux to current offer
            const offerId = searchParams.get('id');

            if (offerId && offerId !== info._id) {
                // fetch offer by id
                const response: any = await apiHandler.get(`/offer/get/${offerId}`);
                const offer = response;

                // parse retrieved offer to normalized offer state
                const tempData = normalizeState(offer);
                offerManageState = {
                    ...offerManageState,
                    info: tempData.info,
                }
            }
        }

        dispatch(replaceOfferManage(offerManageState));
    };

    const normalizeState = (offer: any) => {
        const info: any = {
            _id: offer._id,
            name: offer.name,
            description: offer.description,
            capacity: offer.capacity,
            shifts: offer.shifts,
            positions: offer.positions,
            rooms: offer.rooms,
            timetable: offer.timetable,
            isForEach: offer.options?.isForEach,
            isMultiple: offer.options?.isMultiple,
            isEntertainment: offer.options?.isEntertainment,
        }

        return {
            info: info,
        }
    };

    const handleUpdateOffer = (e: any, fieldKey: string) => {
        const temp = { ...info, [fieldKey]: e };
        dispatch(replaceInfo(temp));
    };

    const validateParams = () => {
        const informationFormErrors = {
            name: !validateShortString(info.name),
            description: !validateLongString(info.description),
            capacity: !validateNumber(info.capacity, false),
            timetable: {
                0: !validateTimetable(info.timetable[0]),
                1: !validateTimetable(info.timetable[1]),
                2: !validateTimetable(info.timetable[2]),
                3: !validateTimetable(info.timetable[3]),
                4: !validateTimetable(info.timetable[4]),
                5: !validateTimetable(info.timetable[5]),
                6: !validateTimetable(info.timetable[6]),
            },
        };

        setInfoErrors(informationFormErrors as any);

        const hasErrors = Object.values(informationFormErrors).some((error: any) => error === true) || Object.values(info.timetable).some((error: any) => error === true);

        if (hasErrors) {
            return false;
        }

        return true;
    };

    // transform offerManageState object to offer object
    const reconstructOffer = () => {
        // add the _id field to the offer object only if isCreate is false
        const offer: IOffer = {
            _id: info._id,
            name: info.name,
            description: info.description,
            capacity: info.capacity,
            shifts: info.shifts,
            positions: info.positions,
            rooms: info.rooms,
            timetable: info.timetable,
            options: {
                isForEach: info.isForEach,
                isMultiple: info.isMultiple,
                isEntertainment: info.isEntertainment
            },
            isEnabled: info.isEnabled,
        }

        if (isCreate) {
            // remove _id field from offer object
            if (offer._id)
                delete offer._id;
        }

        return offer;
    };

    const handleCreateApiOffer = async () => {
        setIsLoading(true);
        const offer = reconstructOffer();
        try {
            if (!validateParams()) {
                setIsLoading(false);
                return;
            }

            const response: any = await apiService<IOffer & { errorCode: string }>(HttpMethod.POST, '/offer/create', offer);

            if (!!response.errorCode) {
                throw response;
            }

            if (response.data)
                showModal(200);

            navigate('/dashboard/servizi');
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleUpdateApiOffer = async () => {
        setIsLoading(true);
        const offer = reconstructOffer();
        try {

            if (!validateParams()) {
                setIsLoading(false);
                return;
            }

            const response: any = await apiService<IOffer & { errorCode: string }>(HttpMethod.PUT, `/offer/update/${offer._id}`, offer);

            if (!!response.errorCode) {
                throw response;
            }

            if (response.data)
                showModal(200);

            navigate('/dashboard/servizi');
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleConfirm = async () => {
        if (isCreate) {
            await handleCreateApiOffer();
        }

        if (!isCreate) {
            await handleUpdateApiOffer();
        }
    };

    return (
        <PageTamplate>
            <div className="w-full flex flex-col gap-8 bg-white p-6 lg:p-10 rounded-lg">

                {/* Header */}
                <div className="w-full flex gap-y-8 lg:gap-y-0 justify-between items-center">
                    <PageTitle title={isCreate ? "Nuovo Servizio" : info?.name} />
                </div>

                {/* Body */}
                <InformationStep handleUpdateOffer={handleUpdateOffer} infoErrors={infoErrors} />

                {/* Error message */}
                {Object.values(infoErrors).some((error: any) => error === true) && <p className='text-error text-xs md:text-sm font-medium text-center'>Ci sono errori nell'inserimento dei dati, verifica e riprova.</p>}

                {/* Footer */}
                <div className='flex flex-col sm:flex-row justify-between items-center w-full gap-2'>
                    <CustomButton label="Annulla" fullWidth={true} containerClassName='sm:w-fit' disabled={isLoading} onClickHandler={() => { navigate('/dashboard/servizi') }} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' />

                    <CustomButton label='Salva ed esci' fullWidth={true} containerClassName='sm:w-fit' disabled={isLoading} onClickHandler={handleConfirm} color='bg-primary' />
                </div>
            </div>
        </PageTamplate>
    )
}

export default OfferManage
