import React from 'react';
import { Form, Navigate, useNavigate, useNavigation } from 'react-router-dom';
import InputForm from '../../components/InputForm';
import CustomButton from '../../components/CustomButton';
import useCookie from '../../hooks/useCookie';

const PrLogin: React.FC = () => {
    const navigation = useNavigation();
    const navigate = useNavigate();

    const { cookieData } = useCookie({ pr_session: 'pr_session' });

    if (cookieData.pr_session) {
        return <Navigate to='/promoter' />
    };

    return (
        <div className="flex min-h-screen items-center justify-center p-4 bg-gradient-to-br from-primary to-background">

            <div className="bg-white p-4 md:p-8 rounded-md shadow-lg w-full max-w-md">
                <div className="text-center mb-6">
                    <p className="text-3xl font-bold text-gray-800">Benvenuto Promoter!</p>
                    <p className="text-gray-600 mt-2">Accedi per gestire le tue liste ed i tuoi inviti</p>
                </div>

                <Form method='POST' className="space-y-6">

                    <InputForm
                        label='Email'
                        type='email'
                        name='email'
                        backgroundColor='bg-background'
                        placeholder='Inserisci la tua email'
                    />
                    <InputForm
                        label='Password'
                        type='password'
                        name='password'
                        placeholder='Inserisci la tua password'
                    />

                    <div className='flex flex-col md:flex-row-reverse gap-2 md:justify-between'>
                        <CustomButton
                            disabled={navigation.state !== 'idle'}
                            type='submit'
                            color='bg-primary'
                            label='Accedi'
                            className='border-2 border-primary'
                        />
                        <CustomButton
                            disabled={navigation.state !== 'idle'}
                            type='button'
                            color='bg-white'
                            label='Indietro'
                            textColor='text-text'
                            className='border-2 border-text'
                            onClickHandler={() => navigate(-1)}
                        />
                    </div>
                </Form>

                <div className="mt-6 text-center">
                    {/* <a href="#" className="text-sm text-primary hover:text-primary font-medium">
                        Password dimenticata?
                    </a> */}
                </div>

            </div>
        </div>
    );
};

export default PrLogin;
