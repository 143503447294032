import { useLoaderData, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import PageTitle from "../../components/PageTitle";
import { LogoutIcon } from "../../utils/icons/LogoutIcon";
import useCookie from "../../hooks/useCookie";
import { IList } from "../../types/interfaces/list.interface";
import { IPartecipant } from "../../types/interfaces/partecipant.interface";
import { IEvent } from "../../types/interfaces/event.interface";
import { IStructure } from "../../types/interfaces/structure.interface";
import moment from "moment";
import { useRef } from "react";
import { useHorizontalScroll } from "../../hooks/useHorizontalScroll";
import CopyToClipboard from "../../components/CopyToClipboard";
import { IminError } from "../../types/interfaces/iminError.interface";
import GenericError from "../error/GenericError";

export type IListWithToken = IList<IPartecipant, IEvent, IStructure> & { token: string };

const PrHome = () => {
    const response = useLoaderData() as IListWithToken[] | IminError;
    const navigate = useNavigate();
    const { removeCookie } = useCookie({ pr_session: 'pr_session' });
    const containerRef = useRef<HTMLDivElement>(null);
    useHorizontalScroll(containerRef);

    if ('errorCode' in response) return <GenericError redirectUrl="/" />

    const lists = response as IListWithToken[];

    const handleLogout = () => {
        removeCookie('pr_session');

        navigate('/auth/pr/login', { replace: true });
    };

    const handleNavigatetoList = (id?: string) => {
        if (id) {
            navigate(`list/${id}`);
        }
    };

    const groupedLists = lists?.reduce((acc, list) => {
        const structureName = list.structure.name;
        if (!acc[structureName]) {
            acc[structureName] = [];
        }
        acc[structureName].push(list);
        return acc;
    }, {} as Record<string, IListWithToken[]>);

    return (
        <div className="flex flex-col gap-4 py-8">
            <div className="px-8 pb-0">
                <PageTitle title="Le tue Liste" goBackBtn={false} rightBtn={<CustomButton onClickHandler={handleLogout} label='Esci' color='bg-text' leftIcon={<LogoutIcon />} />} />
            </div>

            {Object.entries(groupedLists).map(([structureName, lists]) => (
                <div key={structureName} className="flex flex-col gap-2 text-text">
                    <h2 className="text-2xl font-semibold px-8">{structureName}</h2>
                    <div ref={containerRef} className="flex w-full overflow-x-auto px-8 py-1 gap-2">
                        {lists.map(list => (
                            <div key={list._id} className="rounded shadow flex-shrink-0 w-72 select-none">
                                <div className="bg-text flex justify-center gap-4 rounded-t px-4 py-2">
                                    <p className="text-text_white font-medium">Condividi lista!</p>
                                    <CopyToClipboard link={"https://imin-dashboard.com/register/list/" + list.token} color="bg-lightGray" />
                                </div>
                                <div className="flex gap-2 bg-white cursor-pointer" onClick={() => handleNavigatetoList(list._id)}>
                                    <img
                                        src={list.event.poster || `https://via.placeholder.com/1080x1920?text=Locandina+Evento+${list.event.name}`}
                                        alt={list.event.name}
                                        className="w-1/2 h-auto"
                                    />
                                    <div className="flex flex-col gap-2 justify-between p-2 text-text">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-lg line-clamp-3 font-bold capitalize">{list.event.name}</p>
                                            <p className="text-sm line-clamp-6 font-medium">{list.event.info}</p>
                                        </div>
                                        <div className="flex justify-between items-center text-xl font-medium">
                                            <p>{list.people.length}</p>
                                            <p>Invitat{list.people.length !== 1 ? 'i' : 'o'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-primary flex justify-center gap-4 rounded-b px-4 py-1">
                                    <p className="capitalize font-medium text-text_white text-lg">{moment(list.event.date).format('dddd DD MMMM')}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

        </div>
    )
}

export default PrHome