import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { IStructure } from '../../types/interfaces/structure.interface';
import { IEvent } from '../../types/interfaces/event.interface';
import moment from 'moment';

export const listRegistratorTemplate = async (qrCodeDataURL: string, structure: IStructure, event: IEvent, imageUrl: string = 'https://picsum.photos/1080/1920') => {
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Add a new page to the document with specific dimensions (custom size similar to your design)
    const page = pdfDoc.addPage([540, 960]);

    // Font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    // Immagine sfondo
    const imageResponse = await fetch(imageUrl);

    if (!imageResponse.ok) {
        throw new Error(`Errore durante il download dell'immagine: ${imageResponse.statusText}`);
    }

    const imageBytes = await imageResponse.arrayBuffer();
    const embeddedImage = await pdfDoc.embedJpg(imageBytes);

    // Dimensioni della pagina
    const { width, height } = page.getSize();

    // Disegna l'immagine a tutta pagina
    page.drawImage(embeddedImage, {
        x: 0,
        y: 0,
        width: width,
        height: height,
    });

    // Blocco bianco sulla metà inferiore della pagina
    page.drawRectangle({
        x: 0,
        y: 0,
        width: width,
        height: height / 2,
        color: rgb(1, 1, 1), // Colore bianco
    });

    // Decode the QR image from the Data URL
    const qrImage = await pdfDoc.embedPng(qrCodeDataURL);

    // Dimensioni dell'immagine e la posizione centrata
    const qrImageDims = qrImage.scale(1);
    const qrCodeWidth = qrImageDims.width;
    const qrCodeHeight = qrImageDims.height;
    const qrCodeX = (page.getWidth() - qrCodeWidth) / 2;
    const qrCodeY = (page.getHeight() - qrCodeHeight) / 2;

    // Disegna un'ombra dietro l'immagine QR code
    page.drawRectangle({
        x: qrCodeX - 4,
        y: qrCodeY - 4,
        width: qrCodeWidth + 8,
        height: qrCodeHeight + 8,
        color: rgb(0, 0, 0),
        opacity: 0.3,
    });

    // Disegna l'immagine QR code sopra l'ombra
    page.drawImage(qrImage, {
        x: qrCodeX,
        y: qrCodeY,
        width: qrCodeWidth,
        height: qrCodeHeight,
    });

    // Event details text

    const eventNameSize = 42;
    const eventNameWidth = helveticaBoldFont.widthOfTextAtSize(event.name, eventNameSize);
    page.drawText(event.name, {
        x: (page.getWidth() - eventNameWidth) / 2,
        y: qrCodeY - 60,
        size: eventNameSize,
        font: helveticaBoldFont,
        color: rgb(0, 0, 0),
    });

    const dateStringSize = 28;
    const dateStringWidth = helveticaFont.widthOfTextAtSize(moment(event.date).format('dddd DD MMMM').replace(/\b\w/g, char => char.toUpperCase()), dateStringSize);
    page.drawText(moment(event.date).format('dddd DD MMMM').replace(/\b\w/g, char => char.toUpperCase()), {
        x: (page.getWidth() - dateStringWidth) / 2,
        y: qrCodeY - 100,
        size: dateStringSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });

    const text = `
 1. Mostra questo pass all'ingresso.
 2. Scansiona il QR code per registrare il tuo arrivo.
 3. Attendi la conferma del nostro staff e preparati a entrare!
 
 Ci vediamo dentro!`;

    // Divide il testo in righe
    const lines = text.split('\n');
    const fontSize = 16;
    const lineHeight = fontSize * 1.5;

    // Disegna ogni riga di testo una sotto l'altra
    lines.forEach((line, index) => {
        const stringWidth = helveticaFont.widthOfTextAtSize(line, fontSize);
        page.drawText(line, {
            x: (page.getWidth() - stringWidth) / 2,
            y: qrCodeY - 140 - index * lineHeight,
            size: fontSize,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });
    });

    page.drawText(structure.name, {
        x: 30,
        y: 40,
        size: 20,
        font: helveticaBoldFont,
        color: rgb(0, 0, 0),
    });

    page.drawText(structure.address.city + ', ' + structure.address.street, {
        x: 30,
        y: 20,
        size: 16,
        font: helveticaFont,
        color: rgb(0, 0, 0),
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ticket.pdf';
    link.click();
    window.URL.revokeObjectURL(url);
};
