import React from 'react';
import { useNavigation } from 'react-router-dom';

interface CustomButtonProps {
    color: 'bg-primary' | 'bg-secondary' | 'bg-background' | 'bg-background2' | 'bg-text' | 'bg-success' | 'bg-info' | 'bg-warning' | 'bg-error' | 'bg-darkGray' | 'bg-mediumGray' | 'bg-lightGray' | 'bg-transparent' | 'bg-white';
    textColor?: 'text-primary' | 'text-secondary' | 'text-background' | 'text-background2' | 'text-text' | 'text-success' | 'text-info' | 'text-warning' | 'text-error' | 'text-darkGray' | 'text-mediumGray' | 'text-lightGray' | 'text-transparent' | 'text-text_white' | 'text-text';
    label?: string | React.ReactNode;
    title?: string;
    padding?: string;
    fullWidth?: boolean;
    fullHeight?: boolean;
    disabled?: boolean;
    onClickHandler?: (e: React.MouseEvent<any>) => void;
    className?: string;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    textStyle?: string;
    popoverTitle?: string;
    type?: "button" | "submit" | "reset" | undefined;
    containerClassName?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ color, textColor = 'text-text_white', label, title, padding = 'py-2 px-6', fullWidth, fullHeight, disabled, onClickHandler, className, leftIcon, rightIcon, textStyle = 'text-base', popoverTitle, type, containerClassName }) => {
    const navigation = useNavigation();
    return (
        <div className={`flex flex-col gap-2 select-none ${fullWidth ? 'w-full' : ''} ${fullHeight ? 'h-full' : ''} ${containerClassName}`}>
            {title && <label className="font-medium text-text">
                {title}
            </label>}
            <button
                type={type}
                title={popoverTitle}
                onClick={onClickHandler}
                className={`${className} rounded-md shadow flex justify-center items-center gap-2 cursor-pointer ${fullHeight ? 'h-full' : ''} ${disabled ? 'bg-mediumGray pointer-events-none cursor-default border-0' : `${color}`} ${padding} hover:opacity-80`}
                disabled={disabled}
            >
                {navigation.state === 'submitting' && type === 'submit'
                    ? null
                    : leftIcon
                }

                {label && <p className={`text-center ${textColor} ${textStyle} font-semibold ${!title ? 'cursor-pointer' : 'cursor-default'}`}>{label}</p>}

                {navigation.state === 'submitting' && type === 'submit'
                    ? <svg className={`animate-spin h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path className="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.86 3.185 8.009l2-2.718z" />
                    </svg>
                    : rightIcon
                }
            </button>
        </div>
    );
};

export default CustomButton;
