import Drawer from '../../../components/Drawer';
import { FoodAllergen, translateAllergenToItalian } from '../../../types/enum/foodAllergen.enum';
import { getAllergenIcon } from '../../../utils/foodAllergenicsUtils';
import Text from '../../../components/Text';
import { useState } from 'react';

const AllergensInfo = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);


    const closeAllergenInfo = () => {
        setIsDrawerOpen(false);
    };

    const opneAllergenInfo = () => {
        if (!isDrawerOpen) {
            setIsDrawerOpen(true);
        }
    };

    return (
        <div onClick={opneAllergenInfo}>
            <p className="text-sm font-medium text-text text-left underline">Info Allergeni</p>

            <Drawer
                isOpen={isDrawerOpen}
                onClose={closeAllergenInfo}
                maxHeight="100%"
                initialHeight="80%"
                backgroundColor="white"
                opacityIntesity={3}
            >

                <div className="flex flex-col gap-2 p-4 md:p-8">
                    <Text title="Allergeni" label="Consulta la lista degli allergeni presenti nei nostri piatti per garantire una scelta consapevole." />

                    <div className="grid grid-cols-3">
                        {Object.values(FoodAllergen).map((allergen, index) => (
                            <div key={index} className='flex flex-col items-center gap-1 p-2'>
                                {getAllergenIcon(allergen)}
                                <p className="text-sm text-text font-semibold text-center">{translateAllergenToItalian(allergen)}</p>
                            </div>
                        ))}
                    </div>
                </div>

            </Drawer>
        </div>
    )
}

export default AllergensInfo