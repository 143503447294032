import { FoodAllergen } from "../types/enum/foodAllergen.enum";
import { PeanutsIcon } from './icons/allergens/PeanutsIcon';
import { TreeNutsIcon } from "./icons/allergens/TreeNutsIcon";
import { MilkIcon } from './icons/allergens/MilkIcon';
import { MollusksIcon } from './icons/allergens/MollusksIcon';
import { EggsIcon } from './icons/allergens/EggsIcon';
import { FishIcon } from './icons/allergens/FishIcon';
import { ShellfishIcon } from './icons/allergens/ShellfishIcon';
import { GlutenIcon } from './icons/allergens/GlutenIcon';
import { SoyIcon } from './icons/allergens/SoyIcon';
import { SesameIcon } from './icons/allergens/SesameIcon';
import { MustardIcon } from './icons/allergens/MustardIcon';
import { CeleryIcon } from './icons/allergens/CeleryIcon';
import { LupinIcon } from './icons/allergens/LupinIcon';
import { SulphitesIcon } from './icons/allergens/SulphitesIcon';

export const getAllergenIcon = (allergen: FoodAllergen, color?: string | undefined, size?: string | undefined): JSX.Element | null => {
    switch (allergen) {
        case FoodAllergen.PEANUTS:
            return <PeanutsIcon color={color} size={size} />;
        case FoodAllergen.TREE_NUTS:
            return <TreeNutsIcon color={color} size={size} />;
        case FoodAllergen.MILK:
            return <MilkIcon color={color} size={size} />;
        case FoodAllergen.EGGS:
            return <EggsIcon color={color} size={size} />;
        case FoodAllergen.FISH:
            return <FishIcon color={color} size={size} />;
        case FoodAllergen.SHELLFISH:
            return <ShellfishIcon color={color} size={size} />;
        case FoodAllergen.MOLLUSKS:
            return <MollusksIcon color={color} size={size} />;
        case FoodAllergen.GLUTEN:
            return <GlutenIcon color={color} size={size} />;
        case FoodAllergen.SOY:
            return <SoyIcon color={color} size={size} />;
        case FoodAllergen.SESAME:
            return <SesameIcon color={color} size={size} />;
        case FoodAllergen.MUSTARD:
            return <MustardIcon color={color} size={size} />;
        case FoodAllergen.CELERY:
            return <CeleryIcon color={color} size={size} />;
        case FoodAllergen.LUPIN:
            return <LupinIcon color={color} size={size} />;
        case FoodAllergen.SULPHITES:
            return <SulphitesIcon color={color} size={size} />;
        default:
            return null;
    }
};
