import { Outlet } from 'react-router-dom';
import { IminDashboard } from '../../utils/icons/IminDashboard';
import { SquaringCircles } from '../../utils/icons/SquaringCircles';
import { useEffect } from 'react';
import { privacyPolicy } from '../../views/public/iubenda';

const PublicLayout = () => {
    return <div className='flex flex-col justify-between flex-1 h-full w-full overflow-auto hidden-scrollbar'>

        {/* BODY */}
        <Outlet />

        {/* footer */}
        <footer className='flex gap-4 md:gap-10 bg-primary px-6 md:px-16 py-4 md:py-10 justify-between items-center z-50 w-full'>

            <div className='flex flex-col items-center justify-around gap-4 h-full'>

                <IminDashboard color='#FFF' />

                <div className='flex flex-col items-center m- xl:m-0'>
                    <p className='font-medium text-xs text-text_white'>Powered by</p>
                    <a href='https://thesquaringcircles.com/' target='_blank'><SquaringCircles /></a>
                </div>

            </div>


            <div className='flex flex-col items-center justify-between h-full font-medium md:font-semibold text-xs md:text-lg text-text_white'>

                <a href="https://www.iubenda.com/privacy-policy/54121952" className="w-fit px-4 py-1 md:py-2 text-center" title="Privacy Policy ">Privacy Policy</a>
                <PrivacyPolicy />

                <a href="https://www.iubenda.com/privacy-policy/54121952/cookie-policy" className="w-fit px-4 py-1 md:py-2 text-center" title="Cookie Policy ">Cookie Policy</a>
                <CookiePolicy />

                <a href="https://www.iubenda.com/termini-e-condizioni/54121952" className="w-fit px-4 py-1 md:py-2 text-center" title="Termini e Condizioni ">Termini e Condizioni</a>
                <TermsAndConditions />
            </div>
        </footer>
    </div>
}

const PrivacyPolicy: React.FC = () => {

    useEffect(() => {
        privacyPolicy()
    }, [])

    return <></>
}

const CookiePolicy: React.FC = () => {

    useEffect(() => {
        privacyPolicy()
    }, [])



    return <></>
}

const TermsAndConditions: React.FC = () => {

    useEffect(() => {
        privacyPolicy()
    }, [])

    return <></>
}

export default PublicLayout