import { Navigate, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import useCookie from "../../hooks/useCookie";
import { useEffect } from "react";
import { apiHandler, postHandler } from "../../services/apiHandler";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";
import { setSideBarState } from "../../store/UI/uiSlice";
import { CalendarIcon } from "../../utils/icons/CalendarIcon";
import useComponentVisible from "../../hooks/useComponentVisible";
import Modal from "../../components/Modal";
import Calendar from "../../components/Calendar";
import { changeSelectedDate } from "../../store/booking/bookingSlice";
import useScreenSize from "../../hooks/useScreenSize";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
import { IEvent } from "../../types/interfaces/event.interface";
import { Structureicon } from "../../utils/icons/StructureIcon";
import { apiService, HttpMethod } from "../../services/apiService";
import DropdownMenu, { Option } from "../../components/DropdownMenu";
import { PluginIdentifier } from "../../types/enum/pluginIdentifier";
import { IUserStructure } from "../../types/interfaces/userStructure.interface";
import { IPlugin } from "../../types/interfaces/plugin.interface";
import { IStructure } from "../../types/interfaces/structure.interface";
import TopBar from "../TopBar";


export const ProtectedLayout = () => {
    const dispatch = useAppDispatch();
    const { sideBarState } = useAppSelector(state => state.ui);
    const { cookieData, removeCookie, setCookie } = useCookie({ userData: 'user_cookie', structureData: 'structure_cookie' });
    const { showErrorModal } = useModalDispatcher();

    useEffect(() => {
        const interval = setInterval(() => {
            checkUserToken();
        }, 900000); // Check every 15 minutes 

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkStructureToken();
        }, 900000); // Check every 15 minutes

        return () => {
            clearInterval(interval);
        };
    }, []);

    const checkUserToken = async () => {
        try {
            const response = await apiHandler.post<postHandler | any>('/user/check-token', { isUserCookie: true });

            if (response.newJwt) {
                setCookie('userData', response.newJwt, response.exp);
            }

            if (!response.isTokenValid) {
                removeCookie('userData');
            }

        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
            removeCookie('userData');
        }
    };

    const checkStructureToken = async () => {
        try {
            const response: any = await apiHandler.post('/user/check-structure-token');
            if (response.newJwt) {
                setCookie('structureData', response.newJwt, response.exp);
            }

            if (!response.isTokenValid) {
                removeCookie('structureData');
            }

        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
            removeCookie('structureData');
        }
    };

    const handleOverlayClick = () => {
        if (sideBarState) {
            dispatch(setSideBarState(false));
        }
    };

    if (!cookieData.userData) {
        return <Navigate to="/auth" />;
    };

    if (!cookieData.structureData) {
        return <Navigate to="/structure" />;
    };

    return (
        <>
            <div className="flex h-full w-full justify-end">
                <Sidebar />

                {sideBarState && (
                    <div
                        style={{ width: 'calc(100% - 224px)' }}
                        className="z-50 h-full absolute"
                        onClick={handleOverlayClick}
                    />
                )}

                <div className='flex flex-col h-full w-full text-text'>
                    <TopBar />
                    <Navbar navContent={<ProtectedLayoutNavbar />} />

                    <div className="h-full w-full overflow-y-auto overflow-x-hidden">
                        <Outlet />
                    </div>

                </div>
            </div>
        </>
    )
};

const ProtectedLayoutNavbar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const { globalDate } = useAppSelector(state => state.booking);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const screenSize = useScreenSize();

    const { data: dailyEvent } = useFetch<IEvent[]>(
        () => apiService<IEvent[] & { errorCode: string }>(HttpMethod.GET, `/event/list`, undefined, { date: globalDate }),
        undefined,
        [globalDate],
        !!globalDate
    );

    const eventOptions = (): Option[] => {
        const options: Option[] = dailyEvent?.map(event => (
            {
                label: <p>{event.name}</p>,
                onClick: () => navigate('/dashboard/events/info/' + globalDate, { replace: true }),
            }
        )) ?? [];

        return options;
    }

    return (
        <>
            {/* {!location.pathname.includes('comande') && */}
            <div className="flex flex-col w-full items-center md:items-end md:mr-4 ">
                <p className="font-semibold text-text text-lg capitalize">{moment(searchParams.get('bookingDate') ?? globalDate).utc(true).format('dddd DD MMMM YYYY')}</p>
                {!!dailyEvent?.length
                    ? dailyEvent?.length === 1
                        ? dailyEvent.map((event, index) => <p key={index} className="text-text font-medium text-sm text-center">{event.name}</p>)
                        : <DropdownMenu placement="bottom" toggleButton={<p className="text-text font-medium text-sm hover:text-primary hover:scale-105 hover:font-semibold focus:text-primary focus:scale-105 focus:font-semibold">{dailyEvent?.length} Eventi Oggi</p>} options={eventOptions()} />
                    : null
                }
            </div>

            <div ref={ref} className="gap-4 relative flex items-center">
                <button type="button" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                    <CalendarIcon color="#131313" />
                </button>

                {isComponentVisible &&
                    (screenSize !== 'xs' && screenSize !== 'sm')
                    ? <div className="absolute z-50 top-10 right-0 translate rounded-lg shadow-lg w-72 flex justify-center items-center bg-white overflow-auto p-4">
                        <Calendar fullHeight fullWidth selectedDate={searchParams.get('bookingDate') ?? globalDate} setSelectedDate={(date) => {
                            setIsComponentVisible(false);
                            dispatch(changeSelectedDate(date));
                            if (searchParams.has('bookingDate')) {
                                searchParams.set('bookingDate', date);
                                setSearchParams(searchParams);
                            }
                        }} />
                    </div>
                    : <Modal isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)}>
                        <Calendar fullHeight fullWidth selectedDate={searchParams.get('bookingDate') ?? globalDate} setSelectedDate={(date) => {
                            setIsComponentVisible(false);
                            dispatch(changeSelectedDate(date));
                            if (searchParams.has('bookingDate')) {
                                searchParams.set('bookingDate', date);
                                setSearchParams(searchParams);
                            }
                        }} />
                    </Modal>
                }
            </div>
        </>
    )
}  
