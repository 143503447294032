import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/CustomButton'

const PageNotFound = () => {
    const navigate = useNavigate()

    const handleRedirect = () => {
        navigate('/auth')
    }

    return (
        <div className="flex flex-col items-center justify-center h-full w-full bg-primaryLight p-4">
            <h1 className='font-bold text-primary text-6xl mb-4'>404</h1>
            <h2 className='font-medium text-2xl mb-2'>Pagina non trovata</h2>
            <p className='text-center text-lg mb-4'>La pagina che stai cercando non esiste o non hai i permessi per accedervi.</p>
            <CustomButton label="Torna alla Home" onClickHandler={handleRedirect} color='bg-primary' className='mt-4' />
        </div>
    )
}

export default PageNotFound
