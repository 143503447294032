import React, { ReactNode } from 'react';

type ModalProps = {
    isOpen: boolean;
    title?: string;
    onClose?: (...args: any) => void;
    children: ReactNode;
    ref?: React.LegacyRef<HTMLDivElement> | undefined;
    className?: string;
    classNameChildren?: string;
    fullScreen?: boolean;
    bgColor?: string;
};

const Modal: React.FC<ModalProps> = ({ isOpen, title, onClose, children, ref, className, classNameChildren = "p-4 h-full", fullScreen = false, bgColor = "bg-white" }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div ref={ref} className={`fixed inset-0 flex items-center justify-center z-50 h-full ${fullScreen ? "" : "p-4 container m-auto"}`}>

            {/* Background overlay */}
            <div onClick={onClose} className="fixed inset-0 bg-text opacity-50"></div>

            {/* Modal content */}
            <div className={`${bgColor} z-10 max-w-full max-h-full rounded-md shadow-lg overflow-auto ${fullScreen && "h-full w-full"} ${className}`}>

                {title && <div className="flex justify-between items-center p-4 pb-0">
                    <p className="font-bold text-text text-xl uppercase select-none">{title}</p>
                </div>}

                <div className={classNameChildren}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;