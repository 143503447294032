import React, { useState, useEffect } from 'react';
import moment from 'moment';

interface ICountdownProps {
    targetDate: Date;
}

const ListCountDown: React.FC<ICountdownProps> = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState<string>('');

    useEffect(() => {
        const updateCountdown = () => {
            const now = moment().utc(true);
            const end = moment(targetDate).utc(true);
            const duration = moment.duration(end.diff(now));

            if (duration.asMilliseconds() <= 0) {
                setTimeLeft('Tempo scaduto!');
                return;
            }

            const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
            const minutes = String(duration.minutes()).padStart(2, '0');
            const seconds = String(duration.seconds()).padStart(2, '0');

            setTimeLeft(`${hours}:${minutes}:${seconds}`);
        };

        const intervalId = setInterval(updateCountdown, 1000);
        updateCountdown();

        return () => clearInterval(intervalId);
    }, [targetDate]);

    return (
        <p className='font-medium text-warning'>
            {timeLeft}
        </p>
    );
};

export default ListCountDown;
