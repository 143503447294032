import { useState } from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { useLoaderData } from 'react-router-dom';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import { AutoBookingType } from '../../../../types/enum/autoBookingType.enum';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import Step1Create from './Step1Create';
import Step2Create from './Step2Create';
import Step3Create from './Step3Create';
import Step4Create from './Step4Create';
import Step5Create from './Step5Create';
import PageTitle from '../../../../components/PageTitle';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';

const BookingRequestCreateWizard = () => {
    const { structure, offers } = useLoaderData() as { structure: IStructure, offers: IOffer<IAutoBookingOption>[] };

    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState<IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>({
        structure: structure,
        autoBookingType: AutoBookingType.AUTOMATIC
    } as IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>);

    const isStructureAbleToAutoBook = () => {
        const offerAbleToAutoBook = offers.some(offer => {
            return !!offer.autoBookingOptions && offer.autoBookingOptions.disabled === false
        });

        return offerAbleToAutoBook;
    };

    isStructureAbleToAutoBook();

    const nextStep = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1Create data={formData} setData={setFormData} offers={offers} nextStep={nextStep} />;
            case 2:
                return <Step2Create data={formData} setData={setFormData} nextStep={nextStep} prevStep={prevStep} />;
            case 3:
                return <Step3Create data={formData} setData={setFormData} nextStep={nextStep} prevStep={prevStep} />;
            case 4:
                return <Step4Create data={formData} setData={setFormData} nextStep={nextStep} prevStep={prevStep} />;
            case 5:
                return <Step5Create data={formData} setData={setFormData} prevStep={prevStep} />;
            default:
                return <Step1Create data={formData} setData={setFormData} offers={offers} nextStep={nextStep} />;
        }
    };

    return (
        <div className='flex flex-col justify-between'>

            <div className='w-full bg-white shadow px-8 py-4 text-center'>
                <p className='line-clamp-1 font-bold text-4xl text-primary'>{structure?.name}</p>
                <p className='line-clamp-1 font-base text-sm text-text'>{structure.address.city} ({structure.address.state}), {structure.address.street}</p>
            </div>

            <div className='flex flex-col h-full w-full p-8 gap-8'>
                {isStructureAbleToAutoBook()
                    ? renderStep()
                    : <div className='flex flex-col gap-4 h-full'>
                        <PageTitle title='Link Scaduto' goBackBtn={false} />
                        <div className='text-text text-lg text-center items-center justify-center flex h-full'>
                            <p >
                                Il link di prenotazione per questa struttura è scaduto.<br />
                                La struttura potrebbe aver temporaneamente disattivato la modalità di prenotazione online.<br />
                                Ti consigliamo di contattarli direttamente per maggiori informazioni.
                            </p>
                        </div>
                    </div>
                }
            </div>

        </div>
    );
};


export default BookingRequestCreateWizard