import { useSearchParams } from "react-router-dom";

const RightOverlayContent = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="p-8 text-center w-2/3 flex flex-col gap-4">
            <p className="text-4xl font-bold text-white">Benvenuto su IMIN</p>

            <p className="text-lg text-white">IMIN aiuta a tenere traccia delle prenotazioni dei servizi della tua attività commerciale.</p>

            <button
                className="py-3 px-6 bg-transparent rounded-md text-center text-white font-bold uppercase ring-2 ring-white active:scale-110 transition-transform ease-in"
                onClick={() => {
                    searchParams.set('register', "true");
                    setSearchParams(searchParams)
                }}
            >
                Registrati
            </button>
        </div>
    );
};

export default RightOverlayContent;