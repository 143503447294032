import React from 'react';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import CustomButton from '../../../../components/CustomButton';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import useFetch from '../../../../hooks/useFetch';
import { IEvent } from '../../../../types/interfaces/event.interface';
import { apiService, HttpMethod } from '../../../../services/apiService';
import { EventProposalOptions } from '../../../../types/enum/eventProposalsOptions.enum';
import { IAutoBookingOption } from '../../../../types/interfaces/autoBookingOptions.interface';
import MenuProposalAccordion from '../details/details_pages/MenuProposalAccordion';

interface Step4Props {
    data: IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>;
    setData: React.Dispatch<React.SetStateAction<IBookingRequest<IStructure, IOffer<IAutoBookingOption>, IProposal>>>;
    nextStep: () => void;
    prevStep: () => void;
}

const Step4Create: React.FC<Step4Props> = ({ data, setData, nextStep, prevStep }) => {

    const { data: events } = useFetch<{ offerEvent: IEvent<string, IProposal> | undefined, dailyEvent: IEvent<string, IProposal> | undefined }>(
        () => apiService(HttpMethod.POST, `/event/public/get/dailyEvent`, { date: data.date, structureId: data.structure, offer: data.offer._id }),
        undefined,
        [data],
        !!data.date && !!data.structure && !!data.offer
    );

    const handleNext = (proposal: IProposal) => {
        setData({ ...data, proposal });
        nextStep();
    };

    let proposals: IProposal[] = [];

    switch (events?.offerEvent?.proposalOption) {
        case EventProposalOptions.COMBINED:
            proposals = (data.offer.autoBookingOptions?.proposals ?? [] as any).concat(events?.offerEvent?.proposals ?? []);
            break;
        case EventProposalOptions.CUSTOM:
            proposals = events?.offerEvent?.proposals ?? [];
            break;
        case EventProposalOptions.SERVICE:
            proposals = data.offer.autoBookingOptions?.proposals ?? [] as any;
            break;
        default:
            proposals = data.offer.autoBookingOptions?.proposals ?? [] as any;
            break;
    };

    return (
        <>
            <div className='flex flex-col gap-2'>
                <p className='font-bold text-text text-2xl'>Proposte</p>
                <p className='text-darkGray font-semibold md:text-center'>
                    Completa la tua prenotazione inserendo le tue informazioni personali e, se desideri, lascia una nota per l'organizzatore per assicurarti un'esperienza perfetta durante la tua visita.
                </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {!!proposals &&
                    proposals.map((proposal, index) => {
                        return (
                            <button
                                key={index}
                                onClick={() => handleNext(proposal as IProposal)}
                                className={`flex flex-col items-center justify-between p-4 gap-6 bg-white rounded-md shadow`}
                            >
                                <div className='flex flex-col gap-4 w-full'>
                                    <div className='flex justify-between items-center w-full gap-4 h-16'>
                                        <p className='text-2xl font-bold text-primary capitalize line-clamp-2 text-left'>{(proposal as IProposal).name}</p>
                                        <p className='text-base font-bold text-darkGray whitespace-nowrap'>{((proposal as IProposal).price).toFixed(2)} €</p>
                                    </div>

                                    {!!(proposal as IProposal).description && <div className='flex flex-col w-full'>
                                        <p className='text-text text-left text-base font-semibold'>Descrizione</p>
                                        <p className='text-text text-left text-sm'>
                                            {(proposal as IProposal).description}
                                        </p>
                                    </div>}

                                    <MenuProposalAccordion proposal={proposal as any} />
                                </div>

                            </button>
                        )
                    })
                }
            </div>

            <div className='flex flex-col gap-2 h-full justify-end md:w-fit'>
                <CustomButton type='button' label="Indietro" onClickHandler={prevStep} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' />
            </div>
        </>
    );
};

export default Step4Create;