import { useRevalidator } from "react-router-dom";
import { useModalDispatcher } from "../../../hooks/useModalDispatcher";
import { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import InputForm from "../../../components/InputForm";
import CustomButton from "../../../components/CustomButton";
import { apiHandler } from "../../../services/apiHandler";
import { ICategory } from "../../../types/interfaces/category.interface";
import { IProduct } from "../../../types/interfaces/product.inteface";
import { IProposal } from "../../../types/interfaces/proposal.interface";
import { IStructure } from "../../../types/interfaces/structure.interface";
import { IOffer } from "../../../types/interfaces/offer.interface";
import CropImage from "../../../components/CropImage";
import { PhotoPlaceHolderIconXS } from "../../../utils/icons/PlaceHolder";
import { apiService, HttpMethod } from "../../../services/apiService";
import Text from "../../../components/Text";
import { FoodAllergen, translateAllergenToItalian } from "../../../types/enum/foodAllergen.enum";
import { GoBackIcon } from "../../../utils/icons/GoBackIcon";
import { IminError } from "../../../types/interfaces/iminError.interface";
import { getAllergenIcon } from "../../../utils/foodAllergenicsUtils";
import Switch from "../../../components/Switch";

interface IProps {
    isOpen: boolean;
    setIsOpen: (e: boolean) => void;
    category?: ICategory<IProduct> | undefined;
    proposal?: IProposal<IStructure, IOffer, IProduct> | undefined;
    element?: IProduct;
    setElement?: React.Dispatch<React.SetStateAction<IProduct | undefined>>;
    image?: boolean;
};

const ManageProduct: React.FC<IProps> = ({ isOpen, setIsOpen, category, proposal, element, setElement, image = false }) => {
    const { showErrorModal } = useModalDispatcher();
    const { revalidate } = useRevalidator();

    const ref = useRef<HTMLInputElement | null>(null);

    const [product, setProduct] = useState<IProduct>({} as IProduct);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    useEffect(() => {
        if (element) {
            setProduct(element);
        } else {
            setSelectedImage("");
            setProduct({} as IProduct);
        }
    }, [element]);

    const handleManageProduct = async () => {
        try {
            setError('');

            if (!product || !product?.name?.length) {
                setError('Inserisci il nome del prodotto.');
                return;
            }

            if ((!element && !!category) && category?.products?.find(prod => { if (typeof prod !== 'string') { return prod.name.toLowerCase() === product.name.toLowerCase() } else { return false } })) {
                setError('Prodotto gia esistente.');
                return;
            }

            if ((!element && !!proposal) && proposal?.products?.find(prod => { if (typeof prod !== 'string') { return prod.name.toLowerCase() === product.name.toLowerCase() } else { return false } })) {
                setError('Prodotto gia esistente.');
                return;
            }

            setIsLoading(true);

            const formData = new FormData();
            formData.append('name', product.name);
            formData.append('description', product?.description ?? "");
            formData.append('price', product?.price?.toString() ?? 0);

            if (!!category?._id) {
                formData.append('is_frozen', product?.is_frozen?.toString());

                if (product?.allergens?.length)
                    formData.append('allergens', JSON.stringify(product?.allergens) ?? []);
            }
            if (!!category?._id) formData.append('category', category?._id)
            if (!!proposal?._id) formData.append('proposal', proposal?._id)

            if (selectedImage && image) {
                const response = await fetch(selectedImage);
                const blob = await response.blob();
                formData.append('image', blob, 'image.png');
            }

            let response;
            if (!!element) {
                response = await apiService<IProduct | IminError>(HttpMethod.PUT, 'product/update/' + element._id, formData, undefined, { multipart: true });
            } else {
                response = await apiService<IProduct | IminError>(HttpMethod.POST, 'product/create', formData, undefined, { multipart: true });
            }

            if ('errorCode' in response) {
                throw response;
            }

            revalidate();
            setProduct({} as IProduct);
            setSelectedImage(null);
            setIsLoading(false);

            ref?.current?.focus();

            if (!!element)
                handleClose();

        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleDeleteProduct = async () => {
        try {
            setIsLoading(true);

            await apiHandler.del('product/delete/' + element?._id);

            revalidate();
            setProduct({} as IProduct);
            setSelectedImage(null);
            handleClose();
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    }

    const handleProductChange = (field: keyof IProduct, value: typeof product[keyof IProduct]): void => {
        setProduct(prevProduct => ({
            ...prevProduct,
            [field]: value
        }));
    };

    const handleClose = () => {
        if (setElement) { setElement(undefined) }
        setIsOpen(false);
    };

    const handleChangeImage = (newImageSrc: string | null) => {
        setSelectedImage(newImageSrc);
    };

    const handleManageAllergen = (allergen: FoodAllergen) => {
        setProduct(prevProduct => ({
            ...prevProduct,
            allergens: prevProduct?.allergens?.includes(allergen) ? prevProduct.allergens.filter(all => all !== allergen) : [...prevProduct.allergens ?? [], allergen]
        }))
    };


    return (
        <Modal isOpen={isOpen} onClose={handleClose} bgColor="bg-background" fullScreen={true} classNameChildren="flex flex-col justify-between overflow-auto h-full p-4 gap-4">
            <div className="flex gap-2 items-center">
                <div onClick={handleClose} className='cursor-pointer border-2 bg-white shadow rounded-lg flex items-center justify-center p-2 w-fit aspect-square'>
                    <GoBackIcon />
                </div>
                <h2 className="text-3xl font-bold text-text text-center">{!!element ? 'Modifica' : 'Nuovo'} Prodotto</h2>
            </div>

            <div className="flex flex-col gap-2">
                {image && <CropImage onImageChange={handleChangeImage} name='image' maxWidth={1920} maxHeight={1080} aspect={16 / 9}>
                    <label htmlFor="image" className='cursor-pointer w-60 sm:w-80 md:w-72'>
                        <div className="relative w-full flex justify-center">
                            {selectedImage || product.image ? (
                                <img
                                    className='w-full aspect-video rounded-md shadow'
                                    src={selectedImage || product.image}
                                    alt='img-suggestion'
                                />
                            ) : (
                                <div className='w-60 sm:w-80 md:w-72 flex items-center justify-center aspect-video rounded-md shadow bg-white'>
                                    <PhotoPlaceHolderIconXS />
                                </div>
                            )}
                            <p className='bg-text px-4 py-1 rounded-full text-text_white font-semibold text-sm md:text-lg absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 whitespace-nowrap'>
                                Carica Immagine
                            </p>
                        </div>
                    </label>
                </CropImage>}

                <InputForm inputRef={ref} backgroundColor='bg-white' label='Nome *' value={product?.name ?? ""} setValue={(e) => handleProductChange('name', e)} onSearchSubmit={handleManageProduct} />

                {!!category && <div className="flex gap-4 items-center relative">
                    <InputForm backgroundColor='bg-white' label="Prezzo" customStyle="pr-6" type="number" fullWidth value={product?.price ?? ""} setValue={(e) => handleProductChange('price', e)} onSearchSubmit={handleManageProduct} />
                    <p className="font-semibold text-2xl text-darkGray absolute right-1 bottom-0.5">€</p>
                </div>}

                <InputForm backgroundColor='bg-white' label='Descrizione' multiline multilineRows={6} value={product?.description ?? ""} setValue={(e) => handleProductChange('description', e)} onSearchSubmit={handleManageProduct} />

                {!!category?._id && <div className="flex flex-col gap-2">
                    <Text title="Allergeni" label='Scegli gli allergeni del tuo prodotto.' />
                    <div className="grid grid-cols-3">
                        {Object.values(FoodAllergen).sort((a, b) => translateAllergenToItalian(a) > translateAllergenToItalian(b) ? 1 : -1).map((allergen, index) => (
                            <button type="button" key={index}
                                onClick={() => handleManageAllergen(allergen)}
                                className={`flex flex-col items-center gap-1 p-2`}
                            >
                                <div className={`${product?.allergens?.includes(allergen) ? '' : ''} transition-all duration-300 ease-in-out`}>
                                    {getAllergenIcon(allergen, product?.allergens?.includes(allergen) ? undefined : '#A9A9A9')}
                                </div>
                                <p className={`text-xs ${product?.allergens?.includes(allergen) ? 'text-text font-semibold' : 'text-mediumGray font-medium'} transition-all duration-300 ease-in-out`}>
                                    {translateAllergenToItalian(allergen)}
                                </p>
                            </button>
                        ))}
                    </div>
                </div>}

                {!!category?._id && <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                        <Text title="Prodotto congelato?" label=" " />
                        <Switch isOn={product?.is_frozen} toggleSwitch={() => handleProductChange('is_frozen', !product?.is_frozen)} size="md" />
                    </div>
                    <Text title="" label="Seleziona questa opzione se il prodotto sarà congelato quando non disponibile fresco. Verrà mostrato un asterisco (*) accanto al piatto nel menu." />
                </div>}

            </div>

            {!!error && <p className="text-error text-sm text-center font-medium">{error}</p>}

            <div className={`flex flex-row-reverse gap-2 justify-between`}>
                <CustomButton disabled={isLoading} onClickHandler={handleManageProduct} color='bg-text' className='border-2 border-text' label={!!element ? 'Modifica' : 'Crea'} type="button" />
                {!element
                    ? <CustomButton onClickHandler={handleClose} color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' label='Fine' type="button" />
                    : <CustomButton onClickHandler={handleDeleteProduct} color='bg-error' className='border-2 border-error' label='Elimina' type="button" />
                }
            </div>
        </Modal>
    )
}

export default ManageProduct