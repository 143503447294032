import { ActionFunctionArgs, LoaderFunctionArgs, redirect } from "react-router-dom"
import { apiService, HttpMethod } from "../apiService";
import { IEvent } from "../../types/interfaces/event.interface";
import moment from "moment";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";
import { AxiosError } from "axios";

export function useEventApi() {
    const { showErrorModal } = useModalDispatcher();

    const listEvents = async (args: LoaderFunctionArgs) => {
        const url = new URL(args.request.url);
        const startDate = url.searchParams.get("startDate");
        const endDate = url.searchParams.get("endDate");

        try {
            // Api call per prendersi gli eventi del mese visualizzato
            const events = await apiService<IEvent[] & { errorCode: string, status: number, message?: string }>(HttpMethod.GET, `/event/list`, undefined, { startDate, endDate });

            if (events instanceof AxiosError) {
                throw events;
            }

            if (!!events.errorCode) {
                showErrorModal(events.status, events.errorCode, events.message);
                return events
            }

            const startMoment = moment(startDate).utc(true);
            const endMoment = moment(endDate).utc(true);

            const middleMoment = startMoment.clone().add(endMoment.diff(startMoment, 'days') / 2, 'days').startOf('month');

            // Ritorno anche la data da mostrare selezionata per una migliore ui.  
            let selectedDate = '';

            if (middleMoment.isSame(moment(), 'month')) {
                selectedDate = moment().utc(true).startOf('day').toISOString()
            } else {
                selectedDate = middleMoment.toISOString();
            }

            return { events, selectedDate };
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const createEvent = async (args: ActionFunctionArgs) => {
        const formData = await args.request.formData();
        const data = Object.fromEntries(formData) as any;

        try {
            const event = await apiService<IEvent & { errorCode: string, status: number, message?: string }>(HttpMethod.POST, '/event/create', formData, undefined, { multipart: true });

            if (event instanceof AxiosError) {
                throw event;
            }

            if (!!event.errorCode) {
                showErrorModal(event.status, event.errorCode, event.message);
                return event;
            }

            return redirect(`/dashboard/events?startDate=${moment(data.date).utc(true).startOf('month').startOf('week').toISOString()}&endDate=${moment(data.date).utc(true).endOf('month').endOf('week').toISOString()}`);
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const updateEvent = async (args: ActionFunctionArgs) => {
        const formData = await args.request.formData();
        let data = Object.fromEntries(formData) as any;
        const eventParsed = JSON.parse(data.event) as IEvent;

        try {
            const event = await apiService<IEvent & { errorCode: string, status: number, message?: string }>(HttpMethod.PUT, '/event/update/' + args.params.id, formData, undefined, { multipart: true });

            if (event instanceof AxiosError) {
                throw event;
            }

            if (!!event.errorCode) {
                showErrorModal(event.status, event.errorCode, event.message);
                return event;
            }
            return redirect(`/dashboard/events/info/${moment(eventParsed.date).utc(true).startOf('day').toISOString()}`);
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const deleteEvent = async (args: ActionFunctionArgs) => {
        try {
            const formData = await args.request.formData();
            let data = Object.fromEntries(formData) as any;
            const event = JSON.parse(data.event) as IEvent;

            const response = await apiService<IEvent & { errorCode: string, status: number, message?: string }>(HttpMethod.PUT, '/event/delete/' + event._id);

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return redirect(`/dashboard/events/info/${event.date}`);
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const searchEventByName = async (name: string) => {
        try {
            const response = await apiService<IEvent[] | { errorCode: string, status: number, message?: string }>(HttpMethod.GET, `/event/search/${name}`);

            if (response instanceof AxiosError) {
                throw response;
            }

            if ('errorCode' in response) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return response;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    return {
        listEvents,
        createEvent,
        updateEvent,
        deleteEvent,
        searchEventByName
    };
}