interface EventEntityType {
    [key: string]: string;
}

const entityMessages: EventEntityType = {
    BO: 'Prenotazione',
    EV: 'Evento',
    ET: 'Modello Evento',
    OF: 'Offerta',
    ST: 'Struttura',
    US: 'Utente',
    SU: 'Utente della struttura',
    NO: 'Notifica',
    OT: 'Token OTP',
    SS: 'Token di Sessione',
    CR: 'Crash',
    GE: 'Generale',
    PO: 'Profilo',
    PE: 'Entità Profilo',
    BL: 'Blacklist',
    PG: 'Plugin della struttura',
    AU: 'autorizzazione',
    PL: 'Plugin',
    DI: 'Info Giornaliere',
    OR: 'Comanda',
    TB: 'Tavolo',
    CT: 'Categoria',
    PD: 'Prodotto',
    VR: 'Variazione',
    AB: 'Opzioni Prenotazione Online',
    BR: 'Richiesta di prenotazione',
    PS: 'Proposta',
    WA: 'Whatsapp',
    BC: 'Automazioni prenotazioni',
    LI: 'Lista',
    PA: 'Partecipante lista',
    PR: 'Promoter',
    S3: 'Storage file'
};

const eventMessages: EventEntityType = {
    '000': ': Errore Generico',
    '001': 'non autorizzato',
    '002': ', campo/i errato',
    '003': ', dati errati',
    '004': 'non trovato',
    '005': 'non verificato',
    '006': ', dati mancanti',
    '007': 'già esistente',
    '008': ', dati scomparsi',
    '009': 'non esistente'

};

export const getErrorMessage = (errorCode: string) => {
    if (errorCode) {
        const entityCode = errorCode.substring(0, 2);
        const eventCode = errorCode.substring(2, 5);

        const entityMessage = entityMessages[entityCode] || 'Errore sconosciuto';
        const eventMessage = eventMessages[eventCode] || 'Errore sconosciuto';

        const errorMessage = `${entityMessage} ${eventMessage}`;

        return errorMessage;
    }
    return 'Errore sconosciuto'
};

