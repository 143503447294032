import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/CustomButton'
import React from 'react';

interface IProps {
    redirectUrl?: string
}

const GenericError: React.FC<IProps> = ({ redirectUrl = '/dashboard/home' }) => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(redirectUrl)
    }

    return (
        <div className="flex flex-col items-center justify-center h-full w-full bg-background p-4">
            <h1 className='font-bold text-text text-3xl md:text-6xl mb-4 text-center'>Qualcosa è andato storto.</h1>
            <h2 className='font-medium text-text text-lg md:text-2xl mb-2 text-center'>Riprova piu tardi o contatta l'assistenza</h2>
            <CustomButton label="Torna alla Home" onClickHandler={handleRedirect} color='bg-primary' className='mt-4' />
        </div>
    )
}

export default GenericError
