import { ActionFunctionArgs, LoaderFunctionArgs, redirect } from "react-router-dom"
import { apiService, HttpMethod } from "../apiService";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";
import { AxiosError } from "axios";
import { IBookingPagination } from "../../views/booking/booking/Bookings";
import { IBooking } from "../../types/interfaces/booking.interface";

export function useBookingApi() {
    const { showErrorModal } = useModalDispatcher();

    const listBookings = async (args: LoaderFunctionArgs) => {
        const url = new URL(args.request.url);
        const bookingDate = url.searchParams.get('bookingDate');
        const pageSize = url.searchParams.get('pageSize');
        const currentPage = url.searchParams.get('currentPage');
        const filters = url.searchParams.get('filters');
        const sort = url.searchParams.get('sort');

        const parsedFilter = JSON.parse(filters || '{}');
        const parsedSort = JSON.parse(sort || '{}');

        const isPageSizeValid = Number(pageSize) <= 100


        try {
            // Api call per prendersi le bookings
            const bookings = await apiService<IBookingPagination & { errorCode: string, status: number, message?: string }>(
                HttpMethod.POST,
                '/booking/list',
                {
                    pagination: {
                        pageSize: (isPageSizeValid && pageSize) ? Number(pageSize) : 15,
                        currentPage: (currentPage && Number(currentPage) > 0) ? Number(currentPage) : 1,
                        filters: { ...parsedFilter, bookingDate },
                        sort: parsedSort
                    }
                });

            if (bookings instanceof AxiosError) {
                throw bookings;
            }


            if (!!bookings.errorCode) {
                showErrorModal(bookings.status, bookings.errorCode, bookings.message);
                return bookings
            }

            return bookings;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    const deleteBooking = async ({ params, request }: ActionFunctionArgs) => {
        try {
            const formData = await request.formData();
            let data = Object.fromEntries(formData) as any;

            const response = await apiService<IBooking[] & { errorCode: string, status: number, message?: string, _id: string }>(HttpMethod.PUT, '/booking/delete/' + params.id ?? '', data);

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            return redirect('/dashboard/bookings?bookingDate=' + response[0].bookingDate)

        } catch (error: any) {
            throw error
        };
    };


    return {
        listBookings,
        deleteBooking
    };
}