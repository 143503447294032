import { useEffect, useState } from 'react';
import { IBooking, PartialIBooking } from '../../../types/interfaces/booking.interface';
import { Form, useLoaderData, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton';
import moment from 'moment';
import { CAKE_TYPE, mapCakeType } from '../../../types/enum/cakeType';
import BookingStatusSlider from '../../../components/BookingStatusSlider';
import { EmptyStarIcon, FullStarIcon } from '../../../utils/icons/StarIcon';
import Text from '../../../components/Text';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { IOffer } from '../../../types/interfaces/offer.interface';
import { IProposal } from '../../../types/interfaces/proposal.interface';
import { IStructure } from '../../../types/interfaces/structure.interface';
import { IUser } from '../../../types/interfaces/auth.interface';
import Modal from '../../../components/Modal';
import RadioButton from '../../../components/RadioButton';
import { LinkIcon } from '../../../utils/icons/LinkIcon';
import { AutoBookingType } from '../../../types/enum/autoBookingType.enum';
import { IBookingRequest } from '../../../types/interfaces/bookingRequest.interface';

const BookingDetails = () => {
    const booking = useLoaderData() as IBooking<IStructure, IOffer, IUser, IProposal, string, PartialIBooking<{ offer: IOffer, proposals: IProposal }>, IBooking<string, IOffer>, IBookingRequest>;
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const [bookedOffers, setBookedOffers] = useState<IBooking<string, IOffer, IUser, IProposal, string, PartialIBooking<{ offer: IOffer, proposals: IProposal, user: IUser, periodicBookings: IBooking<string, IOffer> }>, IBooking, IBookingRequest>[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selection, setSelection] = useState({
        sharedOption: false,
        scheduleOption: false
    });

    useEffect(() => {
        setActiveIndex(0);
        if (booking)
            setBookedOffers([booking, ...booking.sharedBookingsNew as any]);
    }, [booking])


    const handleServiceOptionChange = (value: boolean) => {
        setSelection(prevState => ({
            ...prevState,
            sharedOption: value,
        }));
    };

    const handleScheduleOptionChange = (value: boolean) => {
        setSelection(prevState => ({
            ...prevState,
            scheduleOption: value,
        }));
    };

    const handleCancelPress = () => {
        let currentFilters;

        if (searchParams.get('filters')) {
            currentFilters = JSON.parse(searchParams.get('filters') || '{}');
        }

        currentFilters
            ? navigate('/dashboard/bookings' + '?bookingDate=' + searchParams.get('bookingDate') + '&filters=' + JSON.stringify(currentFilters), { replace: true })
            : navigate('/dashboard/bookings' + '?bookingDate=' + searchParams.get('bookingDate'), { replace: true });
    }

    // RENDER BOOKINGS DETAILS
    const renderBookingDetails = () => {
        return <>
            <div className='flex flex-col gap-4'>
                <div className='grid grid-cols-2 gap-4'>
                    <Text title='Cognome' label={booking.surname} reverseFontStyle />
                    {!!booking.name && <Text title='Nome' label={booking.name!} reverseFontStyle />}
                    {!!booking.email && <Text title='Email' className='normal-case' label={booking.email!} reverseFontStyle />}
                    {!!booking.age && <Text title='Etá' label={booking.age!} reverseFontStyle />}
                    <Text title='Telefono' label={booking.phoneNumber} reverseFontStyle />
                    <Text title='Data' label={moment(booking.bookingDate).format('DD-MM-YYYY')} reverseFontStyle />
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <p className="font-semibold text-text text-lg">Servizi</p>
                <div className='flex flex-col select-none shadow'>
                    <div className='flex'>
                        {bookedOffers?.map((bookedOffer, index) => {
                            return <div key={index}
                                onClick={() => setActiveIndex(index)}
                                className={`flex items-center justify-center h-8 text-base font-medium capitalize cursor-pointer w-full ${index === activeIndex ? 'text-white bg-primary shadow-xl' : 'text-text bg-background'} rounded-t-md truncate`}
                            >
                                {bookedOffer.offer.name}
                            </div>
                        })}
                    </div>

                    {!!bookedOffers[activeIndex]
                        ? <div className='flex flex-col bg-white w-full rounded-b-md shadow-md px-2 py-4 gap-4'>
                            <div className='grid grid-cols-3 gap-2'>
                                <Text title='Pax' label={bookedOffers[activeIndex].people} containerClassName='col-span-2' reverseFontStyle />
                                <Text title='Turno' label={bookedOffers[activeIndex].shift!} reverseFontStyle />
                            </div>

                            <div className='grid grid-cols-3 gap-2'>
                                <Text title='Bambini' label={bookedOffers[activeIndex].childs} containerClassName='col-span-2' reverseFontStyle />
                                <Text title='Seggiolini' label={bookedOffers[activeIndex].childSeats!} reverseFontStyle />
                            </div>

                            {bookedOffers[activeIndex]?.proposals?.length > 0
                                ? <div>
                                    <div className='grid grid-cols-3 gap-2 w-full'>
                                        <Text title={"Proposte"} label={' '} reverseFontStyle containerClassName='col-span-2' />
                                        <Text title={'Qta'} label={' '} reverseFontStyle />
                                    </div>
                                    {bookedOffers[activeIndex].proposals.map((proposal, index) => {
                                        return <div key={index} className='grid grid-cols-3 gap-2'>
                                            <Text title={proposal.proposal.name} label={' '} containerClassName='col-span-2' />
                                            {!!proposal.quantity && <Text title={proposal.quantity.toString()} label={" "} />}
                                        </div>
                                    })}
                                </div>
                                : <p className='text-lg font-semibold text-center text-warning'>- Proposte da definire -</p>
                            }

                            <Text title='Note Interne' label={bookedOffers[activeIndex].notes} reverseFontStyle />
                            <Text title='Note per il Cliente' label={bookedOffers[activeIndex].customerNotes} reverseFontStyle />
                            {!!bookedOffers[activeIndex]?.bookingRequest && <Text title='Note Online del Cliente' label={bookedOffers[activeIndex]?.bookingRequest?.notes} reverseFontStyle />}

                            {bookedOffers[activeIndex].cakeType !== CAKE_TYPE.NO_CAKE && <Text title='Torta' label={mapCakeType(bookedOffers[activeIndex].cakeType)} reverseFontStyle />}
                            <Text title='Note Torta' label={bookedOffers[activeIndex].cakeNote} reverseFontStyle />

                            <div className='flex flex-col gap-2'>
                                <BookingStatusSlider bookingStatus={bookedOffers[activeIndex].bookingStatus} />
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </>
    };

    return (
        <Form method='PUT' className='flex flex-col h-full'>
            <div className='flex flex-col gap-4 flex-1'>

                <div className='flex items-center justify-between gap-2'>
                    <div>
                        <p className='text-primary font-bold text-xl'>Prenotazione</p>
                        {
                            booking.bookingRequest?.autoBookingType === AutoBookingType.AUTOMATIC
                                ? <p className='text-info font-semibold text-sm'>Creata Online</p>
                                : <p className='text-info font-semibold text-sm'>Creata da {booking.createdBy.name}</p>
                        }
                    </div>
                    <div title={booking.isImportant ? 'Importante' : 'Non importante'}>{booking.isImportant ? <FullStarIcon /> : <EmptyStarIcon />}</div>
                </div>

                {renderBookingDetails()}

                {!!booking?.periodicBookings?.length && (
                    <div className='flex flex-col gap-2'>
                        <p className='font-semibold text-text text-lg'>Prenotazioni schedulate</p>
                        <div className='flex flex-col gap-1'>
                            {booking.periodicBookings
                                .sort((a, b) => new Date(a.bookingDate).getTime() - new Date(b.bookingDate).getTime())
                                .map((periodicBooking, index) => (
                                    <div
                                        key={index}
                                        className='flex items-center gap-2 cursor-pointer'
                                        onClick={() => navigate('/dashboard/bookings/details/' + periodicBooking._id + '?bookingDate=' + periodicBooking.bookingDate, { replace: true })}
                                    >
                                        <p className={`text-text truncate capitalize whitespace-pre-line`}>
                                            - {moment(periodicBooking.bookingDate).format('dddd DD MMMM')}, {periodicBooking.offer.name}
                                        </p>
                                        <LinkIcon />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )}
            </div>

            <div className='flex flex-col gap-2 justify-between mt-2'>
                {(booking.bookingRequest?.autoBookingType === AutoBookingType.SEMI_AUTOMATIC || !booking.bookingRequest)
                    ? <>
                        <CustomButton type='button' label='Modifica' color='bg-primary' onClickHandler={() => navigate('update?bookingDate=' + booking.bookingDate)} disabled={navigation.state !== 'idle'} />
                        <CustomButton type='button' label='Elimina' color='bg-error' onClickHandler={() => setIsComponentVisible(true)} />
                    </>
                    : <p className='text-center font-medium text-info text-xs'>Le prenotazioni prese dal cliente online non possono essere eliminate o modificate.</p>
                }
                <CustomButton type='button' label='Annulla' color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={handleCancelPress} />
            </div>

            <Modal isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)}>
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-1'>
                        <p className='text-xl font-semibold text-text'>Sicuro di voler eliminare la prenotazione?</p>
                        <p className='text-text text-sm'>Una volta confermata l'eliminazione, non sarà più visibile e non verrà inclusa in nessuna analisi sulle prenotazioni.</p>
                    </div>
                    <div className='flex flex-col gap-2'>
                        {booking?.sharedBookingsNew?.length > 0 &&
                            <div>
                                <p className='font-semibold text-text text-lg'>Cosa vuoi eliminare?</p>
                                <RadioButton
                                    labelStyle='normal-case'
                                    label={`Solo il servizio ${booking.offer.name}`}
                                    checked={selection.sharedOption === false}
                                    onChange={() => handleServiceOptionChange(false)}
                                />
                                <RadioButton
                                    labelStyle='normal-case'
                                    label="Tutti i servizi prenotati di questa prenotazione"
                                    checked={selection.sharedOption === true}
                                    onChange={() => handleServiceOptionChange(true)}
                                />
                            </div>
                        }
                        {booking?.periodicBookings?.length > 0 &&
                            <div>
                                <p className='font-semibold text-text text-lg'>Prenotazioni schedulate nel tempo</p>
                                <RadioButton
                                    labelStyle='normal-case'
                                    label={`Elimina solo quella del ${moment(booking.bookingDate).format('dddd DD MMMM YYYY')}`}
                                    checked={selection.scheduleOption === false}
                                    onChange={() => handleScheduleOptionChange(false)}
                                />
                                <RadioButton
                                    labelStyle='normal-case'
                                    label="Elimina tutte le prenotazioni schedulate nel tempo"
                                    checked={selection.scheduleOption === true}
                                    onChange={() => handleScheduleOptionChange(true)}
                                />
                            </div>
                        }
                    </div>
                    <div className='flex justify-between gap-4'>
                        <CustomButton type='button' label='Annulla' color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => setIsComponentVisible(false)} />
                        <CustomButton type='submit' color='bg-error' label='Elimina' className='border-2 border-error' />
                    </div>
                </div>
            </Modal>

            <input hidden value={String(selection.scheduleOption)} name='scheduleOption' onChange={() => false} />
            <input hidden value={String(selection.sharedOption)} name='sharedOption' onChange={() => false} />

        </Form>
    )
}

export default BookingDetails 
