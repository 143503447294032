import React from 'react';
import SigninForm from './SigninForm';
import SignupForm from './SignupForm';
import LeftOverlayContent from './LeftOverlayContent';
import RightOverlayContent from './RightOverlayContent';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../store/store';
import useCookie from '../../hooks/useCookie';

const LoginView: React.FC = () => {
    const [searchParams] = useSearchParams();
    const overlayBg = "bg-gradient-to-r from-primary via-purple-800 to-primary";

    const { userAuth } = useAppSelector(state => state.auth);
    useCookie({ userData: 'user_cookie' });

    if (userAuth) {
        return <Navigate to="/structure" />;
    };

    return (
        <div className="flex items-center z-10 justify-center h-screen w-screen">
            <div className="h-full w-full bg-white relative overflow-auto md:overflow-hidden">
                <div className={`absolute top-0 md:h-full md:w-1/2 h-1/2 w-full flex justify-center items-center transition-all duration-700 ease-in-out ${searchParams.has('register') ? "max-md:translate-y-full md:translate-x-full opacity-0" : "z-20"}`} >
                    <SigninForm />
                </div>

                <div className={`absolute top-0 md:h-full md:w-1/2 h-1/2 w-full flex justify-center items-center transition-all duration-700 ease-in-out ${searchParams.has('register') ? "max-md:translate-y-full md:translate-x-full opacity-100 z-20 animate-show" : "opacity-0"}`} >
                    <SignupForm />
                </div>

                <div className={`absolute md:top-0 max-md:bottom-0 z-20 md:left-1/2 md:w-1/2 md:h-full max-md:h-1/2 max-md:w-full overflow-hidden transition-transform duration-700 ease-in-out  ${searchParams.has('register') ? "max-md:-translate-y-full md:-translate-x-full" : ""}`} >
                    <div className={`${overlayBg} relative -left-full h-full w-[200%] transform transition-transform duration-700 ease-in-out ${searchParams.has('register') ? "translate-x-1/2" : "translate-x-0"}`} >
                        <div className={`w-1/2 h-full absolute flex justify-center items-center top-0 left-0 transform transition-transform duration-700 ease-in-out ${searchParams.has('register') ? " translate-x-0" : "-translate-x-[20%]"}`} >
                            <LeftOverlayContent />
                        </div>
                        <div className={`w-1/2 h-full absolute flex justify-center items-center top-0 right-0 transform transition-transform duration-700 ease-in-out ${searchParams.has('register') ? "translate-x-[20%]" : "translate-x-0"}`} >
                            <RightOverlayContent />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginView;
