import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomeView from "../views/home/HomeView";
import KitchenView from "../views/plugins/KitchenView";
import OfferView from "../views/offer/OfferView";
import OfferManage from "../views/offer/OfferManage";
import LoginView from "../views/auth/LoginView";
import { LoginLayout } from "./layouts/LoginLayout";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import { StructureLayout } from "./layouts/StructureLayout";
import StructureView from "../views/auth/StructureView";
import VerifyAccount from "../views/auth/VerifyAccount";
import EntryManagement from "../views/plugins/EntryManagement";
import PageNotFound from "../views/error/PageNotFound";
import ResetPassword from "../views/auth/ResetPassword";
import UserStructureManage from "../views/structure/UserStructureManage";
import { useAppDispatch } from "../store/store";
import Landing from "../views/public/Landing";
import Structure from "../views/structure/Structure";
import OfferLayout from "./protectedLayout/OfferLayout";
import { useCrud } from "../hooks/useCrud";
import AutoBookingManage from "../views/offer/autoBook/AutoBookingManage";
import BookingRequestDetails from "../views/booking/bookingRequest/details/BookingRequestDetails";
import BookingRequestCreateWizard from "../views/booking/bookingRequest/create/BookingRequestCreateWizard";
import { IOffer } from "../types/interfaces/offer.interface";
import { IStructure } from "../types/interfaces/structure.interface";
import { IBookingRequest } from "../types/interfaces/bookingRequest.interface";
import BookingRequestUpdateWizard from "../views/booking/bookingRequest/update/BookingRequestUpdateWizard";
import ManageProposals from "../views/offer/proposals/Proposals";

import BookingLayout from "./protectedLayout/BookingLayout";
import BookingCreate from "../views/booking/booking/BookingCreate";
import BookingDetails from "../views/booking/booking/BookingDetails";
import { HttpMethod, apiService } from "../services/apiService";
import Bookings from "../views/booking/booking/Bookings";
import { replaceUserStructure } from "../store/account/accountSlice";
import EventLayout from "./protectedLayout/EventLayout";
import KitchenLayout from "./protectedLayout/KitchenLayout";
import EntryLayout from "./protectedLayout/EntryLayout";
import StructureOptionsLayout from "./protectedLayout/StructureOptionsLayout";
import StructureManage from "../views/structure/StructureManage";
import Events from "../views/event/Events";
import EventCreate from "../views/event/EventCreate";
import { useEventApi } from "../services/routingApi/event.api";
import { IEvent } from "../types/interfaces/event.interface";
import BookingRequests from "../views/booking/bookingRequest/details/BookingRequests";
import RequestsView from "../views/booking/bookingRequest/admin/RequestsView";
import RequestLayout from "./protectedLayout/RequestLayout";
import PublicLayout from "./layouts/PublicLayout";
import { queryListBookingRequests } from "../services/routingApi/bookingRequests.api";
import { IUserStructure } from "../types/interfaces/userStructure.interface";
import GenericError from "../views/error/GenericError";
import DailyEvents from "../views/event/DailyEvents";
import { IProposal } from "../types/interfaces/proposal.interface";
import { useBookingApi } from "../services/routingApi/booking.api";
import Lists from "../views/list/Lists";
import ListLayout from "./protectedLayout/ListLayout";
import ListDetails from "../views/list/ListDetails";
import { useListApi } from "../services/routingApi/list.api";
import { usePartecipantApi } from "../services/routingApi/partecipant.api";
import PrManage from "../views/structure/PrManage";
import UnsubscribePr from "../views/pr-backoffice/UnsubscribePr";
import PrLogin from "../views/pr-backoffice/PrLogin";
import PrLayout from "./protectedLayout/PrLayout";
import RegisterToList from "../views/public/list-partecipant/RegisterToList";
import PartecipantDetails from "../views/public/list-partecipant/PartecipantDetails";
import PrList from "../views/pr-backoffice/PrList";
import PrHome, { IListWithToken } from "../views/pr-backoffice/PrHome";
import AddPartecipant from "../views/pr-backoffice/AddPartecipant";
import { usePromoterApi } from "../services/routingApi/promoter.api";
import { IList } from "../types/interfaces/list.interface";
import { IPartecipant } from "../types/interfaces/partecipant.interface";
import ListScan from "../views/list/ListScan";
import MenuManage from "../views/offer/menu/MenuManage";
import DigitalMenu from "../views/public/digital-menu/DigitalMenu";
import { ICategory } from "../types/interfaces/category.interface";
import { IProduct } from "../types/interfaces/product.inteface";
import { IminError } from "../types/interfaces/iminError.interface";

const Routing: React.FC = () => {
    const { genericPost, genericPut, genericDelete, genericGet } = useCrud();
    const { createEvent, deleteEvent, listEvents, updateEvent } = useEventApi();
    const { listBookings, deleteBooking } = useBookingApi();
    const { listPrLists, getListPaginated, getListByToken } = useListApi();
    const { addPeopleList } = usePartecipantApi();
    const { checkPermission } = usePromoterApi();

    const dispatch = useAppDispatch();

    const routing = createBrowserRouter([
        {
            path: '',
            element: <PublicLayout />,
            errorElement: <GenericError redirectUrl="/" />,
            children: [
                {
                    index: true,
                    element: <Landing />
                },
                {
                    path: ':name/:id',
                    element: <BookingRequestCreateWizard />,
                    loader: async (args) => await genericGet('/auto-booking-options/get/info/' + args.params.id),
                    action: async (args) => await genericPost<{ bookingRequest: IBookingRequest, urlToken: string }>('/booking-request/create', '/request/', args, true)
                },
                {
                    path: 'request/:token',
                    element: <BookingRequests />,
                    loader: async (args) => await genericGet('/booking-request/get/token/' + args.params.token)
                },
                {
                    path: 'details/:id',
                    element: <BookingRequestDetails />,
                    loader: async (args) => await genericGet('/booking-request/get/' + args.params.id),
                    action: async (args) => await genericDelete('/booking-request/delete/', '/', args, true)
                },
                {
                    path: 'request/:id/update',
                    element: <BookingRequestUpdateWizard />,
                    loader: async (args) => await genericGet<IBookingRequest<IStructure, IOffer>>('/booking-request/get/' + args.params.id),
                    action: async (args) => await genericPut('/booking-request/update/', '/details/', args, true)
                },
                {
                    path: 'register/list/:token',
                    element: <RegisterToList />,
                    loader: async (args) => await getListByToken(args),
                    action: async (args) => await addPeopleList(args, '/partecipant/public/add-user-to-list', '/partecipant/', '_id')
                },
                {
                    path: 'partecipant/:id',
                    element: <PartecipantDetails />,
                    loader: async (args) => await genericGet('/partecipant/public/get/' + args.params.id),
                },
                {
                    path: 'unsubscribe/pr/:id',
                    element: <UnsubscribePr />,
                    loader: async (args) => await genericGet('/pr/unsubscribe/get/' + args.params.id),
                    action: async (args) => await genericPut('/pr/unsubscribe/remove/', '/', args)
                },
                {
                    path: 'digital/menu/:id',
                    element: <DigitalMenu />,
                    loader: async (args) => await genericGet<ICategory<IProduct, IStructure, IOffer> | IminError>('/structure/list/menu/' + args.params.id)
                },
            ]
        },
        {
            path: '/promoter',
            element: <PrLayout />,
            loader: async () => await checkPermission(),
            children: [
                {
                    index: true,
                    element: <PrHome />,
                    loader: async () => await genericGet<IListWithToken[]>('/pr/fetch/lists')
                },
                {
                    path: 'list/:id',
                    element: <PrList />,
                    loader: async (args) => await genericGet('/pr/get/list/' + args.params.id)
                },
                {
                    path: 'list/add/partecipant/:list',
                    element: <AddPartecipant />,
                    action: async (args) => await addPeopleList(args, '/partecipant/pr/add-user-to-list', '/promoter/list/', 'list._id')
                }
            ]
        },
        {
            path: '/auth',
            element: <LoginLayout />,
            children: [
                { index: true, element: <LoginView /> },
                { path: 'verify', element: <VerifyAccount /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'pr/login', element: <PrLogin />, action: async (args) => await genericPost('/pr/login', '/promoter', args) },
            ],
        },
        {
            path: '/structure',
            element: <StructureLayout />,
            children: [
                { index: true, element: <StructureView /> },
                // { path: 'add', element: <AddStructurePage /> },
            ],
        },
        {
            path: '/dashboard',
            element: <ProtectedLayout />,
            errorElement: <GenericError />,
            loader: async () => {
                const response = await genericGet('/user/get-current-user/true');
                dispatch(replaceUserStructure({ userStructure: response }))
                return response;
            },
            children: [
                { path: 'home', element: <HomeView /> },
                {
                    path: 'bookings',
                    element: <BookingLayout />,
                    children: [
                        {
                            path: '',
                            element: <Bookings />,
                            loader: async (args) => await listBookings(args),
                            children: [
                                {
                                    path: 'create',
                                    element: <BookingCreate />
                                },
                                {
                                    path: 'details/:id',
                                    element: <BookingDetails />,
                                    loader: async (args) => await apiService(HttpMethod.GET, '/booking/get/' + args.params.id),
                                    action: async (args) => await deleteBooking(args)
                                },
                                {
                                    path: 'details/:id/update',
                                    element: <BookingCreate />,
                                    loader: async (args) => await apiService(HttpMethod.GET, '/booking/get/' + args.params.id, undefined, { isUpdate: true })
                                }

                            ]
                        }
                    ]
                },
                {
                    path: 'servizi',
                    element: <OfferLayout />,
                    children: [
                        { index: true, element: <OfferView /> },
                        { path: 'manage', element: <OfferManage /> },
                        {
                            path: 'auto-booking/:offerId',
                            element: <AutoBookingManage />,
                            loader: async (args) => {
                                const proposalsFetched = await apiService(HttpMethod.GET, '/proposal/list-by-offer/' + args.params.offerId);
                                const offer = await apiService(HttpMethod.GET, '/offer/get/' + args.params.offerId);
                                const autoBookingOptions = await apiService(HttpMethod.GET, '/auto-booking-options/get-by-offer/' + args.params.offerId);
                                return { proposalsFetched, autoBookingOptions, offer };
                            },
                            action: async (args) => await genericPost('/auto-booking-options/update/' + args.params.offerId, '/dashboard/servizi', args, false)
                        },
                        {
                            path: 'menu/:offerId',
                            element: <MenuManage />,
                            loader: async (args) => await apiService(HttpMethod.GET, '/category/list-by-offer/' + args.params.offerId)
                        },
                        // {
                        //     path: 'variazioni/:offerId',
                        //     element: <Variations />,
                        //     loader: async (args) => await apiService(HttpMethod.GET, '/variation/list-by-offer/' + args.params.offerId)
                        // },
                        {
                            path: 'proposte/:offerId',
                            element: <ManageProposals />,
                            loader: async (args) => await apiService(HttpMethod.GET, '/proposal/list-by-offer/' + args.params.offerId)
                        },
                    ]
                },
                // {
                //     path: 'plugins/comande',
                //     element: <OrderLayout />,
                //     children: [
                //         {
                //             index: true,
                //             element: <OrderHome />,
                //             loader: async () => await apiHandler.post('/offer/list', { isEntertainment: true, tables: true })
                //         },
                //         {
                //             path: 'sale/:offerId',
                //             element: <Rooms />,
                //             loader: async (args) => await apiService(HttpMethod.GET, '/offer/get/' + args.params.offerId)
                //         },
                //         {
                //             path: 'init-table/:tableId',
                //             element: <InitTable />,
                //             loader: async (args) => await apiHandler.post('/offer/get', { tableId: args.params.tableId }), //TODO: cambiare questa api, farne una nuova ed eliminare quella attuale su offer route --> '/get'
                //             action: async (args) => await genericPost('/booked-table/create', '/dashboard/plugins/comande/table/', args, true)
                //         },
                //         {
                //             path: 'table/:bookedTableId',
                //             element: <BookedTable />,
                //             loader: async (args) => {
                //                 const tableBooked = await apiService(HttpMethod.GET, '/booked-table/get/' + args.params.bookedTableId) as ITableBooked;
                //                 const categories = await apiService(HttpMethod.GET, '/category/list-by-offer/' + tableBooked.offer);
                //                 const variations = await apiService(HttpMethod.GET, '/variation/list-by-offer/' + tableBooked.offer);

                //                 return { tableBooked, categories, variations }
                //             }
                //         }
                //     ]
                // },
                {
                    path: 'requests',
                    element: <RequestLayout />,
                    children: [
                        {
                            index: true,
                            element: <RequestsView />,
                            loader: async (args) => await queryListBookingRequests(args)
                        }
                    ]
                },
                {
                    path: 'events',
                    element: <EventLayout />,
                    children: [
                        {
                            index: true,
                            element: <Events />,
                            loader: async (args) => await listEvents(args)
                        },
                        {
                            path: 'info/:date',
                            element: <DailyEvents />,
                            loader: async (args) => await genericGet<IEvent[] & { errorCode: string }>('/event/list', args),
                            action: async (args) => await deleteEvent(args)
                        },
                        {
                            path: 'create',
                            element: <EventCreate />,
                            loader: async () => {
                                const offers = await apiService(HttpMethod.POST, '/offer/list')
                                return { offers }
                            },
                            action: async (args) => await createEvent(args)
                        },
                        {
                            path: 'update/:id',
                            element: <EventCreate />,
                            loader: async (args) => {
                                const offers = await apiService(HttpMethod.POST, '/offer/list');
                                const fetchedEvent = await genericGet<{ event: IEvent<string, IProposal, string>, eventsGrouped: IEvent<string, IProposal, string>[] }>('/event/get/' + args.params.id, args);
                                return { offers, fetchedEvent };
                            },
                            action: async (args) => await updateEvent(args)
                        }
                    ]
                },
                {
                    path: 'list',
                    element: <ListLayout />,
                    children: [
                        {
                            index: true,
                            element: <Lists />,
                            loader: async () => await listPrLists()
                        },
                        {
                            path: ':id',
                            element: <ListDetails />,
                            loader: async (args) => await getListPaginated(args),
                            action: async (args) => await addPeopleList(args)
                        },
                        {
                            path: 'scan/:id',
                            element: <ListScan />,
                            loader: async (args) => await getListPaginated(args),
                        }
                    ]
                },
                {
                    path: 'struttura',
                    element: <StructureOptionsLayout />,
                    children: [
                        {
                            index: true,
                            element: <Structure />,
                            loader: async () => await apiService(HttpMethod.GET, '/structure/info/users')
                        },
                        {
                            path: 'manage/:id',
                            element: <StructureManage />,
                            loader: async () => await apiService(HttpMethod.GET, '/structure/get/current'),
                            action: async (args) => genericPut('/structure/update/', '/dashboard/struttura', args)
                        },
                        {
                            path: 'user',
                            element: <UserStructureManage />,
                            loader: async (args) => await genericGet<IUserStructure>('/user-structure/get/null/?isCreate=true', args)
                        },
                        {
                            path: 'user/:id',
                            element: <UserStructureManage />,
                            loader: async (args) => await genericGet<IUserStructure>('/user-structure/get/' + args.params.id, args)
                        },
                        {
                            path: 'pr',
                            element: <PrManage />,
                            action: async (args) => await genericPost('/pr/add', '/dashboard/struttura', args)
                        },
                        {
                            path: 'pr/:id',
                            element: <PrManage />,
                            loader: async (args) => await genericGet('/pr/get/' + args.params.id),
                            action: async (args) => await genericPut('/pr/remove/', '/dashboard/struttura', args)
                        }
                    ]
                },
                {
                    path: 'plugins/cucina',
                    element: <KitchenLayout />,
                    children: [
                        {
                            index: true,
                            element: <KitchenView />,
                            loader: async () => await apiService(HttpMethod.POST, '/offer/list', { isEntertainment: true, isEnabled: true })
                        }
                    ]
                },
                {
                    path: 'plugins/ingressi',
                    element: <EntryLayout />,
                    children: [
                        { index: true, element: <EntryManagement /> }
                    ]
                }
            ]
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ])

    return <RouterProvider router={routing} />
}

export default Routing;
