import { useLoaderData } from 'react-router-dom';
import { IBookingRequest } from '../../../../types/interfaces/bookingRequest.interface';
import { IOffer } from '../../../../types/interfaces/offer.interface';
import { IStructure } from '../../../../types/interfaces/structure.interface';
import { IProposal } from '../../../../types/interfaces/proposal.interface';
import { AutoBookingType } from '../../../../types/enum/autoBookingType.enum';
import AutomaticDetails from './details_pages/AutomaticDetails';
import SemiAutomaticDetails from './details_pages/SemiAutomaticDetails';
import { PartialIBooking } from '../../../../types/interfaces/booking.interface';
import { IProduct } from '../../../../types/interfaces/product.inteface';

const RequestInfo = () => {
    const bookingRequest = useLoaderData() as IBookingRequest<IStructure, IOffer, IProposal<string, string, IProduct>, PartialIBooking<{ proposals: IProposal<string, string, IProduct> }>>;

    return (
        <div className='flex flex-col gap-4 pb-4'>
            {/* HEADER */}
            <div className='w-full bg-white shadow px-8 py-4 text-center'>
                <p className='line-clamp-1 font-bold text-4xl text-primary'>{bookingRequest?.structure?.name}</p>
                <p className='line-clamp-1 font-base text-sm text-text'>{bookingRequest.structure.address.city} ({bookingRequest.structure.address.state}), {bookingRequest.structure.address.street}</p>
            </div>

            {/* BODY */}
            {bookingRequest.autoBookingType === AutoBookingType.AUTOMATIC
                ? <AutomaticDetails bookingRequest={bookingRequest} />
                : <SemiAutomaticDetails bookingRequest={bookingRequest} />}

        </div>
    )
}

export default RequestInfo