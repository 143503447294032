import { redirect } from "react-router-dom";
import { useModalDispatcher } from "../../hooks/useModalDispatcher";
import { apiService, HttpMethod } from "../apiService";
import { AxiosError } from "axios";
import { IStructure } from "../../types/interfaces/structure.interface";
import { IPr } from "../../types/interfaces/pr.interface";

export function usePromoterApi() {
    const { showErrorModal, showModal } = useModalDispatcher();

    const checkPermission = async () => {
        try {
            const response = await apiService<{ status: boolean, message: string, data?: IPr<IStructure> | undefined }>(HttpMethod.GET, '/pr/check/permission');

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!response.status) {
                return redirect('/auth/pr/login');
            }

            return response;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    return {
        checkPermission
    };
}