import moment from "moment"
import { REQUEST_BOOKING_STATUS, requestBookingStatusBgColorMapping, requestBookingStatusOptions, requestBookingStatusTextColorMapping } from "../../../../../types/enum/requestBookingStatus.enum"
import { IBookingRequest } from "../../../../../types/interfaces/bookingRequest.interface"
import { IOffer } from "../../../../../types/interfaces/offer.interface"
import { IProposal } from "../../../../../types/interfaces/proposal.interface"
import { IStructure } from "../../../../../types/interfaces/structure.interface"
import CustomButton from "../../../../../components/CustomButton"
import { useNavigate } from "react-router-dom"
import Text from "../../../../../components/Text"
import { colorPalette } from "../../../../../types/enum/colorPalette"
import { PartialIBooking } from "../../../../../types/interfaces/booking.interface"
import { BOOKING_STATUS } from "../../../../../types/enum/bookingStatus"
import { IProduct } from "../../../../../types/interfaces/product.inteface"
import MenuProposalAccordion from "./MenuProposalAccordion"
import { CAKE_TYPE } from "../../../../../types/enum/cakeType"

interface IProps {
    bookingRequest: IBookingRequest<IStructure, IOffer, IProposal<string, string, IProduct>, PartialIBooking<{ proposals: IProposal<string, string, IProduct> }>>
}

const SemiAutomaticDetails: React.FC<IProps> = ({ bookingRequest }) => {
    const navigate = useNavigate();

    const rejected_canceled_updated = bookingRequest.status === REQUEST_BOOKING_STATUS.REJECTED || bookingRequest.status === REQUEST_BOOKING_STATUS.CANCELLED || bookingRequest.status === REQUEST_BOOKING_STATUS.UPDATED;
    let sliderStyle: React.CSSProperties = {};

    switch (bookingRequest.status) {
        case REQUEST_BOOKING_STATUS.PENDING:
            sliderStyle.width = '50%';
            break;
        case REQUEST_BOOKING_STATUS.ACCEPTED:
            sliderStyle.width = '100%';
            break;
        case REQUEST_BOOKING_STATUS.UPDATED:
            sliderStyle.width = '100%';
            break;
        case REQUEST_BOOKING_STATUS.REJECTED:
            sliderStyle.width = '100%';
            break;
        case REQUEST_BOOKING_STATUS.CANCELLED:
            sliderStyle.width = '100%';
            break;
        default:
            sliderStyle.width = '50%';
            sliderStyle.backgroundColor = colorPalette.Primary;
            break;
    };

    return (
        bookingRequest.booking.deleted || bookingRequest.booking.bookingStatus === BOOKING_STATUS.CANCELLED
            ? <div className='flex flex-col gap-4 p-8 py-4 flex-1'>
                <p className='text-xl font-semibold text-left'>
                    Caro {bookingRequest?.name} {bookingRequest.surname},<br />
                    questa prenotazione è stata cancellata.
                </p>

                <p className='text-base font-medium text-center flex items-center h-full'>Contatta la struttura per maggiori informazioni.</p>
            </div>
            : <div className='flex flex-col gap-4 px-8 justify-between flex-1'>
                <div className='flex flex-col gap-2'>
                    <p className='text-3xl font-bold'>Dettagli</p>
                    <p className='text-sm font-medium text-darkGray'>
                        Qui troverai i dettagli della tua richiesta di prenotazione, controlla attentamente tutte le informazioni per assicurarti che siano corrette.
                    </p>
                </div>

                <div className='grid grid-cols-2 gap-4'>
                    <Text title='Data' label={moment(bookingRequest.date).format('DD-MM-YYYY')} />
                    {bookingRequest.shift && <Text title='Turno' label={bookingRequest.shift} />}
                    <Text title='Nome' label={bookingRequest?.name} />
                    <Text title='Cognome' label={bookingRequest.surname} />
                    <Text title='Telefono' label={bookingRequest.phoneNumber} />
                    <Text title='Persone' label={bookingRequest.people} />

                    <Text title="Bambini" label={bookingRequest.booking.childs} />
                    <Text title='Seggiolini' label={bookingRequest.booking.childSeats} />

                    <Text title="Torta" label={bookingRequest.booking.cakeType !== CAKE_TYPE.NO_CAKE ? 'Si' : undefined} />
                </div>

                {bookingRequest.booking.customerNotes && <Text title='Note dalla Struttura' label={bookingRequest.booking.customerNotes} className='normal-case' />}

                {!!bookingRequest?.booking?.proposals?.length &&
                    <div className='flex flex-col gap-2 w-full'>
                        <p className="font-semibold text-text">Proposte</p>
                        <div className="flex flex-col gap-2">
                            {bookingRequest?.booking?.proposals?.map((element, index) => (
                                <div key={index} className='bg-white px-4 py-2 rounded-md shadow'>
                                    <div className="flex justify-between items-center gap-2">
                                        <p className='text-xl font-semibold truncate w-full'>{element.proposal.name}</p>
                                        <p className='text-base font-medium whitespace-nowrap'>{element.quantity} x</p>
                                    </div>
                                    <p className='text-sm'>{element.proposal.description}</p>

                                    <MenuProposalAccordion proposal={element.proposal} />
                                </div>
                            ))}

                        </div>
                    </div>}

                <div className='flex flex-col gap-2 w-full'>
                    <p className="font-semibold text-text">Stato</p>
                    <div className='relative w-full h-2 bg-white rounded-full'>
                        <div className={`absolute w-full h-2 rounded-full ${requestBookingStatusBgColorMapping[bookingRequest.status]}`} style={sliderStyle}>
                        </div>
                    </div>
                    {rejected_canceled_updated
                        ? <div className='flex justify-center'>
                            <p className={`text-sm font-semibold ${requestBookingStatusTextColorMapping[bookingRequest.status]}`}>
                                {requestBookingStatusOptions.find(option => option.value === bookingRequest.status)?.label}
                            </p>
                        </div>
                        : <div className='grid grid-cols-2'>
                            {requestBookingStatusOptions
                                .filter(option => option.value === REQUEST_BOOKING_STATUS.PENDING || option.value === REQUEST_BOOKING_STATUS.ACCEPTED)
                                .map(status => (
                                    <p className={`${(bookingRequest.status === status.value) ? requestBookingStatusTextColorMapping[bookingRequest.status] : 'text-darkGray'} font-semibold text-base text-center`} key={status.value}>
                                        {status.label}
                                    </p>
                                ))}
                        </div>
                    }

                    {bookingRequest.status === REQUEST_BOOKING_STATUS.UPDATED && <p className='text-mediumGray text-sm font-semibold text-center'>In attesa che venga accettata la modifica</p>}
                </div>

                <div className='border-2 border-dashed border-primary my-4' />

                <p className='text-sm font-medium text-darkGray'>Se hai bisogno di assistenza o hai domande sulla tua prenotazione, non esitare a contattarci. Siamo qui per aiutarti!</p>

                <div className='flex flex-col md:flex-row-reverse md:justify-between gap-2' >
                    {bookingRequest.structure.contact.phone &&
                        <a href={`tel:${bookingRequest.structure.contact.phone}`} className='text-center bg-primary text-white font-semibold px-4 py-2 rounded-md focus:outline-none'>
                            Chiama Struttura
                        </a>}
                    <CustomButton type='button' label="Indietro" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => navigate(-1)} />
                </div>
            </div>
    )
};

export default SemiAutomaticDetails;