import React, { useState, useRef, useEffect, ReactNode, CSSProperties } from "react";

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    backgroundColor?: string;
    maxHeight?: string;
    initialHeight?: string;
    opacityIntesity?: number;
}

const Drawer: React.FC<DrawerProps> = ({
    isOpen,
    onClose,
    children,
    backgroundColor = "white",
    maxHeight = "80%",
    initialHeight = "30%",
    opacityIntesity = 0,
}) => {
    const [currentHeight, setCurrentHeight] = useState<string>('0');
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const startYRef = useRef<number>(0);
    const drawerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen) {
            setCurrentHeight(initialHeight);
        } else {
            setCurrentHeight('0');
        }
    }, [isOpen, maxHeight, initialHeight]);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent | TouchEvent) => {
            if (isDragging && drawerRef.current) {
                const clientY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;
                const deltaY = startYRef.current - clientY;
                const drawerHeight = drawerRef.current.getBoundingClientRect().height;
                const newHeightInPixels = Math.min(Math.max(drawerHeight + deltaY, 0), window.innerHeight);

                const newHeightInPercent = (newHeightInPixels / window.innerHeight) * 100;
                setCurrentHeight(`${newHeightInPercent}%`);

                startYRef.current = clientY;
            }
        };

        const handleMouseUp = () => {
            if (isDragging) {
                setIsDragging(false);
                if (parseInt(currentHeight) < 5) {
                    setCurrentHeight(initialHeight);
                    onClose();
                } else if (parseInt(currentHeight) < parseInt(initialHeight)) {
                    setCurrentHeight(initialHeight);
                } else {
                    setCurrentHeight(maxHeight);
                }
            }
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("touchmove", handleMouseMove);
        document.addEventListener("touchend", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("touchmove", handleMouseMove);
            document.removeEventListener("touchend", handleMouseUp);
        };
    }, [isDragging, currentHeight, maxHeight, initialHeight, onClose]);

    useEffect(() => {
        if (!isDragging) {
            drawerRef.current?.style.setProperty("transition", "height 300ms ease-in-out");
        } else {
            drawerRef.current?.style.setProperty("transition", "none");
        }
    }, [isDragging]);

    const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        setIsDragging(true);
        startYRef.current =
            e.nativeEvent instanceof MouseEvent
                ? e.nativeEvent.clientY
                : e.nativeEvent.touches[0].clientY;
    };

    const drawerStyles: CSSProperties = {
        height: currentHeight,
        maxHeight: '100%',
        backgroundColor,
        zIndex: 999
    };

    const backdropStyle: CSSProperties = {
        backgroundColor: `rgba(0, 0, 0, ${opacityIntesity / 10})`,
    };

    return (
        <>
            {isOpen && (
                <div
                    className={`fixed inset-0 bg-black z-40 ${isOpen ? '' : 'opacity-0'}`}
                    style={backdropStyle}
                    onClick={onClose}
                />
            )}
            <div
                ref={drawerRef}
                className={`fixed bottom-0 left-0 right-0 rounded-t-xl shadow-lg h-full`}
                style={drawerStyles}
            >
                {/* Handle per il trascinamento */}
                <div
                    className="w-full h-6 flex justify-center items-center cursor-pointer"
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleMouseDown}
                >
                    <div className="w-8 h-1 bg-gray-400 rounded-full"></div>
                </div>

                {/* Contenuto del Drawer */}
                {children}
            </div>
        </>
    );
};

export default Drawer;
