import { useState } from 'react';
import InputForm from '../../components/InputForm';
import CustomButton from '../../components/CustomButton';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useModalDispatcher } from '../../hooks/useModalDispatcher';
import RadioButton from '../../components/RadioButton';
import { IStructure } from '../../types/interfaces/structure.interface';
import { IUser } from '../../types/interfaces/auth.interface';
import Modal from '../../components/Modal';
import useComponentVisible from '../../hooks/useComponentVisible';
import { IUserStructure } from '../../types/interfaces/userStructure.interface';
import { HttpMethod, apiService } from '../../services/apiService';
import { IPlugin } from '../../types/interfaces/plugin.interface';
import { PERMISSION_LEVEL, permissionLevelOptions } from '../../types/enum/permissionLevel.enum';
import Switch from '../../components/Switch';
import ExpandableText from '../../components/ExpandableText';
import PageTitle from '../../components/PageTitle';
import Text from '../../components/Text';

const UserStructureManage = () => {
    const { userStructure, structure } = useLoaderData() as { userStructure?: IUserStructure<IStructure<IPlugin>, IUser>, structure?: IStructure<IPlugin> };
    const navigate = useNavigate();
    const params = useParams();
    const { showErrorModal, showModal } = useModalDispatcher();
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const [email, setEmail] = useState<string>('');
    const [permissions, setPermissions] = useState<{ plugin: string; permissionLevel: PERMISSION_LEVEL[]; }[]>(userStructure?.permissions ?? [])
    const [isLoading, setIsLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({});

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const plugins = userStructure?.structureId?.plugins ?? structure?.plugins ?? [];

    const handleUserStructureManage = async () => {
        try {
            setIsLoading(true);
            setFieldErrors({});

            if (!userStructure && !emailRegex.test(email)) {
                setFieldErrors({ email: true })
                setIsLoading(false);
                return;
            };

            if (permissions.length <= 0) {
                setFieldErrors({ permission: true })
                setIsLoading(false);
                return;
            };

            for (const { permissionLevel } of permissions) {
                if (permissionLevel.length <= 0) {
                    setFieldErrors({ plugin: true });
                    setIsLoading(false);
                    return;
                };
            };

            let response;

            if (!!userStructure) {
                response = await apiService(HttpMethod.PUT, '/user-structure/update', { id: params.id, permissions });
            } else {
                response = await apiService(HttpMethod.POST, '/user-structure/add', { email, permissions });
            }

            if (response.errorCode) {
                throw response;
            }

            setIsLoading(false);
            navigate(-1);

            if (!!userStructure) {
                showModal(200);
            } else {
                showModal(201);
            }
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleDeleteUserStructure = async () => {
        try {
            setIsLoading(true);

            await apiService(HttpMethod.DELETE, '/user-structure/delete/' + params.id);

            setIsLoading(false);
            navigate(-1);
        } catch (error: any) {
            setIsLoading(false);
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode);
            } else {
                showErrorModal(400, '');
            }
        }
    };

    const handleManagePermissionPlugin = (plugin: string, isPluginSelected?: { plugin: string; permissionLevel: PERMISSION_LEVEL[] }) => {
        setFieldErrors({});

        setPermissions(prev => {
            if (isPluginSelected) {
                return prev.filter(p => p.plugin !== plugin);
            } else {
                return [...prev, { plugin: plugin, permissionLevel: [PERMISSION_LEVEL.READ] }];
            }
        })
    }

    const handleManagePermissionLevel = (level: PERMISSION_LEVEL, isPluginSelected?: { plugin: string; permissionLevel: PERMISSION_LEVEL[] }, isChecked?: boolean) => {
        setFieldErrors({});

        if (!!isPluginSelected)
            setPermissions(prev => {
                if (isPluginSelected) {
                    if (isChecked && level === PERMISSION_LEVEL.MANAGE) {
                        // Remove the selected permission level
                        const newPermissionLevels = isPluginSelected.permissionLevel.filter(p => p !== level);
                        return prev.map(p => {
                            if (p.plugin === isPluginSelected.plugin) {
                                return { ...p, permissionLevel: newPermissionLevels };
                            } else {
                                return p;
                            }
                        });
                    } else if (!isChecked) {
                        // Add the selected permission level
                        return prev.map(p => {
                            if (p.plugin === isPluginSelected.plugin) {
                                return { ...p, permissionLevel: [...p.permissionLevel, level] };
                            } else {
                                return p;
                            }
                        });
                    }
                }
                return prev;
            });
    };

    return (
        <div className='flex-1 w-full flex flex-col p-8 gap-4'>

            <PageTitle title={!!userStructure ? 'Modifica' : 'Collega'} goBackBtn={true} rightBtn={!!userStructure ? <CustomButton label="Scollega" color="bg-error" onClickHandler={() => setIsComponentVisible(true)} /> : undefined} />

            <div className='h-full flex flex-col gap-4'>
                <InputForm label="Email" backgroundColor='bg-white' value={userStructure ? userStructure.userId.email : email} isDisabled={!!userStructure} setValue={setEmail} placeholder="Inserisci un'email" error={fieldErrors.email} errorMessage='Email non valida' />

                <div className='flex flex-col gap-4 select-none'>
                    <div className='flex flex-col gap-1'>
                        <Text
                            title='Servizi'
                            label={`Scegli i servizi che desideri assegnare all'utente che stai collegando alla tua struttura.
                                    Ogni servizio selezionato permetterà all'utente di accedere alle funzionalità specifiche della struttura.

                                    VISUALIZZA: Permette all'utente di vedere i dati e le informazioni senza poter apportare modifiche.
                                    GESTISCI: Consente all'utente di modificare le impostazioni, creare o rimuovere elementi e gestire altre opzioni disponibili all'interno di quel servizio.`}
                            gap={2}
                        />
                    </div>

                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4'>
                        {
                            plugins.filter(plugin => plugin.isFunctional === false).map(plugin => {
                                const isPluginSelected = permissions.find((p) => p.plugin === plugin._id)
                                return <div key={plugin._id} className='flex flex-col gap-2 shadow rounded-md pb-2 bg-white'>

                                    <div className='flex justify-between items-center px-4 bg-mediumGray rounded-t-md py-2 gap-2 w-full'>
                                        <p className='text-xl font-semibold text-text'>{plugin.name}</p>
                                        <Switch
                                            size='md'
                                            isOn={!!isPluginSelected}
                                            toggleSwitch={() => handleManagePermissionPlugin(plugin._id!, isPluginSelected)}
                                        />
                                    </div>

                                    <div className={`flex flex-col gap-2 px-4 justify-between h-full ${!isPluginSelected && "blur-sm cursor-default"}`}>
                                        <ExpandableText text={plugin.description!} labelClassName='text-xs font-medium text-mediumGray' />
                                        <div className='flex flex-col gap-1'>
                                            {permissionLevelOptions.map(level => {
                                                const isChecked = isPluginSelected && isPluginSelected.permissionLevel.includes(level.value);

                                                return (
                                                    <RadioButton
                                                        disabled={!isPluginSelected}
                                                        key={level.value}
                                                        label={level.label}
                                                        checked={!!isChecked}
                                                        onChange={() => handleManagePermissionLevel(level.value, isPluginSelected, isChecked)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>

                </div>
            </div>

            {fieldErrors.permission && <p className="text-error text-sm md:text-xs text-center font-medium">Seleziona almeno un servizio da assegnare all' utente.</p>}
            {fieldErrors.plugin && <p className="text-error text-sm md:text-xs text-center font-medium">Ogni servizio deve avere almeno un Livello di Permessi selezionato (Visualizza, Gestisci).</p>}

            <div className='flex justify-between mt-2'>
                <CustomButton label="Indietro" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => navigate(-1)} />
                <CustomButton disabled={isLoading} label={!!userStructure ? 'Modifica' : "Invita"} color="bg-text" className='border-2 border-text' onClickHandler={handleUserStructureManage} />
            </div>

            <Modal isOpen={isComponentVisible} onClose={() => setIsComponentVisible(false)}>
                <div className="flex flex-col gap-6 p-2 bg-white">
                    <div className='flex flex-col gap-2'>
                        <p className="text-2xl font-bold text-error">Rimozione Utente</p>
                        <p className="text-sm md:text-base font-medium text-darkGray">
                            Stai per rimuovere l'utente <strong>{userStructure?.userId?.email}</strong> dalla tua struttura.<br />
                            Questa azione è permanente e non può essere annullata.<br />
                            Vuoi procedere con la rimozione?
                        </p>
                    </div>
                    <div className="flex justify-end gap-4 mt-4">
                        <CustomButton
                            color='bg-white'
                            textColor='text-darkGray'
                            className='border-2 border-darkGray'
                            label="Annulla"
                            onClickHandler={() => setIsComponentVisible(false)}
                        />
                        <CustomButton
                            color="bg-error"
                            textColor="text-text_white"
                            className="border-2 border-error"
                            label="Procedi"
                            disabled={isLoading}
                            onClickHandler={handleDeleteUserStructure}
                        />
                    </div>
                </div>
            </Modal>


        </div>
    )
}

export default UserStructureManage