import { LoaderFunctionArgs } from "react-router-dom";
import { apiService, HttpMethod } from "../apiService";
import moment from "moment";
import { IBookingRequest } from "../../types/interfaces/bookingRequest.interface";
import { REQUEST_BOOKING_STATUS } from "../../types/enum/requestBookingStatus.enum";
import { IPagination } from "../../types/interfaces/pagination.interface";
import { Paginated } from "../../types/interfaces/paginated.interface";
import { IStructure } from "../../types/interfaces/structure.interface";
import { IOffer } from "../../types/interfaces/offer.interface";
import { IProposal } from "../../types/interfaces/proposal.interface";

export const queryListBookingRequests = async (args: LoaderFunctionArgs) => {
    const params = new URLSearchParams(args.request.url.substring(args.request.url.indexOf('?') + 1));

    let page_size = params.get('size') ? Number(params.get('size')) : 10;

    if (page_size !== 10 && page_size !== 25 && page_size !== 50) {
        page_size = 10
    }

    let filters: Partial<{ [K in keyof IBookingRequest]: IBookingRequest[K] }> = {}

    if (params.get('status') && params.get('status') !== 'all') {
        switch (params.get('status')) {
            case 'pending':
                filters.status = REQUEST_BOOKING_STATUS.PENDING
                break;
            case 'accepted':
                filters.status = REQUEST_BOOKING_STATUS.ACCEPTED
                break;
            case 'rejected':
                filters.status = REQUEST_BOOKING_STATUS.REJECTED
                break;
            case 'cancelled':
                filters.status = REQUEST_BOOKING_STATUS.CANCELLED
                break;
            case 'updated':
                filters.status = REQUEST_BOOKING_STATUS.UPDATED
                break;
            default:
                break;
        }
    }

    if (params.get('date')) {
        filters.date = moment(params.get('date')).utc(true).toDate()
    }

    const body: IPagination<IBookingRequest> = {
        pageSize: page_size,
        currentPage: params.get('page') ? Number(params.get('page')) : 1,
        filters: filters
    }

    const response = await apiService<{ paginatedResults: Paginated<IBookingRequest<IStructure, IOffer, IProposal>>, newBookingRequests: { "PENDING": number, "UPDATED": number } }>(HttpMethod.POST, '/booking-request/list/filtered', { pagination: body });

    return response;
};