import React, { useState } from 'react'
import { CopyIcon } from '../utils/icons/CopyIcon'

interface IProps {
    link: string;
    iconClassName?: string;
    padding?: number;
    color?: string;
}

const CopyToClipboard: React.FC<IProps> = ({ link, iconClassName, padding = 0, color = 'bg-white' }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        setCopied(false);

        navigator.clipboard.writeText(link)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Errore durante la copia del testo nella clipboard:', error);
            });
    };

    return (
        <div className='relative'>
            <div onClick={copyToClipboard} className={`p-${padding} cursor-pointer ${iconClassName}`}>
                <CopyIcon />
            </div>
            <div className={`transition-opacity ${copied ? 'opacity-100' : 'opacity-0'} cursor-default duration-300 ease-in-out absolute bottom-full -translate-x-1/2 left-1/2 mb-2 px-2 py-1 ${color} text-text font-medium rounded shadow`}>
                Copiato
            </div>
        </div>
    )
}

export default CopyToClipboard