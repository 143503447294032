import { ActionFunctionArgs, redirect } from 'react-router-dom';
import { apiHandler } from '../services/apiHandler';
import { AxiosError } from 'axios';
import { useModalDispatcher } from './useModalDispatcher';
import { IStructure } from '../types/interfaces/structure.interface';
import { apiService, HttpMethod } from '../services/apiService';

export function useCrud() {
    const { showErrorModal, showModal } = useModalDispatcher();

    /**
     * Performs a generic POST request to the specified URL and handles the response.
     *
     * @param {string} url - The URL to send the POST request to.
     * @param {string} redirectUrl - The URL to redirect to after the request is successful.
     * @param {ActionFunctionArgs} request - The request object containing form data.
     * @param {boolean} [redirectById=false] - Whether to redirect to a URL with the response's _id appended.
     * @return {Promise<boolean|void>} - Returns a Promise that resolves to a boolean indicating success or void if an error occurs.
     */
    async function genericPost<T>(url: string, redirectUrl: string | undefined, { request }: ActionFunctionArgs, redirectById: boolean = false) {
        try {
            const formData = await request.formData();
            let data = Object.fromEntries(formData) as any;

            const response = await apiHandler.post<T & { errorCode: string, status: number, message?: string, _id?: string, urlToken?: string }>(url, data);

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response
            }

            showModal(200, 'Operazione completata con successo!');

            if (!!redirectUrl) {
                if (redirectById) {
                    if (!!response.urlToken) {
                        return redirect(redirectUrl + response?.urlToken);
                    }
                    if (!!response._id) {
                        return redirect(redirectUrl + response?._id);
                    }
                } else {
                    return redirect(redirectUrl);
                }
            } else {
                return response;
            }
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    async function genericPut<T>(url: string, redirectUrl: string, { params, request }: ActionFunctionArgs, redirectById: boolean = false, isMultipart: boolean = false) {
        try {
            const formData = await request.formData();
            let data = Object.fromEntries(formData) as any;

            const response = await apiHandler.put<T & { errorCode: string, status: number, message?: string, _id: string }>(url + params.id ?? '', isMultipart ? formData : data, isMultipart);

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            showModal(200, 'Operazione completata con successo!');

            if (redirectById) {
                return redirect(redirectUrl + response?._id);
            } else {
                return redirect(redirectUrl);
            }
        } catch (error: any) {
            throw error
        };
    };

    /**
     * Deletes a resource from the specified URL and redirects to the given URL.
     *
     * @param {string} url - The URL to send the DELETE request to.
     * @param {string} redirectUrl - The URL to redirect to after the request is successful.
     * @param {ActionFunctionArgs} args - The arguments object containing the params.
     * @return {Promise<void | T>} - A Promise that resolves to the response or void if an error occurs.
     */
    async function genericDelete<T>(url: string, redirectUrl: string, { params }: ActionFunctionArgs, isBookingRequest: boolean = false) {
        try {
            const response = await apiHandler.put<T & { _id: string, errorCode: string, status: number, message?: string, structure?: IStructure }>(url + params.id);

            if (response instanceof AxiosError) {
                throw response;
            }

            if (!!response.errorCode) {
                showErrorModal(response.status, response.errorCode, response.message);
                return response;
            }

            if (isBookingRequest && response?.structure?.name && response?.structure?._id) {
                return redirect(`/${response.structure.name}/${response.structure._id}`);
            } else {
                return redirect(redirectUrl);
            }
        } catch (error: any) {
            throw error
        };
    };

    async function genericGet<T>(url: string, args?: ActionFunctionArgs) {
        try {
            let params = {};
            params = args?.params ?? {};

            const response = await apiService<any | { errorCode: string, status: number, message?: string }>(HttpMethod.GET, url, undefined, params);

            if (response instanceof AxiosError) {
                throw response;
            }

            if ('errorCode' in response) {
                throw response;
            }

            return response as T;
        } catch (error: any) {
            if (error?.errorCode) {
                showErrorModal(error.status, error.errorCode, error.message);
            } else {
                showErrorModal(400, '');
            }
        };
    };

    return {
        genericPost,
        genericPut,
        genericDelete,
        genericGet
    };
}
