import { Form, useLoaderData, useNavigation, useParams } from 'react-router-dom'
import PageTitle from '../../../components/PageTitle'
import { IList } from '../../../types/interfaces/list.interface';
import { IEvent } from '../../../types/interfaces/event.interface';
import moment from 'moment';
import { IStructure } from '../../../types/interfaces/structure.interface';
import SelectForm from '../../../components/SelectForm';
import InputForm from '../../../components/InputForm';
import { useState } from 'react';
import CustomButton from '../../../components/CustomButton';
import { GENDER } from '../../../types/enum/gender.enum';
import { CREATION_MODE } from '../../../types/enum/creationMode.enum';
import { IminError } from '../../../types/interfaces/iminError.interface';
import GenericError from '../../error/GenericError';
import ListCountDown from './ListCountDown';

const RegisterToList = () => {
    const list = useLoaderData() as IList<string, IEvent, IStructure> | IminError;
    const params = useParams();
    const navigation = useNavigation();

    const [gender, setGender] = useState<{ value: GENDER, label: string } | undefined>(undefined);

    if ('errorCode' in list) return <GenericError redirectUrl="/" />

    const { event, structure, limitDateTime } = list as IList<string, IEvent, IStructure>;

    if (moment().utc(true).isAfter(limitDateTime)) {
        return <div className='p-8 flex flex-col gap-8 h-full'>
            <PageTitle
                title='Tempo scaduto'
                subTitle='Il periodo per iscriversi alla lista è terminato. 🙁'
                goBackBtn={false}
            />
            <div className='text-center text-lg text-text h-full flex flex-col items-center justify-center gap-2'>
                <p>
                    Mi dispiace, ma non è più possibile iscriversi.<br />
                    Se pensi che ci sia stato un errore o hai bisogno di ulteriore assistenza, non esitare a contattare la struttura.
                </p>

                {structure?.contact.phone && <a href={`tel:${structure?.contact.phone}`} className='text-center text-lg px-8 py-2 bg-info rounded-md shadow text-text_white font-semibold'>Contatta {structure?.contact.phone}</a>}
            </div>
        </div>
    }

    return (
        <div className='p-8 flex flex-col gap-8'>
            <PageTitle
                title='Entra in Lista'
                subTitle={`Hai tempo fino a ${moment(limitDateTime).utc(true).format('dddd DD MMMM, HH:mm')} per iscriverti alla lista.`}
                goBackBtn={false}
            />

            <div className='flex items-center justify-center text-2xl gap-2'>
                <p className='text-text font-medium'>Tempo rimanente:</p>
                <ListCountDown targetDate={limitDateTime} />
            </div>

            {/* Locandina dell'evento */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6 flex flex-col sm:flex-row gap-4">
                <img
                    src={event.poster || `https://via.placeholder.com/1080x1920?text=Locandina+Evento+${event.name}`}
                    alt={`Locandina ${event.name}`}
                    className="w-full sm:max-w-sm h-auto object-cover rounded-md"
                />
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-2'>
                        <h2 className="text-4xl font-semibold text-text capitalize">{event.name}</h2>
                        <p className="text-darkgray capitalize font-medium">{moment(event.date).format('dddd DD MMMM YYYY')}</p>
                        <p className="text-darkgray font-medium">Info: {event.description || "Descrizione dell'evento non disponibile."}</p>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <h3 className="text-4xl font-semibold text-text capitalize">{structure.name}</h3>
                        <p className="text-darkgray font-medium">{structure.address.city} ({structure.address.state}), {structure.address.street}, {structure.address.zip}</p>
                        <a href={`tel:${structure.contact.phone}`} className="hover:underline text-darkgray font-medium">{structure.contact.phone}</a>
                        <a href={`mailto:${structure.contact.email}`} className="hover:underline text-darkgray font-medium">{structure.contact.email}</a>
                    </div>
                </div>
            </div>

            {/* Form di registrazione alla lista */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h3 className="text-2xl font-semibold text-text mb-4">Registrati alla Lista</h3>
                <Form method='POST' className="flex flex-col gap-10">
                    {/* Input Hidden */}
                    <input hidden name='creationMode' defaultValue={CREATION_MODE.AUTO} />
                    <input hidden name='gender' defaultValue={gender?.value} />
                    <input hidden name='token' defaultValue={params.token} />


                    <div className='flex flex-col gap-4'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <InputForm
                                label='Nome*'
                                name='name'
                                backgroundColor='bg-background'
                            />
                            <InputForm
                                label='Cognome*'
                                name='surname'
                                backgroundColor='bg-background'
                            />
                        </div>

                        <InputForm
                            label='Email*'
                            name='email'
                            type='email'
                            backgroundColor='bg-background'
                        />

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <SelectForm
                                title='Sesso*'
                                options={[{ label: 'Donna', value: GENDER.FEMALE }, { label: 'Uomo', value: GENDER.MALE }, { label: 'Altro', value: GENDER.OTHER }]}
                                selectedOption={gender?.label}
                                setSelectedOption={(v) => setGender(v)}
                                arrow={false}
                                className='md:h-full h-9'
                            />
                            <InputForm
                                label='Età (opzionale)'
                                name='age'
                                backgroundColor='bg-background'
                            />
                        </div>
                    </div>

                    <p className='text-sm text-mediumGray font-medium'>
                        Le informazioni che ci fornisci saranno visibili solo al nostro team e utilizzate esclusivamente per offrirti il miglior servizio possibile.<br />
                        La tua email ci serve unicamente per inviarti ciò che ti serve per l'ingresso, come il QR code o altre informazioni importanti. Non ti invieremo mai pubblicità o messaggi promozionali indesiderati. Puoi stare tranquillo: i tuoi dati saranno trattati con attenzione e nel rispetto delle normative sulla privacy.
                    </p>

                    <div className='flex flex-col gap-1'>
                        <p className='text-xs text-mediumGray font-medium'>* Campi obbligatori</p>
                        <CustomButton
                            disabled={navigation.state !== 'idle' || moment().utc(true).isAfter(limitDateTime)}
                            containerClassName='flex md:items-end'
                            color='bg-text'
                            label="Registrati"
                            type='submit'
                        />
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default RegisterToList