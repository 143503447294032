import { Form, Navigate, useLoaderData, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { IStructure } from '../../types/interfaces/structure.interface';
import CustomButton from '../../components/CustomButton';

const StructureManage = () => {
    const structure = useLoaderData() as IStructure;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const params = useParams();

    const inputClassNames = `h-full px-2 py-2 rounded-md text-sm font-medium focus:outline-none resize-none bg-white w-full shadow`;

    if (params.id !== structure._id || !params.id) {
        return <Navigate replace={true} to={'/dashboard/struttura/manage/' + structure._id} />
    }

    return (
        <Form method='PUT' className='w-full flex flex-col p-8 gap-6 justify-between flex-1'>
            <p className="font-bold text-3xl text-text">Aggiorna la tua Struttura</p>
            <p className="text-lg text-gray-600">Modifica i dettagli della tua struttura qui sotto e assicurati che tutto sia aggiornato correttamente.</p>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                <div className='md:col-span-2 flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Nome</p>
                    <input name='name' defaultValue={structure.name} className={inputClassNames} placeholder='Come si chiama la tua struttura?' />
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Telefono</p>
                    <input name='contact.phone' defaultValue={structure.contact?.phone} className={inputClassNames} placeholder='Qual è il numero di telefono?' />
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Email</p>
                    <input name='contact.email' defaultValue={structure.contact?.email} className={inputClassNames} placeholder='Inserisci la tua email qui!' />
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Città</p>
                    <input name='address.city' defaultValue={structure.address?.city} className={inputClassNames} placeholder='Dove si trova la tua struttura?' />
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Stato/Regione</p>
                    <input name='address.state' defaultValue={structure.address?.state} className={inputClassNames} placeholder='In quale stato/regione?' />
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Indirizzo</p>
                    <input name='address.street' defaultValue={structure.address?.street} className={inputClassNames} placeholder="Qual è l'indirizzo esatto?" />
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Codice Postale</p>
                    <input name='address.zip' defaultValue={structure.address?.zip} className={inputClassNames} placeholder='Inserisci il codice postale!' />
                </div>
                <div className='md:col-span-2 flex flex-col gap-1'>
                    <p className='font-semibold text-text text-lg'>Descrizione</p>
                    <textarea rows={6} name='description' defaultValue={structure?.description} className={inputClassNames} placeholder='Descrivi la tua fantastica struttura!' />
                </div>
            </div>

            <div className='flex justify-between mt-10'>
                <CustomButton type='button' label="Indietro" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => navigate(-1)} />
                <CustomButton type='submit' disabled={navigation.state !== 'idle'} label={'Salva'} color="bg-text" className='border-2 border-text' onClickHandler={() => false} />
            </div>
        </Form>
    )
}

export default StructureManage