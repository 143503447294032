import PageTitle from '../../components/PageTitle'
import useComponentVisible from '../../hooks/useComponentVisible';
import InputForm from '../../components/InputForm';
import { Form, useLoaderData, useNavigate, useNavigation } from 'react-router-dom';
import Text from '../../components/Text';
import CustomButton from '../../components/CustomButton';
import Modal from '../../components/Modal';
import { IPr } from '../../types/interfaces/pr.interface';
import { IStructure } from '../../types/interfaces/structure.interface';

const PrManage = () => {
    const pr = useLoaderData() as IPr<IStructure>;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return (
        <Form method='PUT' className='h-full w-full justify-between overflow-auto flex flex-col p-8 gap-4'>

            <div className='flex flex-col gap-4 select-none'>
                <PageTitle title='Gestione Contatti PR' goBackBtn={true} />

                <div className='flex flex-col gap-1'>
                    <Text
                        title='Email PR Attuale'
                        label={`${false
                            ? `Qui sotto trovi i dettagli del PR attualmente associato al tuo locale. Se desideri rimuovere questo PR dalla lista, seleziona l'opzione di rimozione. 
                                Tieni presente che una volta rimosso, il PR non riceverà più notifiche sugli eventi e non potrà più gestire le liste degli invitati.`
                            : `Inserisci i dettagli del nuovo PR che desideri aggiungere al tuo locale. Assicurati di fornire informazioni corrette, poiché il PR riceverà notifiche sugli eventi e potrà gestire le liste degli invitati.`
                            }`}
                        gap={2}
                    />
                </div>

                <InputForm name='name' label="Nome" defaultValue={pr?.name ?? ''} backgroundColor='bg-white' isDisabled={!!pr?._id || navigation.state !== 'idle'} placeholder="Nome" />
                <InputForm name='surname' label="Cognome" defaultValue={pr?.surname ?? ''} backgroundColor='bg-white' isDisabled={!!pr?._id || navigation.state !== 'idle'} placeholder="Cognome" />
                <InputForm name='email' label="Email" defaultValue={pr?.email ?? ''} backgroundColor='bg-white' isDisabled={!!pr?._id || navigation.state !== 'idle'} placeholder="Email" />
                <input name='action' type='hidden' defaultValue={pr?._id ? 'remove' : 'add'} />
            </div>


            <div className='flex justify-between'>
                <CustomButton type='button' label="Indietro" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => navigate(-1)} />
                <CustomButton type={pr?._id ? 'button' : 'submit'} disabled={navigation.state !== 'idle'} label={pr?._id ? "Rimuovi" : "Invita"} color={pr?._id ? 'bg-error' : 'bg-text'} className={`border-2 border-${pr?._id ? 'error' : 'text'}`} onClickHandler={() => setIsComponentVisible(true)} />
            </div>

            <Modal isOpen={isComponentVisible && !!pr?._id} onClose={() => setIsComponentVisible(false)}>
                <div className='flex flex-col gap-2'>
                    <p className='text-center text-text font-bold text-2xl'>Sicuro di voler rimuovere questo PR?</p>
                    <p className='text-text font-medium'>
                        Questa operazione rimuoverà definitivamente l'email del PR dal sistema.<br />
                        Una volta rimosso, il PR non riceverà più notifiche sugli eventi e non potrà più gestire le liste degli invitati.
                    </p>

                    <div className='flex justify-between mt-6'>
                        <CustomButton type='button' label="Annulla" color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={() => setIsComponentVisible(false)} />
                        <CustomButton type='submit' disabled={navigation.state !== 'idle'} label="Procedi" color='bg-error' textColor='text-text_white' className='border-2 border-error' />
                    </div>
                </div>
            </Modal>

        </Form>
    )
}

export default PrManage