import React from 'react'
import CustomButton from './CustomButton'
import Text from './Text'
import moment from 'moment'
import { mapCakeType } from '../types/enum/cakeType'
import { IBookingManage } from '../views/booking/booking/BookingCreate'
import { useParams } from 'react-router-dom'
import { IOfferCapacity } from '../types/interfaces/offer.interface'

interface IProps {
    booking: IBookingManage,
    onClose: () => void,
    onAction: () => void,
    isLoading: boolean,
    offersCapacity?: IOfferCapacity[];
}

const BookingRecap: React.FC<IProps> = ({ booking, onClose, onAction, isLoading, offersCapacity }) => {
    const params = useParams();

    return (
        <div className='flex flex-col justify-between h-full gap-6 p-4 w-full overflow-auto'>

            <div className='w-full flex flex-col gap-2'>
                <div className='flex flex-col gap-2'>
                    <p className='text-text font-medium text-2xl capitalize'><strong>{booking.name} {booking.surname}</strong></p>
                    <Text reverseFontStyle title='Data' label={moment(booking.bookingDate).format('dddd DD MMMM')} className='capitalize' />
                    <Text reverseFontStyle title='Telefono' label={booking.phoneNumber} />
                    <Text reverseFontStyle title='Email' label={booking.email} className='normal-case' />
                </div>

                <p className='font-semibold text-text text-left text-xl'>Servizi:</p>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2'>
                    {booking?.bookedOffers?.map((bookedOffer, index) => {
                        const offerAnalitics = offersCapacity?.find(offerCapacity => offerCapacity.offer._id === bookedOffer.offer._id);
                        const availableSpots = offerAnalitics?.freeCapacity ?? 0;
                        const requestedSpots = bookedOffer.people ?? 0;
                        const badPercent = offerAnalitics?.badPercent ? parseFloat(String(offerAnalitics?.badPercent)) : 0;

                        let statusMessage = '';
                        if (badPercent >= 100) {
                            statusMessage = 'Servizio in overbooking!';
                        } else if (requestedSpots > availableSpots) {
                            statusMessage = `Attenzione: Il servizio ${bookedOffer.offer.name.replace(/\b\w/g, char => char.toUpperCase())} andrà in overbooking.`;
                        } else if (badPercent >= 50) {
                            statusMessage = 'Servizio quasi al completo!';
                        }

                        return <div key={index} className='bg-background p-2 shadow rounded-md gap-2 flex flex-col justify-end h-full'>

                            {!!statusMessage && (
                                <p className='font-semibold text-warning text-center text-xl'>
                                    {statusMessage}<br />
                                    ({offerAnalitics?.badPercent}% occupato, {offerAnalitics?.freeCapacity} posti liberi)
                                </p>
                            )}

                            <div className='flex justify-between items-baseline gap-2'>
                                <p className='font-bold text-xl whitespace-nowrap'>{bookedOffer.offer.name}<span className='font-normal text-sm'>{!!bookedOffer.shift ? ', ' + bookedOffer.shift : ''}</span></p>
                                <p className='text-sm font-medium text-right'>x {bookedOffer.people}</p>
                            </div>

                            <hr className='w-full border-b border-darkGray' />

                            <div>
                                <label className='font-semibold text-lg text-text'>Proposte</label>
                                {!!bookedOffer?.proposals?.length
                                    ? bookedOffer?.proposals
                                        .filter(proposal => !proposal.proposal.deleted)
                                        .map((proposal, index) => {
                                            return <div key={index} className='flex justify-between items-baseline'>
                                                <p className='text-sm font-medium text-text flex gap-2 items-baseline'>{proposal.proposal.name}</p>
                                                <p className='text-sm font-medium text-text'>x {proposal.quantity}</p>
                                            </div>
                                        })
                                    : <p className='font-semibold text-text py-4 text-sm text-center'>Da definire</p>
                                }
                            </div>

                            {!!mapCakeType(bookedOffer.cakeType) && <>
                                <hr className='w-full border-b border-darkGray' />
                                <Text title={mapCakeType(bookedOffer.cakeType)} label={bookedOffer.cakeNote ?? 'Nessuna nota sulla torta.'} />
                            </>}

                        </div>
                    })}
                </div>
            </div>

            <div className='flex gap-2 justify-between'>
                <CustomButton label='Annulla' color='bg-white' textColor='text-darkGray' className='border-2 border-darkGray' onClickHandler={onClose} />
                <CustomButton disabled={isLoading} label={params.id ? 'Modifica' : 'Crea'} color='bg-text' className='border-2 border-text' onClickHandler={onAction} />
            </div>
        </div>)
}

export default BookingRecap