import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { retrieveConfig } from '../config/config.environment';

interface ApiServiceOptions {
    onSuccess?: (data: any) => void;
    onError?: (error: AxiosError) => void;
};

export enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
};

export async function apiService<T = any>(method: HttpMethod, url: string, data?: any, params?: any, options?: AxiosRequestConfig & ApiServiceOptions & { isRefreshToken?: boolean, multipart?: boolean }): Promise<T> {
    const headers: Record<string, string> = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': options?.multipart ? 'multipart/form-data' : 'application/json',
        Accept: 'application/json',
    };

    try {
        const response: AxiosResponse<T> = await axios({
            method,
            baseURL: retrieveConfig().apiBaseUrl,
            url,
            data,
            params,
            headers,
            withCredentials: true,
            ...options
        });

        if (options?.onSuccess) {
            options.onSuccess(response.data);
        }

        return response?.data;
    } catch (error: any) {

        if (!!error?.response?.data) {
            return error.response.data
        }

        throw error;
    }
}