import { useLoaderData, useNavigate } from 'react-router-dom';
import { IList } from '../../types/interfaces/list.interface';
import { IPartecipant } from '../../types/interfaces/partecipant.interface';
import { IEvent } from '../../types/interfaces/event.interface';
import { IStructure } from '../../types/interfaces/structure.interface';
import PageTitle from '../../components/PageTitle';
import { GENDER } from '../../types/enum/gender.enum';
import Text from '../../components/Text';
import moment from 'moment';
import { AddIcon } from '../../utils/icons/AddIcon';
import CustomButton from '../../components/CustomButton';
import GenericError from '../error/GenericError';
import { IminError } from '../../types/interfaces/iminError.interface';

const PrList = () => {
    const response = useLoaderData() as IList<IPartecipant, IEvent, IStructure> | IminError;
    const navigate = useNavigate();

    if ('errorCode' in response) return <GenericError redirectUrl="/promoter" />

    const list = response as IList<IPartecipant, IEvent, IStructure>;

    return (
        <div className="flex flex-col gap-4 p-4 md:p-8 relative">
            <PageTitle
                title={list.people.length + " Person" + [list.people.length > 1 ? 'e' : 'a']}
                subTitle={list.event.name}
                rightBtn={
                    <CustomButton containerClassName='hidden md:flex' color='bg-text' label='Aggiungi Persona' leftIcon={<AddIcon />} onClickHandler={() => navigate('/promoter/list/add/partecipant/' + list._id)} />
                }
            />

            <CustomButton containerClassName='md:hidden' color='bg-text' label='Aggiungi Persona' leftIcon={<AddIcon />} onClickHandler={() => navigate('/promoter/list/add/partecipant/' + list._id)} />

            <div className='flex flex-col gap-3'>
                <div className="grid grid-cols-3 items-center gap-2 relative">
                    <Text title='Iscrizione' label=' ' />
                    <Text title='Nome' label=' ' />
                    <Text title='Sesso' label=' ' />
                </div>

                {!!list.people.length
                    ? list?.people?.map((person, index) => {
                        const genderLabel = person.gender === GENDER.MALE ? "Uomo" : GENDER.FEMALE ? "Donna" : "Altro"
                        const age = !!person.age ? ' - ' + person.age : ''
                        return <div key={index} className="grid grid-cols-3 items-center gap-2 relative">
                            <Text className='capitalize' title=' ' label={moment(person.createdAt).format('ddd DD MMM')} />
                            <Text title=' ' label={person.name + ' ' + person.surname} />
                            <Text title=' ' label={genderLabel + age} />
                            <div className={`${person.status ? "bg-success" : "bg-mediumGray"} absolute -right-1 md:-right-2 h-4 w-4 aspect-square rounded-full`} />
                        </div>
                    })
                    : <p className='text-center text-sm font-medium text-mediumGray mt-20'>Aggiungi o invita qualcuno per visualizzarlo nella tua lista.</p>
                }
            </div>
        </div>
    )
}

export default PrList